import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useAccount,
  useConnect,
  useContractWrite,
  useDisconnect,
  usePrepareContractWrite,
  useSignMessage,
  useWaitForTransaction,
} from "wagmi";
import { mainnet } from "wagmi/chains";
import { addWallet, loginWallet, nonceThunk } from "../features/auth/authSlice";
import { addNotif } from "../features/notification/notificationSlice";
import { savePrevCartItems } from "../features/xsolla/xsollaSlice";

export const useWallet = () => {
  const [message, setMessage] = useState();
  const [signature, setSignature] = useState();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const user = useSelector((state) => state.auth.user);
  const items = useSelector((state) => state.xsolla.items);
  const dispatch = useDispatch();

  const { disconnect } = useDisconnect({
    onSuccess() {
      setMessage();
      setSignature();
    },
  });

  const { address, isConnected } = useAccount({
    async onConnect() {
      setSignature();
    },
  });

  const {
    connect,
    connectors,
    isError: isPrepareError,
    error,
  } = useConnect({
    chainId: mainnet.id,
    async onSuccess(data) {
      dispatch(
        addNotif({
          type: "complete",
          text: `Wallet ${data.account.slice(0, 8)}...${data.account.slice(
            -6,
          )} is linked`,
        }),
      );
      if (!user?.wallets.includes(data.account)) {
        setTimeout(() => {
          dispatch(
            addNotif({
              type: "warning",
              text: "If after clicking on (Sign message) the message is not displayed in the wallet application, then open it again",
            }),
          );
        }, 3000);
      }
      const { nonce: message } = await dispatch(
        nonceThunk({ address: data.account }),
      );
      setMessage(message);
    },
    onError() {
      dispatch(
        addNotif({
          type: "cancel",
          text: "Wallet connection lost",
        }),
      );
    },
  });

  const { signMessage } = useSignMessage({
    async onSuccess(data) {
      try {
        await setSignature(data);
        await dispatch(savePrevCartItems(items));
        if (!isAuth) {
          await dispatch(
            loginWallet({ signature: data, publicAddress: address }),
          );
        } else {
          await dispatch(
            addWallet({ signature: data, publicAddress: address }),
          );
        }
        dispatch(
          addNotif({
            type: "complete",
            text: "Message signing was successful",
          }),
        );
      } catch (e) {
        dispatch(
          addNotif({
            type: "cancel",
            text: `${e}`,
          }),
        );
      }
    },
    onError() {
      dispatch(
        addNotif({
          type: "cancel",
          text: "Message signing failed",
        }),
      );
    },
  });

  const goSignMessage = async () => {
    if (message) {
      await signMessage({ message });
    } else {
      const resp = await dispatch(nonceThunk({ address }));
      await signMessage({ message: resp.payload.nonce });
    }
  };

  return {
    connect,
    disconnect,
    connectors,
    address,
    signature,
    isConnected,
    goSignMessage,
    message,
    isPrepareError,
    error,
    useContractWrite,
    usePrepareContractWrite,
    useWaitForTransaction,
  };
};
