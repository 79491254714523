import styled from "styled-components";

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: "pointer";
  min-width: 0.938vw;
  min-height: 0.938vw;
  width: 0.938vw;
  height: 0.938vw;
  @media (max-width: 768px) {
    min-width: 30px;
    min-height: 30px;
  }
`;

const Indicator = styled.div`
  min-width: 0.938vw;
  min-height: 0.938vw;
  border: 0.052vw solid #000;
  border-radius: 0.26vw;
  position: absolute;
  cursor: pointer;
  top: 0;

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0.07vw;
    left: 0.24vw;
    min-width: 0.35vw;
    min-height: 0.6vw;
    border: solid #000;
    border-width: 0 0.15vw 0.15vw 0;
    rotate: 45deg;
  }
  @media (max-width: 768px) {
    min-width: 3vw;
    min-height: 3vw;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 0.26vw;

    ${Input}:checked + &::after {
      top: 4px;
      left: 10.2px;
      min-width: 8px;
      min-height: 16px;
      border-width: 0 2px 2px 0;
    }
  }
`;

export const Checkbox = ({ checked, onChange }) => (
  <Label>
    <Input type="checkbox" checked={checked} onChange={onChange} />
    <Indicator />
  </Label>
);
