import styled from "styled-components";

export const BlueTyping = styled.input`
  width: 100%;
  &.select {
    width: 100%;
  }
  background: transparent;
  font-family: "medium";
  font-size: 0.833vw;
  line-height: 1.167vw;
  font-weight: 530;
  letter-spacing: 0.02em;
  color: #f2530f;
  ::placeholder {
    color: #f2530f;
  }
  @media (max-width: 768px) {
    font-size: 3.333vw;
    line-height: 4.667vw;
    border-radius: 2.051vw;
  }
`;

export const Input = styled.input`
  width: ${(props) => props.w || "100%"};
  background-color: ${(props) => props.bc || "#f2f2f2"};
  height: 2.865vw;
  border: 0.052vw solid ${(props) => props.brc || "rgba(0, 0, 0, 0.15)"};
  border-radius: 0.521vw;
  padding: ${(props) => props.p || "1.25vw 0.833vw 0.521vw 0.833vw"};
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${(props) => props.c || "#000000"};
  font-family: "medium";
  border-top-right-radius: ${(props) => props.nrb && "0vw"};
  border-bottom-right-radius: ${(props) => props.nrb && "0vw"};
  :disabled {
    cursor: not-allowed;
  }
  @media (max-width: 768px) {
    font-size: 3.333vw;
    line-height: 4.667vw;
    height: 11.282vw;
    border: 0.256vw solid ${(props) => props.brc || "rgba(0, 0, 0, 0.15)"};
    border-radius: 2.051vw;
    border-top-right-radius: ${(props) => props.nrb && "0vw"};
    border-bottom-right-radius: ${(props) => props.nrb && "0vw"};
    padding: ${(props) => props.p || "3.333vw 3.846vw 1.5vw 3.846vw"};
  }
`;

export const NoInput = styled.div`
  width: ${(props) => props.w || "100%"};
  background-color: ${(props) => props.bc || "#f2f2f2"};
  height: 2.865vw;
  border: 0.052vw solid ${(props) => props.brc || "rgba(0, 0, 0, 0.15)"};
  border-radius: 0.521vw;
  padding: 1.25vw 0.833vw 0.521vw 0.833vw;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${(props) => props.c || "#000000"};
  border-top-right-radius: ${(props) => props.nrb && "0vw"};
  border-bottom-right-radius: ${(props) => props.nrb && "0vw"};
  cursor: pointer;
  @media (max-width: 768px) {
    height: 11.282vw;
    border: 0.256vw solid ${(props) => props.brc || "rgba(0, 0, 0, 0.15)"};
    border-radius: 2.051vw;
    border-top-right-radius: ${(props) => props.nrb && "0vw"};
    border-bottom-right-radius: ${(props) => props.nrb && "0vw"};
    padding: 3.333vw 3.846vw 1.5vw 3.846vw;
  }
`;
