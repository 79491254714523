/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { claimApi } from "../../api/claimApi";
import { catchNotif } from "../notification/notificationSlice";
import { setWallet } from "../profile/profileSlice";

export const checkTokensThunk = createAsyncThunk(
  "/breeding/checkTokens",
  async ({ wallet }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setWallet(wallet));
      const response = await claimApi.checkTokens(wallet);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const claimThunk = createAsyncThunk(
  "/redeem/burn",
  async (
    {
      country,
      firstName,
      lastName,
      address,
      additionalAddress,
      postalCode,
      phone,
      city,
      email,
      tokenId,
      tokenAddress,
      wallet,
      deviceId,
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await claimApi.claimBurn(
        country,
        firstName,
        lastName,
        address,
        additionalAddress,
        postalCode,
        phone,
        city,
        email,
        tokenId,
        tokenAddress,
        wallet,
        deviceId,
      );
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

const claimSlice = createSlice({
  name: "claim",
  initialState: {
    isLoading: false,
    tokens: [],
  },
  extraReducers: (builder) => {
    builder.addCase(checkTokensThunk.pending, (state) => {
      state.tokens = [];
      state.isLoading = true;
    });
    builder.addCase(checkTokensThunk.fulfilled, (state, action) => {
      state.tokens = action.payload;
      state.isLoading = false;
    });
  },
});

export default claimSlice.reducer;
