/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from "axios";

export const API_URL = process.env.REACT_APP_API_END_POINT;
export const WS_URL = `${process.env.REACT_APP_API_END_POINT}/ws`;
export const XSOLLA_URL = "https://store.xsolla.com/api/v2";
export const MISC_UTILLS = "https://misc-utills.pillz.tech";
export const GAME_API = "https://game-srv.pillz.tech/v1/game/cellz";
export const CURRENT_DROP_ID = "4d610900-b7f5-402b-a5f1-c24b383f1f14";
// 2ee7c231-c35d-46b2-9635-4a749ddbb1ec
export const CURRENT_DROP_SKU = "Pillz-batch-1";
// test-bollz2

export const $apiMiscUtills = axios.create({
  baseURL: MISC_UTILLS,
});

export const $api = axios.create({
  baseURL: API_URL,
});

export const $apiXsolla = axios.create({
  baseURL: XSOLLA_URL,
});

export const $apiGame = axios.create({
  baseURL: GAME_API,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken",
  )}`;
  return config;
});

$apiMiscUtills.interceptors.request.use((config) => {
  config.headers.Authorization = `${localStorage.getItem("accessToken")}`;
  return config;
});

$apiXsolla.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "xsollaToken",
  )}`;
  return config;
});

$apiXsolla.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.data.statusCode === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await $api.get("/order/token");
        localStorage.setItem("xsollaToken", response.data.token);
        return $apiXsolla.request(originalRequest);
      } catch (e) {
        //
      }
    }
    throw error;
  },
);

$api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 403 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await $api.post("/auth/refresh", {
          refreshToken: localStorage.getItem("refreshToken"),
        });
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        return $api.request(originalRequest);
      } catch (e) {
        //
        //
      }
    }
    throw error;
  },
);

$api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = false;
      try {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.reload();
        return $api.request(originalRequest);
      } catch (e) {
        console.log("No authorize");
      }
    }
    throw error;
  },
);

$apiMiscUtills.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 403 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await $api.post("/auth/refresh", {
          refreshToken: localStorage.getItem("refreshToken"),
        });
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        return $api.request(originalRequest);
      } catch (e) {
        //
      }
    }
    throw error;
  },
);

$apiMiscUtills.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = false;
      try {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.reload();
        return $api.request(originalRequest);
      } catch (e) {
        console.log("No authorize");
      }
    }
    throw error;
  },
);
