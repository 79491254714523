import { $api } from ".";

export const dropApi = {
  addToWl(email) {
    return $api
      .get("/whitelist", {
        params: { email },
      })
      .then((res) => res);
  },
  getDrops() {
    return $api.get("/drops").then((res) => res);
  },
  getDrop(dropId) {
    return $api.get(`/drops/${dropId}`).then((res) => res);
  },
  getDropLimit(deviceSku) {
    return $api
      .get("/stuff", {
        params: { deviceSku },
      })
      .then((res) => res);
  },
};
