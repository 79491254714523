import styled from "styled-components";

export const Genes = styled.div`
  width: 44.24vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.042vw;
  @media (max-width: 1280px) {
    width: 52.188vw;
  }
  @media (max-width: 768px) {
    width: 100%;
    gap: 3.251vw;
    margin-top: 2.3vw;
  }
`;

export const Gene = styled.div`
  width: 9.896vw;
  height: 2.083vw;
  background-image: url(${(props) => props.back});
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1280px) {
  }
  @media (max-width: 768px) {
  }
`;

export const OtpInputSection = styled.div`
  justify-content: center;
  align-items: center;
`;

export const HiddenTextInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`;

export const OtpInputContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  width: 20.104vw;
  @media (max-width: 1280px) {
  }
  @media (max-width: 768px) {
    width: 71.066vw;
  }
`;

export const OtpInput = styled.div`
  height: 2.865vw;
  width: 2.917vw;
  border-radius: 0.521vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) =>
    props.noValid ? "0.052vw solid red" : " 0.052vw solid rgba(0, 0, 0, 0.1)"};
  @media (max-width: 1280px) {
  }
  @media (max-width: 768px) {
    height: 10.152vw;
    width: 10.152vw;
    border-radius: 1.777vw;
  }
`;

export const OtpInputText = styled.p`
  font-family: "regular";
  letter-spacing: 0.01em;
  font-size: 1.563vw;
  line-height: 1.875vw;
  color: ${(props) => (props.noValid ? "red" : "#000")};
  @media (max-width: 1280px) {
  }
  @media (max-width: 768px) {
    font-size: 5.076vw;
    line-height: 6.091vw;
  }
`;

export const OtpInputFocused = styled(OtpInput)`
  box-sizing: border-box;
  border: ${(props) =>
    props.noValid ? "0.052vw solid red" : " 0.052vw solid #000"};
  @media (max-width: 1280px) {
  }
`;
