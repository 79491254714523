import { useNavigate } from "react-router-dom";
import { CURRENT_DROP_ID } from "../api";
// import { fillCartThunk } from "../features/xsolla/xsollaSlice";

export const useBuy = () => {
  // const items = useSelector((state) => state.xsolla.items);
  // const dispatch = useDispatch();
  const nav = useNavigate();

  const buyFunc = async () => {
    // if (items.find((i) => i.sku === CURRENT_DROP_SKU)) {
    nav(`/catalog/${CURRENT_DROP_ID}`);
    // } else {
    // const a = await dispatch(
    //   fillCartThunk([{ sku: CURRENT_DROP_SKU, quantity: 1 }]),
    // ).unwrap();
    // if (a) {
    // nav(`/catalog/${CURRENT_DROP_ID}`);
    // }
    // }
  };

  return {
    buyFunc,
  };
};
