import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { WS_URL } from "../../api";
import supportPillz from "../../assets/images/profile/devicesRoom/pillz_support.png";
import upperArrow from "../../assets/images/profile/devicesRoom/upperArrow.png";
import {
  addNewMessage,
  getSupportMessagesThunk,
} from "../../features/support/supportSlice";
import {
  Block,
  BlockInputAndButton,
  BottomPanel,
  Button,
  Chat,
  ContentMessage,
  ImageArrow,
  ImageSupportPillz,
  Input,
  Message,
  RightBlock,
  SupportPillz,
  Text,
} from "./allStyles";

export const RightPanelChatSup = () => {
  const [value, setValue] = useState("");
  const [haveConnection, setHaveConnection] = useState();
  const [room, setRoom] = useState("");
  const user = useSelector((state) => state.auth.user);
  const activeRoom = useSelector((state) => state.support.activeRoom);
  const chatMessages = useSelector((state) => state.support.chatMessages);
  const size = useSelector((state) => state.support.size);
  const page = useSelector((state) => state.support.page);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeRoom) {
      dispatch(getSupportMessagesThunk({ deviceId: activeRoom, page, size }));
    }
  }, [activeRoom]);

  useEffect(() => {
    const socket = new SockJS(WS_URL);
    const stompClient = Stomp.over(socket);
    // stompClient.debug = () => {}; потом раскоментить как все сделаем
    stompClient.connect(
      {
        "X-Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      },
      () => setHaveConnection(stompClient),
    );

    return () => {
      if (stompClient && stompClient.connected) {
        stompClient.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (room === activeRoom) {
      return;
    }

    if (haveConnection && room) {
      haveConnection.unsubscribe(room);
    }

    if (haveConnection && activeRoom) {
      haveConnection?.subscribe(
        `/chat/device/${activeRoom}/message`,
        ({ body }) => {
          dispatch(addNewMessage(JSON.parse(body)));
        },
        { id: activeRoom },
      );
      setRoom(activeRoom);
    }
  }, [activeRoom, haveConnection]);

  const sendMessage = (permanentValue) => {
    haveConnection?.send(
      `/app/device/${activeRoom}/message`,
      {},
      JSON.stringify({ text: permanentValue || value }),
    );
    setValue("");
  };

  if (!activeRoom) {
    return null;
  }

  return (
    <RightBlock>
      <Chat>
        <Block>
          {chatMessages
            .slice()
            .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
            .map((message) => (
              <Message
                key={message.id}
                className={`${
                  message.userId === user.id || !message.userId
                    ? "sent"
                    : "received"
                }`}
              >
                {(message.userId === user.id || !message.userId) && (
                  <SupportPillz>
                    <ImageSupportPillz src={supportPillz} />
                    <Text c="rgba(0, 0, 0, 0.5)">Pillz support</Text>
                  </SupportPillz>
                )}
                <ContentMessage>
                  {message.messageType === "ORDER_CREATED" && (
                    <div style={{ display: "block" }}>
                      <Text>Order details:</Text>
                      <br />
                      <Text>
                        <span style={{ color: "rgba(0,0,0,0.5)" }}>
                          Region:
                        </span>{" "}
                        {JSON.parse(message.text).country}
                      </Text>
                      <Text>
                        <span style={{ color: "rgba(0,0,0,0.5)" }}>
                          Postal code:
                        </span>{" "}
                        {JSON.parse(message.text).postalCode}
                      </Text>
                      <Text>
                        <span style={{ color: "rgba(0,0,0,0.5)" }}>City:</span>{" "}
                        {JSON.parse(message.text).city}
                      </Text>
                      <Text>
                        <span style={{ color: "rgba(0,0,0,0.5)" }}>
                          Address:
                        </span>{" "}
                        {JSON.parse(message.text).address}
                      </Text>
                      {JSON.parse(message.text)?.additionalAddress && (
                        <Text>
                          <span style={{ color: "rgba(0,0,0,0.5)" }}>
                            Additional address:
                          </span>{" "}
                          {JSON.parse(message.text).additionalAddress}
                        </Text>
                      )}
                      <br />
                      <Text>
                        <span style={{ color: "rgba(0,0,0,0.5)" }}>
                          First name:
                        </span>{" "}
                        {JSON.parse(message.text).firstName}
                      </Text>
                      <Text>
                        <span style={{ color: "rgba(0,0,0,0.5)" }}>
                          Last name:
                        </span>{" "}
                        {JSON.parse(message.text).lastName}
                      </Text>
                      <Text>
                        <span style={{ color: "rgba(0,0,0,0.5)" }}>Email:</span>{" "}
                        {JSON.parse(message.text).email}
                      </Text>
                      <Text>
                        <span style={{ color: "rgba(0,0,0,0.5)" }}>
                          Phone number:
                        </span>{" "}
                        {JSON.parse(message.text).phone}
                      </Text>
                    </div>
                  )}
                  {message.messageType === "CHANGE_TRACK_NUMBER" && (
                    <Text>
                      The order was assigned a track number:{" "}
                      {JSON.parse(message.text).trackNumber}
                    </Text>
                  )}
                  {message.messageType === "CHANGE_STATUS" && (
                    <Text>
                      Your order status has changed to (
                      {JSON.parse(message.text).orderStatus ===
                        "IN_PROCESSING" && "In processing"}
                      {JSON.parse(message.text).orderStatus ===
                        "DEVICE_ASSEMBLY" && "Device assembly"}
                      {JSON.parse(message.text).orderStatus ===
                        "PREPARED_FOR_DELIVERY" && "Prep. for delivery"}
                      {JSON.parse(message.text).orderStatus === "IN_DELIVERY" &&
                        "In delivery"}
                      {JSON.parse(message.text).orderStatus === "DELIVERED" &&
                        "Delivered"}
                      )
                    </Text>
                  )}
                  {message.messageType === "CHANGE_DELIVERY_DATE" && (
                    <Text>
                      Estimated delivery date for your order is{" "}
                      {JSON.parse(message.text).expectedDeliveryDate}
                    </Text>
                  )}
                  {(message.messageType === "MESSAGE" ||
                    message.messageType === null) && (
                    <Text>{message.text}</Text>
                  )}
                  <Text c="rgba(0, 0, 0, 0.5)" style={{ textAlign: "right" }}>
                    {new Date(message.createdOn)?.toLocaleString()}
                  </Text>
                </ContentMessage>
              </Message>
            ))}
        </Block>
      </Chat>
      <BottomPanel>
        {/* <HintsBlock>
          <Hint onClick={() => sendMessage("My device is stolen/lost")}>
            <Text>My device is stolen/lost</Text>
          </Hint>
          <Hint onClick={() => sendMessage("I have device problem")}>
            <Text>I have device problem</Text>
          </Hint>
        </HintsBlock> */}
        <BlockInputAndButton>
          <Input
            placeholder="Message to Pillz..."
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button disabled={!value} onClick={() => sendMessage()}>
            <ImageArrow
              src={upperArrow}
              className={!value ? "noactive" : null}
            />
          </Button>
        </BlockInputAndButton>
      </BottomPanel>
    </RightBlock>
  );
};
