import styled from "styled-components";
import { Large } from "../../common/elements/Text";

export const PrivacyPolicyContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #f2530f;
  display: flex;
  justify-content: center;
`;

export const LargePolicy = styled(Large)`
  font-size: 5.208vw;
  line-height: 4.948vw;
`;

export const ContentContainer = styled.div`
  padding: 8.073vw 0;
  display: flex;
  flex-direction: column;
  gap: 4.167vw;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.083vw;
  align-items: center;
`;

export const BottomContainer = styled.div`
  width: 30.729vw;
`;
