import { useDispatch } from "react-redux";
import appstore from "../../assets/images/manual/appstore.png";
// import googleplay from "../../assets/images/manual/googleplay.png";
import info from "../../assets/images/manual/info.png";
import neck from "../../assets/images/manual/neck.png";
import m1 from "../../assets/videos/manual/1.mp4";
import m1m from "../../assets/videos/manual/1m.mp4";
import m2 from "../../assets/videos/manual/2.mp4";
import m3 from "../../assets/videos/manual/3.mp4";
import m4 from "../../assets/videos/manual/4.mp4";
import m5 from "../../assets/videos/manual/5.mp4";
import m5m from "../../assets/videos/manual/5m.mp4";
import { addNotif } from "../../features/notification/notificationSlice";
import { useIsAdaptive } from "../../hooks/useIsAdaptive";
import { ProductTenth } from "../product/tenth/ProductTenth";
import {
  AbsolutedLarge,
  InfoContainer,
  InfoIcon,
  InfoTextContainer,
  LargeStyle,
  LeftSide,
  Line,
  LinkBlocks,
  LinkImage,
  LinkStepContainer,
  ManualCard,
  ManualContainer,
  ManualDescription,
  ManualFlex,
  ManualImg,
  ManualTextBlock,
  NeckImage,
  PaddingContainer,
  RelativeContainer,
  RightSide,
  Source,
} from "./ManualStyle";

export const Manual = () => {
  const adpt = useIsAdaptive();
  const dispatch = useDispatch();

  return (
    <ManualContainer>
      <PaddingContainer>
        <LargeStyle c="#fff">Quick start guide for PILLZ device</LargeStyle>
        <ManualCard>
          <ManualFlex>
            <LeftSide>
              <ManualImg muted autoPlay loop playsInline>
                <Source src={adpt !== "mob" ? m1 : m1m} type="video/mp4" />
              </ManualImg>
            </LeftSide>
            <RightSide>
              <ManualTextBlock w="40.052vw">
                <LargeStyle>Step 1</LargeStyle>
                <ManualDescription w="34.531vw">
                  Connect a charging station to a power source and place the
                  device on top of it. The indication light on the station
                  should illuminate, showing that charging is in progress and
                  everything is fine
                </ManualDescription>
              </ManualTextBlock>
              <InfoTextContainer w="40.052vw">
                <InfoContainer>
                  <InfoIcon src={info} />
                  In the future, you can initiate the charging process by
                  touching the recess on the edge of the charging device,
                  allowing you to use it in a standalone mode without being
                  connected to a power source
                </InfoContainer>
              </InfoTextContainer>
            </RightSide>
          </ManualFlex>
          <Line />
          <LinkStepContainer>
            <LargeStyle>Step 2</LargeStyle>
            <ManualDescription w="44.219vw">
              To proceed with the device activation, at this step, you will need
              to download and install the PILLZ application on your phone
            </ManualDescription>
          </LinkStepContainer>
          <LinkBlocks>
            <LinkImage
              src={appstore}
              onClick={() =>
                dispatch(
                  addNotif({
                    text: "App Store: coming soon",
                    type: "warning",
                  }),
                )
              }
            />
            {/* <LinkImage src={googleplay} /> */}
          </LinkBlocks>
          <Line />
          <ManualFlex>
            <LeftSide order="true">
              <ManualTextBlock w="39.740vw">
                <LargeStyle>Step 3</LargeStyle>
                <ManualDescription w="33.177vw">
                  Open the app on your phone and tap the &quot;Renew&quot;
                  button. The NFC interface will appear. Bring your phone up
                  close to the device. Within 10 seconds, the charging indicator
                  should appear on the screen
                </ManualDescription>
              </ManualTextBlock>
              <InfoTextContainer w="39.740vw">
                <InfoContainer>
                  <InfoIcon src={info} />
                  In some cases, the device may display an outdated battery
                  level. To check the current battery level, remove the device
                  from the charging station, restart it, and it will display the
                  current battery level
                </InfoContainer>
              </InfoTextContainer>
            </LeftSide>
            <RightSide>
              <ManualImg muted autoPlay loop playsInline>
                <Source src={m2} type="video/mp4" />
              </ManualImg>
            </RightSide>
          </ManualFlex>
          <Line />
          <ManualFlex>
            <LeftSide>
              <ManualImg muted autoPlay loop playsInline>
                <Source src={m3} type="video/mp4" />
              </ManualImg>
            </LeftSide>
            <RightSide>
              <ManualTextBlock w="40.052vw">
                <LargeStyle>Step 4</LargeStyle>
                <ManualDescription w="35.260vw">
                  In the application, wait for the &quot;Awake&quot; button to
                  activate before tapping on it. The NFC interface will appear.
                  Bring your phone up close to the device. The device will enter
                  the active mode, displaying the message &quot;Upload your
                  first NFT using Pillz App&quot;
                </ManualDescription>
              </ManualTextBlock>
              <InfoTextContainer w="40.052vw">
                <InfoContainer w="33.4vw">
                  <InfoIcon src={info} />
                  If the device does not enter the active mode, leave it
                  charging and try again
                </InfoContainer>
              </InfoTextContainer>
            </RightSide>
          </ManualFlex>
          <Line />
          <ManualFlex>
            <LeftSide order="true">
              <ManualTextBlock w="39.740vw">
                <LargeStyle>Step 5</LargeStyle>
                <ManualDescription w="33.177vw">
                  Tap the &quot;Initialize&quot; button. The NFC interface will
                  appear. Bring your phone up close to the device
                </ManualDescription>
              </ManualTextBlock>
            </LeftSide>
            <RightSide>
              <ManualImg muted autoPlay loop playsInline>
                <Source src={m4} type="video/mp4" />
              </ManualImg>
            </RightSide>
          </ManualFlex>
          <Line />
          <ManualFlex className="small">
            <LeftSide>
              <ManualImg muted autoPlay loop playsInline className="small">
                <Source
                  src={adpt !== "mob" ? m5 : m5m}
                  type="video/mp4"
                  className="small"
                />
              </ManualImg>
            </LeftSide>
            <RightSide>
              <ManualTextBlock w="39.844vw">
                <LargeStyle>Step 6</LargeStyle>
                <ManualDescription w="35.260vw">
                  Great! Just make sure to plug it in and let device charging
                  for approximately 2 hours
                </ManualDescription>
              </ManualTextBlock>
            </RightSide>
          </ManualFlex>
        </ManualCard>
        <RelativeContainer>
          <NeckImage src={neck} />
          <AbsolutedLarge c="#fff">
            Congratulations, your device has been successfully activated and is
            ready for use!
          </AbsolutedLarge>
        </RelativeContainer>
      </PaddingContainer>
      <ProductTenth />
    </ManualContainer>
  );
};
