/* eslint-disable import/no-cycle */
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { authRoutes } from ".";
import { Large, Medium } from "../common/elements/Text";
import { useIsAdaptive } from "../hooks/useIsAdaptive";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3.125vw;
  padding-top: 7.031vw;
  @media (max-width: 1280px) {
    padding-top: 8.594vw;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4.315vw;
    padding-top: 21.626vw;
    margin-bottom: 0;
  }
`;

export const Nav = styled.div`
  display: flex;
  gap: 0.477vw;
  width: 100%;

  margin-left: 3.802vw;

  @media (max-width: 768px) {
    margin-left: 0;
    gap: 2.03vw;
  }
`;

export const NavElement = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.433vw;
  cursor: pointer;

  &:hover p {
    color: #ff6f32;
  }

  &:hover div {
    border-color: #ff6f32;
  }

  &.active div {
    border-color: #000000;
    &:hover {
      border-color: #000000;
    }
  }

  &.active p {
    color: #000000;
    &:hover {
      color: #000000;
    }
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    gap: 1.523vw;
  }
`;

export const LineBox = styled.div`
  margin-top: 1.479vw;
  width: 100%;
  height: 0.866vw;
  border: 0.052vw solid rgba(0, 0, 0, 0.35);
  border-bottom: none;

  @media (max-width: 768px) {
    height: auto;
    border: 0.256vw solid rgba(0, 0, 0, 0.35);
    border-bottom: none;
    margin-top: 0;
  }
`;

const TextLarge = styled(Large)`
  @media (max-width: 768px) {
    font-size: 10.152vw;
    line-height: 9.645vw;
  }
`;

const TextMedium = styled(Medium)`
  font-weight: 450;
  @media (max-width: 768px) {
    font-size: 3.299vw;
    line-height: 3.959vw;
  }
`;

export const NavigateProfilePage = () => {
  const { pathname } = useLocation();
  const nav = useNavigate();
  const adpt = useIsAdaptive();
  return (
    <>
      <HeaderContainer>
        <TextLarge>Profile</TextLarge>
        <Nav>
          {authRoutes
            .find((r) => r.path === "/profile/")
            .outlet.map(
              (p) =>
                p.title && (
                  <NavElement
                    key={p.path}
                    className={
                      pathname === `/profile/${p.path}` ? "active" : null
                    }
                    onClick={() => nav(p.path)}
                  >
                    {adpt !== "mob" && <LineBox />}
                    <TextMedium c="rgba(0, 0, 0, 0.35)">{p.title}</TextMedium>
                    {adpt === "mob" && <LineBox />}
                  </NavElement>
                ),
            )}
        </Nav>
      </HeaderContainer>
      <Outlet />
    </>
  );
};
