import { $api } from ".";

export const breedingApi = {
  checkBreeding(wallet) {
    return $api
      .get("/breeding/checkBreeding", {
        params: { wallet },
      })
      .then((res) => res);
  },
  getGencode(wallet, tokenId) {
    return $api
      .post("/breeding/gencode", { wallet, tokenId })
      .then((res) => res);
  },
  getGencodeInfo(gencode) {
    return $api
      .get("/breeding/gencodeInfo", {
        params: { gencode },
      })
      .then((res) => res);
  },
  pushFriend(selfGencode, friendGencode, selfTwitter, friendTwitter) {
    return $api
      .get("/breeding/pushFriend", {
        params: { selfGencode, friendGencode, selfTwitter, friendTwitter },
      })
      .then((res) => res);
  },
  claim(tokenId, wallet) {
    return $api
      .post("/breeding/claim", {
        tokenId,
        wallet,
      })
      .then((res) => res);
  },
};
