import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import appStore from "../../assets/images/delivery/appstore.png";
import ready from "../../assets/images/delivery/ready.png";
import mainBackground from "../../assets/videos/success/success.mp4";
import { deleteCartItemThunk } from "../../features/xsolla/xsollaSlice";
import { useIsAdaptive } from "../../hooks/useIsAdaptive";
import {
  BackVideo,
  BlockButtons,
  Border,
  BorderBottom,
  ButtonCycle,
  ButtonGoToProfile,
  ButtonWithAppStore,
  CenterBlock,
  Container,
  CycleBlock,
  // BorderBottom,
  // Content,
  FlexContainer,
  ImgReady,
  LeftBlock,
  RightBlock,
  TextButton,
  TextCycleButton,
  TextLarge,
  TextLeftBlock,
  TextSmall,
  TextSmallAdpt,
  WrapperVideo,
  YouSuccessfully,
} from "./WrapperDeliveryStyle";

export const WrapperDelivery = ({ notDelivery, claimed }) => {
  const nav = useNavigate();
  const adpt = useIsAdaptive();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const cartId = useSelector((state) => state.xsolla.cartId);
  const items = useSelector((state) => state.xsolla.items);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!claimed && items.length !== 0 && cartId) {
      dispatch(deleteCartItemThunk({ cartId, itemSku: items[0].sku }));
    }
  }, [items]);

  return (
    <WrapperVideo claimed={claimed}>
      {!claimed && (
        <BackVideo muted autoPlay loop playsInline>
          <source src={mainBackground} type="video/mp4" />
        </BackVideo>
      )}
      <Container>
        <FlexContainer>
          {adpt !== "mob" ? (
            <>
              <Border className="full" claimed={claimed} />
              <Border className="right" claimed={claimed} />
            </>
          ) : (
            <Border className="full" claimed={claimed} />
          )}
        </FlexContainer>
        <CenterBlock>
          <LeftBlock>
            <YouSuccessfully>
              <ImgReady src={ready} alt="ready" claimed={claimed} />
              <TextLarge c={claimed ? "#fff" : "#000"}>You have</TextLarge>
            </YouSuccessfully>
            {notDelivery ? (
              <>
                <TextLarge c={claimed ? "#fff" : "#000"}>purchased</TextLarge>
                <TextLarge c={claimed ? "#fff" : "#000"}>
                  the Pillz NFT
                </TextLarge>
                <TextLeftBlock width={adpt !== "mob" ? "25.271vw" : "62.944vw"}>
                  <TextSmallAdpt>
                    It&apos;s going to be on you crypto wallets balance in
                    approx. 10 minutes from now.{" "}
                    <b>We will send an NFT to your Ethereum wallet</b>.
                  </TextSmallAdpt>
                </TextLeftBlock>
              </>
            ) : (
              <>
                <TextLarge c={claimed ? "#fff" : "#000"}>
                  {claimed ? "redeemed" : "successfully purchased"}
                </TextLarge>
                <TextLarge c={claimed ? "#fff" : "#000"}>
                  The Pillz NFT
                </TextLarge>
                <TextLeftBlock width={adpt !== "mob" ? "24.271vw" : "70.051vw"}>
                  <TextSmallAdpt c={claimed ? "#fff" : "#000"}>
                    {isAuth
                      ? "Your redeemable Pillz NFT is minted. You will be able to redeem it 3 months before shipping"
                      : "Login to see your redeemable Pillz NFT and playable Cellz NFT!"}
                  </TextSmallAdpt>
                </TextLeftBlock>
                <ButtonGoToProfile
                  claimed={claimed}
                  onClick={() =>
                    isAuth ? nav("/profile/tokens") : nav("/login")
                  }
                >
                  <TextButton claimed={claimed}>
                    {isAuth ? "My Devices" : "Login"}
                  </TextButton>
                </ButtonGoToProfile>
              </>
            )}
          </LeftBlock>
          <RightBlock>
            <CycleBlock>
              {notDelivery ? (
                <>
                  <TextLarge c="#FFFFFF">Delivery</TextLarge>
                  {adpt !== "mob" ? (
                    <TextSmall c="#FFFFFF">
                      You can always redeem your Pillz NFT to make delivery, get
                      <br /> a playable character and access to the Pillz
                      ecosystem
                    </TextSmall>
                  ) : (
                    <TextSmall c="#FFFFFF">
                      You can always redeem your Pillz NFT to make
                      <br />
                      delivery, get a playable character and access
                      <br />
                      to the Pillz ecosystem
                    </TextSmall>
                  )}
                  <ButtonCycle onClick={() => nav("/redeem")}>
                    <TextCycleButton c="#FFFFFF">Redeem</TextCycleButton>
                  </ButtonCycle>
                </>
              ) : (
                <>
                  <TextLarge c="#FFFFFF">Cellz pet</TextLarge>
                  {adpt !== "mob" ? (
                    <TextSmall isAuth={isAuth} c="#FFFFFF">
                      Welcome to the Cellz gaming universe.
                      <br /> Your Cellz will be airdropped to your wallet.
                      <br /> Game release: June 2023.
                    </TextSmall>
                  ) : (
                    <TextSmall isAuth={isAuth} c="#FFFFFF">
                      Welcome to the Cellz gaming universe.
                      <br /> Your Cellz will be airdropped to your wallet.
                      <br /> Game release: June 2023.
                    </TextSmall>
                  )}
                  {isAuth && (
                    <BlockButtons>
                      {/* <ImageAppStore src={appStore} alt="appStore" /> */}
                      <ButtonWithAppStore
                        onClick={() =>
                          isAuth ? nav("/profile/pets") : nav("/login")
                        }
                      >
                        <TextCycleButton c="#FFFFFF">My pets</TextCycleButton>
                      </ButtonWithAppStore>
                    </BlockButtons>
                  )}
                </>
              )}
            </CycleBlock>
          </RightBlock>
        </CenterBlock>
        <FlexContainer>
          {adpt !== "mob" ? (
            <>
              <BorderBottom className="full" claimed={claimed} />
              <BorderBottom className="right" claimed={claimed} />
            </>
          ) : (
            <BorderBottom className="full" claimed={claimed} />
          )}
        </FlexContainer>
      </Container>
    </WrapperVideo>
  );
};
