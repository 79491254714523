// import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app/App";
import { store } from "./app/store";
import "./assets/fonts/stylesheet.css";
import "./index.css";

// Sentry.init({
//   dsn: "https://e0b5a1a9c1b540b79d2744c86bad8850@o4504393623863296.ingest.sentry.io/4504393628844033",
//   environment: "development",
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: ["localhost", /^https:\/\/v1\.sso\.pillz\.tech/],
//     }),
//     new Sentry.Replay(),
//   ],

//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0,
//   replaysOnErrorSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
);
