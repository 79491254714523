import { PopupButton } from "@typeform/embed-react";
import styled from "styled-components";
import { CatalogImage } from "../../common/elements/Images";
import { Large, Medium, Small } from "../../common/elements/Text";

export const LoadingContainer = styled.div`
  position: absolute;
  top: 42.6%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CatalogCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.521vw;
  @media (max-width: 1280px) {
    gap: 0.781vw;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 5.128vw;
    padding-bottom: 25vw;
  }
`;

export const MediumStyled = styled(Small)`
  position: absolute;
  top: ${(props) => props.t || "auto"};
  left: ${(props) => props.l || "auto"};
  bottom: ${(props) => props.b || "auto"};
  right: ${(props) => props.r || "auto"};
  color: ${(props) => props.c || "#000"};
`;

export const LargeStyled = styled(Large)`
  position: absolute;
  white-space: nowrap;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

export const BuySecondary = styled(Medium)`
  font-size: 1.875vw;
  line-height: 1.875vw;
  position: absolute;
  bottom: 8.229vw;
  cursor: pointer;
`;

export const ButtonAbsolute = styled.div`
  position: absolute;
  left: 50%;
  bottom: 1.042vw;
  transform: translate(-50%, 0);
  @media (max-width: 1280px) {
    bottom: 1.302vw;
  }
  @media (max-width: 768px) {
    bottom: 2.564vw;
  }
`;

export const ActionButton = styled.button`
  box-sizing: border-box;
  width: 10.417vw;
  height: 2.24vw;
  border-radius: 0.521vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.zebra || "none"});
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  &.logout {
    border: 0.052vw solid #ff434f;
  }
  @media (max-width: 1280px) {
    width: 9.375vw;
    height: 2.578vw;
    border-radius: 0.391vw;
  }
  @media (max-width: 768px) {
    width: 25.641vw;
    height: 8.462vw;
    border-radius: 1.282vw;
  }
`;

export const ActionTypeFormButton = styled(PopupButton)`
  width: 0vw;
  height: 0vw;
  visibility: hidden;
`;

export const CatalogCard = styled.div`
  height: 30.729vw;
  width: 30.729vw;
  background-color: #fff;
  border-radius: 1.042vw;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: ${(props) =>
    !props.zebra && props.status !== "PLANNED" ? "pointer" : "not-allowed"};
  ${CatalogImage} {
    transition: all 700ms ease-out;
  }
  &:hover ${CatalogImage} {
    transition: all 700ms ease-out;
    transform: scale(1.02);
  }
  ${ActionButton} {
    transition: all 700ms ease-out;
    background-color: ${(props) => !props.zebra && "#F2530F"};
    cursor: ${(props) =>
      !props.zebra && props.status !== "PLANNED" ? "pointer" : "not-allowed"};
    ${Small} {
      color: ${(props) => !props.zebra && "#fff"};
      transition: all 700ms ease-out;
    }
  }
  &:hover ${ActionButton} {
    transition: all 700ms ease-out;
    background-color: ${(props) => !props.zebra && "#FF6F32"};
    transform: scale(1.02);
    ${Small} {
      color: ${(props) => !props.zebra && "#fff"};
    }
  }
  @media (max-width: 1280px) {
    height: 30.469vw;
    width: 30.469vw;
    border-radius: 1.042vw;
  }
  @media (max-width: 768px) {
    min-height: 92.968vw;
    width: 92.968vw;
    border-radius: 3.077vw;
  }
`;
