import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

export const NavigateAdminContainer = styled.div`
  padding-top: 7.5vw;
`;

export const AdminNavigation = styled.div`
  height: 3vw;
  background-color: #e3e3e3;
  display: flex;
  align-items: center;
  padding: 0 2vw;
  gap: 1vw;
`;

export const NavButton = styled.button`
  height: 2vw;
  padding: 0 1vw;
  border-radius: 1vw;
  background-color: #fff;
  &.active {
    background-color: #f2530f;
    color: #fff;
  }
`;

export const StyledOutlet = styled(Outlet)`
  height: calc(100vh - 6.576vw - 2.604vw - 3vw);
`;

export const NavigateAdminPage = () => {
  const { pathname } = useLocation();
  const nav = useNavigate();

  return (
    <NavigateAdminContainer>
      <AdminNavigation>
        <NavButton
          className={pathname === "/admin/product" ? "active" : null}
          onClick={() => nav("/admin/product")}
        >
          PRODUCT
        </NavButton>
        <NavButton
          className={pathname === "/admin/drop" ? "active" : null}
          onClick={() => nav("/admin/drop")}
        >
          DROP
        </NavButton>
        <NavButton
          className={pathname === "/admin/delivery" ? "active" : null}
          onClick={() => nav("/admin/delivery")}
        >
          DELIVERY
        </NavButton>
      </AdminNavigation>
      <StyledOutlet />
    </NavigateAdminContainer>
  );
};
