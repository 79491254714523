import Lottie from "lottie-react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import loader from "../../../assets/lottie/l_g.json";
import { StringSelect } from "../../../common/components/select/Select";
import { Medium, Small } from "../../../common/elements/Text";
import { checkTokensThunk } from "../../../features/claim/claimSlice";
import { useIsAdaptive } from "../../../hooks/useIsAdaptive";
import { SpanBlue } from "../../claim/ClaimStyle";
// import test from "../../../assets/images/profile/test.png";

const Container = styled.div`
  margin-top: 1.354vw;
  display: flex;
  flex-direction: column;
  gap: 0.781vw;

  @media (max-width: 768px) {
    margin-top: 0;
    gap: 2.538vw;
  }
`;

export const Cards = styled.div`
  /* width: 30.729vw; */
  width: 46.4vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  gap: 0.521vw;
  @media (max-width: 768px) {
    width: 100%;
    gap: 3vw;
  }
`;

export const Card = styled.div`
  width: 15.104vw;
  height: 22.323vw;
  border-radius: 1.042vw;
  box-sizing: border-box;
  border: 0.052vw solid rgba(255, 255, 255, 0.3);
  :hover {
    border: 0.052vw solid rgba(255, 255, 255, 0.3);
  }
  &.active {
    border: 0.052vw solid rgba(255, 255, 255, 1);
  }
  background-color: ${(props) => (props.white ? "#fff" : "#000")};
  border: 0.052vw solid
    ${(props) => (props.white ? "rgba(0, 0, 0, 0.1)" : "#fff")};
  @media (max-width: 768px) {
    width: 100%;
    height: 118.817vw;
    border-radius: 2.564vw;
    border: 0.256vw solid
      ${(props) => (props.white ? "rgba(0, 0, 0, 0.1)" : "#fff")};
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 15.104vw;
  border-radius: 1.042vw;
  @media (max-width: 768px) {
    width: 100%;
    height: 90.863vw;
    border-radius: 5.076vw;
  }
`;

export const CardVideo = styled.video`
  width: 100%;
  height: 15.104vw;
  border-radius: 1.042vw;
  @media (max-width: 768px) {
    width: 100%;
    height: 90.863vw;
    border-radius: 5.076vw;
  }
`;

export const FlexName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.042vw;
  padding: 0 0.938vw 0.938vw 0.938vw;
  height: 9.219vw;
  @media (max-width: 768px) {
    justify-content: flex-start;
    padding: 0 6.599vw 6.599vw 6.599vw;
    gap: 6.599vw;
    height: 46.954vw;
  }
`;

export const ShineButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0.521vw 1.042vw;
  border-radius: 0.521vw;
  background-color: ${(props) => !props.game && "#F2530F"};
  transition: all 300ms ease-out;
  width: 30.729vw;
  &:hover {
    transition: all 300ms ease-out;
    background-color: ${(props) => !props.game && "#FF6F32"};
  }
  @media (max-width: 1280px) {
    padding: 0.521vw 1.042vw;
    border-radius: 0.781vw;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BuyText = styled(Small)`
  color: #fff;
  white-space: nowrap;
`;

export const ButtonPlay = styled.button`
  width: 13.229vw;
  height: 2.865vw;
  cursor: default;
  border-radius: 0.521vw;
  border: 0.052vw solid rgba(255, 255, 255, 0.4);
  background-color: #000;
  @media (max-width: 768px) {
    width: 100%;
    height: 11.168vw;
    border-radius: 2.03vw;
  }
`;

export const TextPlay = styled(Small)``;

export const TextMedium = styled(Medium)`
  margin-top: 1.354vw;
  font-weight: 450;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const StyledSpan = styled.span`
  cursor: pointer;
  color: #f2530f;
  transition: all 300ms ease-in;
  :hover {
    transition: all 300ms ease-out;
    color: #ff6f32;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const LottieContainer = styled.div`
  width: 6vw;
  height: 2vw;
  @media (max-width: 768px) {
    width: 15vw;
    height: 6vw;
  }
`;

export const TextSmall = styled(Small)`
  font-size: 0.625vw;
  line-height: 0.875vw;
  @media (max-width: 768px) {
    font-size: 3.299vw;
    line-height: 4.619vw;
  }
`;

export const TextFuckMedium = styled(Medium)`
  @media (max-width: 768px) {
    font-size: 7.462vw;
    line-height: 8.954vw;
  }
`;

export const YourTokens = () => {
  const wallet = useSelector((state) => state.profile.wallet);
  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.claim.isLoading);
  const tokens = useSelector((state) => state.claim.tokens);
  const adpt = useIsAdaptive();
  const dispatch = useDispatch();

  const selectWallet = (value) => {
    dispatch(checkTokensThunk({ wallet: value }));
  };

  return (
    <Container>
      <StringSelect
        bc="#FFF"
        label="Wallet"
        notyping
        w={adpt !== "mob" ? "15vw" : "100%"}
        fc="rgba(0,0,0,0.35)"
        value={wallet}
        values={user?.wallets || []}
        setValue={(value) => selectWallet(value)}
      />
      {isLoading && (
        <Container>
          <LottieContainer>
            <Lottie animationData={loader} loop />
          </LottieContainer>
        </Container>
      )}
      {tokens.length === 0 && !isLoading ? (
        <TextMedium c="#000">
          {!wallet ? (
            <>
              Select your wallet <br />
              to see your Pillz NFTs
            </>
          ) : (
            <>
              If you just purchased Pillz - your redeemable
              <br /> Pillz NFT is on its way to you. In case you don&apos;t see{" "}
              <br />
              it within 4h after payment please open a ticket in{" "}
              <SpanBlue href="https://discord.gg/pillz">Discord</SpanBlue> or
              contact us at{" "}
              <SpanBlue href="mailto:hi@pillz.tech">
                support@pillz.tech
              </SpanBlue>
            </>
          )}
        </TextMedium>
      ) : (
        <Cards>
          {tokens.length > 0 &&
            tokens.map((el) => (
              <Card key={el.name} white={el.collection === "The Pillz"}>
                {el.metadata.image?.includes("ipfs://") ? (
                  <CardVideo
                    src={`https://pillz-tech.infura-ipfs.io/ipfs/${el.metadata.image.replace(
                      "ipfs://",
                      "",
                    )}`}
                    muted
                    autoPlay
                    loop
                    playsInline
                  />
                ) : (
                  <CardImage
                    src={el.metadata.image}
                    muted
                    autoPlay
                    loop
                    playsInline
                  />
                )}
                <FlexName>
                  <TextFuckMedium
                    c={el.collection === "The Pillz" ? "#000" : "#fff"}
                  >
                    {el.name}
                  </TextFuckMedium>
                  <TextSmall
                    c={
                      el?.attributes?.find((a) => a.trait_type === "REDEEMED")
                        ?.value === "YES"
                        ? "#FF434F"
                        : "#2AE448"
                    }
                  >
                    {el?.attributes?.find((a) => a.trait_type === "REDEEMED")
                      ?.value === "YES"
                      ? "Redeemed"
                      : "Not redeemed"}
                  </TextSmall>
                </FlexName>
              </Card>
            ))}
        </Cards>
      )}
    </Container>
  );
};
