/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import coinbase from "../../../assets/images/wallet/coinbase.png";
import ledger from "../../../assets/images/wallet/ledger.png";
import metamask from "../../../assets/images/wallet/metamask.png";
import walletConnect from "../../../assets/images/wallet/wallet_connect.png";
import { ButtonLogin } from "../../../common/components/button/Button";
import { TextInput } from "../../../common/components/input/Input";
import { Medium, Small } from "../../../common/elements/Text";
import {
  deleteWallet,
  loginEmailChangeOTP,
  logoutReducer,
  setNoValid,
} from "../../../features/auth/authSlice";
import { addNotif } from "../../../features/notification/notificationSlice";
import { otpChangeEmailThunk } from "../../../features/profile/profileSlice";
import { useWallet } from "../../../hooks/use-wallet";
import { useForm } from "../../../hooks/useForm";
import { useIsAdaptive } from "../../../hooks/useIsAdaptive";
import {
  ImageCoinbase,
  ImageLedger,
  ImageMetaMask,
  ImageWalletConnect,
} from "../../login/LoginStyle";
import { OTPCode } from "../../login/code/OTPCode";
import { CartTitles } from "../ProfileStyle";
import { TextMedium } from "./LinkedDevices";
import { BlockCredential, ButtonDelete, Text, TextDelete } from "./allStyles";

const Container = styled.div`
  padding-top: 1.354vw;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 0;
  }
`;

export const Inline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.g};
  padding-bottom: ${(props) => props.pb};
  padding-top: ${(props) => props.pt};
  border-bottom: ${(props) => props.bb};
  flex-direction: ${(props) => props.fd};
  &.wallets {
    gap: 0.421vw;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    &.wallets {
      gap: 3.807vw;
    }
  }
`;

// const Input = styled.input`
//   width: ${(props) => props.w || "100%"};
//   background-color: #e0e0e0;
//   height: 2.865vw;
//   border: 0.052vw solid ${(props) => props.brc || "rgba(0, 0, 0, 0.15)"};
//   border-radius: 0.521vw;
//   padding: 0.521vw 0.833vw 0.521vw 0.833vw;
//   outline: none;
//   box-shadow: none;
//   transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
//   color: ${(props) => props.c || "#000000"};
//   font-family: "medium";
//   @media (max-width: 768px) {
//     font-size: 3.333vw;
//     line-height: 4.667vw;
//     height: 11.282vw;
//     border: 0.256vw solid ${(props) => props.brc || "rgba(0, 0, 0, 0.15)"};
//     border-radius: 2.051vw;
//     padding: 3.807vw 3.846vw 3.299vw 3.807vw;
//   }
// `;

const WalletText = styled(Medium)`
  font-size: 1.563vw;

  @media (max-width: 768px) {
    font-size: 4.569vw;
    line-height: 5.025vw;
  }
`;

export const Form = styled.form`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Credentials = () => {
  // const [newEmail, setNewEmail] = useState("ermakov20002000@mail.ru");
  const inputRef = useRef(null);
  const {
    address,
    isConnected,
    connect,
    connectors,
    goSignMessage,
    disconnect,
  } = useWallet();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const adpt = useIsAdaptive();

  const { values, onChange, isValid, errors } = useForm();
  const [isSendCode, setIsSendCode] = useState(false);
  const [codeStage, setCodeStage] = useState(false);
  const [pinReady, setPinReady] = useState(false);
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [isDisabledInput, setDisabledInput] = useState(false);
  const noValid = useSelector((state) => state.auth.noValid);

  useEffect(() => {
    const login = async () => {
      if (pinReady && values?.email) {
        const res = await dispatch(
          loginEmailChangeOTP({ otp: code, email: values?.email }),
        ).unwrap();
        if (res) {
          setIsSendCode(false);
          setPinReady(false);
          setCode("");
          setCodeStage(false);
          setDisabledInput(false);
          dispatch(
            addNotif({
              type: "complete",
              text: "Email changed",
            }),
          );
        }
      } else {
        dispatch(setNoValid());
      }
    };

    login();
  }, [pinReady]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const deleteOneWallet = async (w) => {
    if (user?.email && user?.wallets.length === 1) {
      dispatch(logoutReducer());
    } else if (user?.email && user?.wallets.length > 1) {
      await dispatch(deleteWallet({ walletAddress: w }));
    } else if (!user?.email && user?.wallets.length > 1) {
      await dispatch(deleteWallet({ walletAddress: w }));
    } else if (!user?.email && user?.wallets.length === 1) {
      dispatch(logoutReducer());
    }
  };

  const changeEmail = () => {
    setIsSendCode(true);
  };

  const sendOTP = () => {
    if (values?.email) {
      dispatch(otpChangeEmailThunk({ email: values?.email }))
        .then((result) => {
          if (result.error) {
            setIsSendCode(false);
            setPinReady(false);
            setCode("");
            setCodeStage(false);
            setDisabledInput(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    setCode("");
    setCodeStage(true);
    setSeconds(30);
    setDisabledInput(true);
  };

  return (
    <Container>
      <BlockCredential
        w={isSendCode && codeStage && "50%"}
        mb={adpt !== "mob" ? "2.188vw" : "6.345vw"}
      >
        <Text c={adpt !== "mob" ? "#000" : "rgba(0, 0, 0, 0.35)"}>
          Email for notifications and Log in:
        </Text>
        <Inline g={adpt === "mob" && "3vw"} fd={adpt === "mob" && "column"}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              sendOTP();
            }}
          >
            <TextInput
              ref={inputRef}
              p={
                adpt !== "mob"
                  ? "0.521vw 0.833vw 0.521vw 0.833vw"
                  : "3.807vw 3.846vw 3.299vw 3.807vw"
              }
              name="email"
              type="email"
              value={!isSendCode ? user?.email : values?.email}
              onChange={onChange}
              errorMessage={errors?.email}
              w={adpt !== "mob" ? "15.104vw" : "100%"}
              disabled={!isSendCode || isDisabledInput}
              c={
                !isSendCode || isDisabledInput ? "rgba(0, 0, 0, 0.35)" : "#000"
              }
              bc={
                !isSendCode || isDisabledInput ? "rgba(0, 0, 0, 0.15)" : "#fff"
              }
            />
          </Form>
          {isSendCode ? (
            !codeStage ? (
              <ButtonLogin
                disabled={!isValid}
                title="Send code"
                wM={adpt === "mob" && "100%"}
                action={() => sendOTP()}
              />
            ) : (
              <>
                <OTPCode
                  code={code}
                  noValid={noValid}
                  setCode={setCode}
                  pinReady={pinReady}
                  setPinReady={setPinReady}
                />
                {seconds !== 0 ? (
                  <Small c="rgba(0, 0, 0, 0.35)">
                    Send code again in{" "}
                    {seconds < 10 ? `0:0${seconds}` : `0:${seconds}`}
                  </Small>
                ) : (
                  <ButtonLogin
                    wM={adpt === "mob" && "44.416vw"}
                    title="Resend"
                    action={() => sendOTP()}
                  >
                    {/* <Medium c="#FFF">Resend</Medium> */}
                  </ButtonLogin>
                )}
              </>
            )
          ) : (
            <Text
              c="#0075FF"
              style={{ cursor: "pointer" }}
              onClick={() => changeEmail()}
            >
              Change
            </Text>
          )}
        </Inline>
      </BlockCredential>
      {user?.wallets.length === 0 && (
        <TextMedium c="#000" className="cred">
          Add your wallet to see your devices and Cellz pets!
        </TextMedium>
      )}
      {!user?.email && (
        <TextMedium c="#000" className="cred">
          Add your email to get important notifications!
        </TextMedium>
      )}
      {user?.wallets.length !== 0 && (
        <BlockCredential
          mb={adpt !== "mob" ? "2.188vw" : "6.345vw"}
          g={adpt === "mob" && "0"}
        >
          <CartTitles border="rgba(0, 0, 0, 0.15)">
            <Text c={adpt !== "mob" ? "#000" : "rgba(0, 0, 0, 0.35)"}>
              Connected wallets
            </Text>
          </CartTitles>
          {user?.wallets.map((w, index) => (
            <Inline
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              pb={adpt !== "mob" ? "0.781vw" : "2.030vw"}
              pt={adpt === "mob" && "2.030vw"}
              bb={
                adpt !== "mob"
                  ? "0.052vw solid rgba(0, 0, 0, 0.15)"
                  : "0.256vw solid rgba(0, 0, 0, 0.15)"
              }
            >
              <WalletText>
                {w.slice(0, 6)}...{w.slice(-9)}
              </WalletText>
              {adpt !== "mob" ? (
                <Text
                  c="#F2530F"
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteOneWallet(w)}
                >
                  Delete
                </Text>
              ) : (
                <ButtonDelete onClick={() => deleteOneWallet(w)}>
                  <TextDelete c="#F2530F">Delete</TextDelete>
                </ButtonDelete>
              )}
            </Inline>
          ))}
        </BlockCredential>
      )}

      <BlockCredential
        mb={adpt === "mob" && "0"}
        g={adpt === "mob" && "2.538vw"}
      >
        <Text c={adpt !== "mob" ? "#000" : "rgba(0, 0, 0, 0.35)"}>
          Connect {user?.wallets.length !== 0 && "new"} wallet
        </Text>
        <Inline g="0.521vw" className="wallets">
          {isConnected && address && !user.wallets.includes(address) ? (
            <>
              <ButtonLogin action={() => goSignMessage()} wM="70.5vw">
                <Small c="#FFF">Sign message</Small>
              </ButtonLogin>
              <ButtonLogin
                wM="18vw"
                w="5.313vw"
                bg="rgba(255, 67, 79, 0.1)"
                c="rgba(255, 67, 79, 1)"
                action={() => disconnect()}
                type="button"
              >
                <Small c="#FF434F">Decline</Small>
              </ButtonLogin>
            </>
          ) : (
            <>
              <ButtonLogin
                wM="100%"
                bg="#0075FF"
                action={() => {
                  connect({ connector: connectors[0] });
                }}
              >
                <ImageWalletConnect src={walletConnect} />
              </ButtonLogin>
              {window?.ethereum && window?.ethereum?.isMetaMask && (
                <ButtonLogin
                  wM="100%"
                  bg="#000000"
                  action={() => connect({ connector: connectors[1] })}
                >
                  <ImageMetaMask src={metamask} />
                </ButtonLogin>
              )}
              <ButtonLogin
                wM="100%"
                bg="#0052FF"
                action={() => connect({ connector: connectors[2] })}
              >
                <ImageCoinbase src={coinbase} />
              </ButtonLogin>
              <ButtonLogin
                wM="100%"
                bg="#FF5300"
                action={() => connect({ connector: connectors[3] })}
              >
                <ImageLedger src={ledger} />
              </ButtonLogin>
            </>
          )}
        </Inline>
      </BlockCredential>
    </Container>
  );
};
