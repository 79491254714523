import styled from "styled-components";

export const Minus = styled.img`
  width: 2.188vw;
  height: 0.417vw;
  opacity: ${(props) => (props.isLoading ? 0.2 : 1)};
  &.new {
    width: 0.739vw;
    height: 0.18vw;
  }
  @media (max-width: 768px) {
    width: 5vw;
    height: 1.1vw;
    &.cart {
      width: 3vw;
      height: 0.6vw;
    }
  }
`;

export const Plus = styled.img`
  width: 2.188vw;
  height: 2.188vw;
  opacity: ${(props) => (props.isLoading ? 0.2 : 1)};
  &.new {
    width: 0.739vw;
    height: 0.739vw;
  }
  @media (max-width: 768px) {
    width: 5vw;
    height: 5vw;
    &.cart {
      width: 3vw;
      height: 3vw;
    }
  }
`;

export const CatalogImage = styled.img`
  height: 30.729vw;
  max-height: 100%;
  width: auto;
  pointer-events: none;
  border-radius: 1.059vw;
  @media (max-width: 1280px) {
    height: 30.469vw;
  }
  @media (max-width: 768px) {
    height: 92.968vw;
  }
`;

export const CatalogVideo = styled.video`
  height: 30.729vw;
  max-height: 100%;
  width: auto;
  pointer-events: none;
  border-radius: 1.059vw;
  @media (max-width: 1280px) {
    height: 30.469vw;
  }
  @media (max-width: 768px) {
    height: 92.968vw;
  }
`;
