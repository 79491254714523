/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable dot-notation */
/* eslint-disable no-undef */
import { $api } from ".";

export const adminApi = {
  newDevice(values, technical) {
    return $api
      .post("/admin/device", {
        ...values,
        technical,
      })
      .then((res) => res);
  },
  editDevice(id, values, technical) {
    return $api
      .put(`/admin/device/${id}`, {
        ...values,
        technical,
      })
      .then((res) => res);
  },
  deleteDevice(id) {
    return $api.delete(`/admin/device/${id}`).then((res) => res);
  },
  newEquipment(id, name, photo, order) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("photo", photo);
    formData.append("order", order);
    return $api
      .post(`/admin/device/${id}/equipment`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res);
  },
  editEquipment(deviceId, equipmentId, name, photo, order) {
    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (photo) {
      formData.append("photo", photo);
    }
    if (order) {
      formData.append("order", order);
    }

    return $api
      .put(`/admin/device/${deviceId}/equipment/${equipmentId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res);
  },
  deleteEquipment(id) {
    return $api.delete(`/admin/device/equipment/${id}`).then((res) => res);
  },
  getDevices() {
    return $api.get("/admin/device").then((res) => res);
  },
  newDrop(values) {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    return $api
      .post("/admin/drop", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res);
  },
  putDrop(values) {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    return $api
      .put("/admin/drop", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res);
  },
  deleteDrop(id) {
    return $api.delete(`/admin/drop/${id}`).then((res) => res);
  },
  getDrops() {
    return $api.get("/admin/drop").then((res) => res);
  },
  putDeviceInDrop(dropId, deviceId) {
    return $api
      .put(`/admin/drop/${dropId}/device/${deviceId}`)
      .then((res) => res);
  },
  deleteDeviceInDrop(dropId, deviceId) {
    return $api
      .delete(`/admin/drop/${dropId}/device/${deviceId}`)
      .then((res) => res);
  },
  getOrders() {
    return $api.get("/admin/orders").then((res) => res);
  },
};
