import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { $apiGame } from "../../api";
import { addNotif } from "../../features/notification/notificationSlice";
import { useIsAdaptive } from "../../hooks/useIsAdaptive";

const IframeGameWrapper = styled.div`
  width: 100%;
  height: ${({ dynamicHeight }) => dynamicHeight}px;
  overflow: hidden;
`;

const IframeGame = styled.iframe`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
`;

export const CellzGame = () => {
  const Location = useLocation();
  const { cellzName } = Location.state || false;
  const adpt = useIsAdaptive();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [dynamicHeight, setDynamicHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateDynamicHeight = () => {
      setDynamicHeight(window.innerHeight);
    };

    updateDynamicHeight();
    window.addEventListener("resize", updateDynamicHeight);

    return () => {
      window.removeEventListener("resize", updateDynamicHeight);
    };
  }, []);

  useEffect(() => {
    const prevOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = prevOverflow;
    };
  }, []);

  const queryParam0 = localStorage.getItem("refreshToken");
  const queryParam1 = localStorage.getItem("accessToken");
  // const queryParam2 = cellzName;

  const queryParams = new URLSearchParams();
  queryParams.append("refresh_token", queryParam0);
  queryParams.append("access_token", queryParam1);
  // queryParams.append("cellzName", queryParam2);

  useEffect(() => {
    if (!cellzName) {
      nav("/");
    } else if (adpt !== "mob") {
      dispatch(
        addNotif({
          type: "cancel",
          text: "Game allowed only mobile devices",
        }),
      );
      nav("/profile/pets");
    } else if (
      cellzName &&
      localStorage.getItem("accessToken") &&
      localStorage.getItem("refreshToken")
    ) {
      $apiGame
        .get("/set-active", {
          params: { name: cellzName, jwt: queryParam1 },
        })
        .then((res) => res);
    }
  }, [cellzName, adpt]);

  if (
    cellzName &&
    localStorage.getItem("accessToken") &&
    localStorage.getItem("refreshToken")
  ) {
    return (
      <IframeGameWrapper dynamicHeight={dynamicHeight}>
        <IframeGame
          allowFullScreen
          src={`https://game-srv.pillz.tech?${queryParams.toString()}`}
        />
      </IframeGameWrapper>
    );
  }

  return null;
};
