import { useEffect, useState } from "react";

const getIsMobile = () => (window.innerWidth <= 768 ? "mobile" : null);
const getIsDesktop = () => (window.innerWidth > 1280 ? "pc" : null);

const checkCurrentDevice = () => {
  if (getIsMobile() !== null) {
    return "mob";
  }
  if (getIsDesktop() !== null) {
    return "pc";
  }

  return "tablet";
};
export const useIsAdaptive = () => {
  const [adpt, setIsAdpt] = useState(checkCurrentDevice());

  useEffect(() => {
    const onResize = () => {
      setIsAdpt(checkCurrentDevice());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return adpt;
};
