import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { goerli, mainnet } from "wagmi/chains";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { infuraProvider } from "wagmi/providers/infura";
import { Notification } from "../common/components/notification/Notification";
import { Header } from "../components/header/Header";
import { initializeApp, setInitialized } from "../features/auth/authSlice";
import { xsollaInitializeThunk } from "../features/xsolla/xsollaSlice";
import {
  PageContainer,
  adminRoute,
  authRoutes,
  noAuthRoutes,
  routes,
  supportRoute,
} from "../pages";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: medium;
    font-size: 0.833vw;
    line-height: 1.167vw;
    font-weight: 530;
    letter-spacing: 2%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    color: #000000;
    letter-spacing: 0.02em;
    background-color: ${(props) => (props.whiteTheme ? "#f2f2f2" : "#000")};
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    scroll-behavior: smooth;
  }

  :root {
    background-color: ${(props) => (props.whiteTheme ? "#f2f2f2" : "#000")};
  }
`;

const { publicClient, webSocketPublicClient, chains } = configureChains(
  [mainnet, goerli],
  [
    infuraProvider({
      apiKey: "731c9521063740c3bb49ed9f8cc55f9a",
      rpcUrl: "https://mainnet.infura.io/v3/731c9521063740c3bb49ed9f8cc55f9a",
    }),
  ],
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "594854becd34adcbca118def6f25cea8",
      },
    }),
    new MetaMaskConnector({
      chains,
      options: {
        shimDisconnect: true,
        UNSTABLE_shimOnConnectSelectAccount: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "Pillz Inc.",
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

export const App = () => {
  const { pathname } = useLocation();
  const isInitialized = useSelector((state) => state.auth.isInitialized);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const whiteTheme =
    (routes.find((r) => r.path.includes(pathname.slice(0, 4)))?.isWhite ||
      noAuthRoutes.find((r) => r.path.includes(pathname.slice(0, 4)))
        ?.isWhite ||
      authRoutes.find((r) => r.path.includes(pathname.slice(0, 4)))?.isWhite ||
      adminRoute.path.includes(pathname.slice(0, 6)) ||
      supportRoute.path.includes(pathname.slice(0, 6))) &&
    !pathname.includes("/catalog/4d610900-b7f5-402b-a5f1-c24b383f1f14");

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      dispatch(initializeApp());
    } else {
      dispatch(setInitialized());
      dispatch(xsollaInitializeThunk({}));
    }
  }, []);

  if (!isInitialized) {
    return null;
  }

  return (
    <WagmiConfig config={config}>
      <GlobalStyle whiteTheme={whiteTheme} />
      <Notification whiteTheme={whiteTheme} />
      <Header whiteTheme={whiteTheme} />
      <Suspense fallback={<></>}>
        <Routes>
          {routes.map(({ path, element, noContainer, outlet }) =>
            outlet ? (
              <Route
                exact
                path={path}
                key={path}
                element={
                  noContainer ? (
                    element
                  ) : (
                    <PageContainer>{element}</PageContainer>
                  )
                }
              >
                {outlet.map(({ path, element }) => (
                  <Route exact path={path} key={path} element={element} />
                ))}
              </Route>
            ) : (
              <Route
                exact
                path={path}
                key={path}
                element={
                  noContainer ? (
                    element
                  ) : (
                    <PageContainer>{element}</PageContainer>
                  )
                }
              />
            ),
          )}
          {isAuth &&
            authRoutes.map(({ path, element, noContainer, outlet }) =>
              outlet ? (
                <Route
                  exact
                  path={path}
                  key={path}
                  element={
                    noContainer ? (
                      element
                    ) : (
                      <PageContainer>{element}</PageContainer>
                    )
                  }
                >
                  {outlet.map(({ path, element }) => (
                    <Route exact path={path} key={path} element={element} />
                  ))}
                </Route>
              ) : (
                <Route
                  exact
                  path={path}
                  key={path}
                  element={
                    noContainer ? (
                      element
                    ) : (
                      <PageContainer>{element}</PageContainer>
                    )
                  }
                />
              ),
            )}
          {user?.role === "ROLE_ADMIN" && (
            <Route
              exact
              path={adminRoute.path}
              key={adminRoute.path}
              element={adminRoute.element}
            >
              {adminRoute.outlet.map(({ path, element }) => (
                <Route exact path={path} key={path} element={element} />
              ))}
            </Route>
          )}
          {user?.role === "ROLE_SUPPORT" && (
            <Route
              exact
              path={supportRoute.path}
              key={supportRoute.path}
              element={supportRoute.element}
            />
          )}
          {!isAuth &&
            noAuthRoutes.map(({ path, element }) => (
              <Route exact path={path} key={path} element={element} />
            ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </WagmiConfig>
  );
};
