import Lottie from "lottie-react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import loader from "../../../assets/lottie/l_g.json";
import { Medium, Small } from "../../../common/elements/Text";
import { SpanBlue } from "../../claim/ClaimStyle";
// import { useIsAdaptive } from "../../../hooks/useIsAdaptive";
// import pillzDelivered from "../../../assets/images/profile/test.png";
// import { Medium, Small } from "../../../common/elements/Text";
// import { Text } from "./allStyles";
// import { useIsAdaptive } from "../../../hooks/useIsAdaptive";

const Container = styled.div`
  margin-top: 1.354vw;
  display: flex;
  flex-direction: column;
  gap: 0.781vw;

  @media (max-width: 768px) {
    margin-top: 0;
    gap: 2.538vw;
  }
`;

// const DeviceBlock = styled.div`
//   padding: 1.354vw 2.031vw 1.354vw 1.771vw;
//   border-radius: 1.042vw;
//   background-color: ${(props) => props.bc};
//   width: 36.667vw;

//   @media (max-width: 768px) {
//     width: 100%;
//     padding: 6.345vw;
//     border-radius: 3.553vw;
//   }
// `;

// const FirstLine = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding-bottom: 0.3vw;

//   @media (max-width: 768px) {
//   }
// `;

// const DisconnectDevice = styled(Medium)`
//   font-weight: 450;
//   font-size: 1.302vw;
//   line-height: 1.563vw;
//   cursor: pointer;

//   @media (max-width: 768px) {
//     font-family: "medium";
//     font-weight: 500;
//     line-height: 4.619vw;
//     font-size: 3.299vw;
//   }
// `;

// const TitleAndImage = styled.div`
//   display: flex;
//   gap: 0.781vw;

//   @media (max-width: 768px) {
//     gap: 1.777vw;
//   }
// `;

// const Title = styled(Small)`
//   font-weight: 500;
//   font-size: 1.302vw;
//   line-height: 1.563vw;

//   @media (max-width: 768px) {
//     font-weight: 500;
//     font-size: 4.569vw;
//     line-height: 5.482vw;
//   }
// `;

// const Icon = styled.img`
//   width: ${(props) => props.w};
//   height: ${(props) => props.h};
// `;

// // const LastLogged = styled.div`
// //   margin-top: 1.042vw;
// // `;

// const ActiveOnDevice = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const DetailText = styled(Medium)`
//   font-size: 0.833vw;
//   cursor: pointer;
// `;

// const ButtonsFlex = styled.div`
//   display: flex;
//   gap: 2.03vw;
//   align-items: center;
//   margin-top: 4.061vw;
// `;

// const Button = styled.button`
//   @media (max-width: 768px) {
//     background: ${(props) => props.bg || "#F2530F"};
//     margin-bottom: 0;
//     width: 38.071vw;
//     height: 11.168vw;
//     border-radius: 2.03vw;
//   }
// `;

// export const LinkedDevices = () => {
//   const adpt = useIsAdaptive();
//   return (
//     <Container>
//       <Medium c={adpt !== "mob" ? "#000" : "rgba(0,0,0,0.35)"}>
//         You don&apos;t have any linked devices yet.
//       </Medium>
//       {/* <LinkDevice bc="rgba(0, 0, 0, 0.05)" />
//     <LinkDevice bc="rgba(42, 228, 72, 0.1)" /> */}
//     </Container>
//   );
// };

export const Cards = styled.div`
  width: 30.729vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  gap: 0.521vw;
  @media (max-width: 768px) {
    width: 100%;
    gap: 0.3vw;
  }
`;

export const Card = styled.div`
  width: 15.104vw;
  height: 24.323vw;
  border-radius: 1.042vw;
  box-sizing: border-box;
  border: 0.052vw solid rgba(255, 255, 255, 0.3);
  :hover {
    border: 0.052vw solid rgba(255, 255, 255, 0.3);
  }
  &.active {
    border: 0.052vw solid rgba(255, 255, 255, 1);
  }
  background-color: ${(props) => (props.white ? "#fff" : "#000")};
  @media (max-width: 768px) {
    width: 100%;
    height: 137.817vw;
    border-radius: 2.564vw;
  }
`;

export const CardImage = styled.video`
  width: 100%;
  height: 15.104vw;
  border-radius: 1.042vw;
  @media (max-width: 768px) {
    width: 100%;
    height: 90.863vw;
    border-radius: 5.076vw;
  }
`;

export const FlexName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.042vw;
  padding: 0 0.938vw 0.938vw 0.938vw;
  height: 9.219vw;
  @media (max-width: 768px) {
    justify-content: flex-start;
    padding: 0 6.599vw 6.599vw 6.599vw;
    gap: 6.599vw;
    height: 46.954vw;
  }
`;

export const ShineButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0.521vw 1.042vw;
  border-radius: 0.521vw;
  background-color: ${(props) => !props.game && "#F2530F"};
  transition: all 300ms ease-out;
  width: 30.729vw;
  &:hover {
    transition: all 300ms ease-out;
    background-color: ${(props) => !props.game && "#FF6F32"};
  }
  @media (max-width: 1280px) {
    padding: 0.521vw 1.042vw;
    border-radius: 0.781vw;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BuyText = styled(Small)`
  color: #fff;
  white-space: nowrap;
`;

export const ButtonPlay = styled.button`
  width: 13.229vw;
  height: 2.865vw;
  border-radius: 0.521vw;
  border: 0.052vw solid rgba(255, 255, 255, 0.4);
  background-color: #000;
  :hover {
    background-color: #fff;
  }
  :hover p {
    color: #000;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 11.168vw;
    border-radius: 2.03vw;
  }
`;

export const TextPlay = styled(Small)``;

export const TextMedium = styled(Medium)`
  margin-top: 1.354vw;
  font-weight: 450;
  &.cred {
    margin-bottom: 2vw;
    border: 0.052vw solid #000;
    padding: 1vw;
    display: inline-block;
    border-radius: 0.731vw;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    &.cred {
      margin-top: 4.354vw;
      margin-bottom: 6vw;
      border: 0.256vw solid #000;
      padding: 3.3vw;
      display: inline-block;
      border-radius: 2vw;
    }
  }
`;

export const StyledSpan = styled.span`
  cursor: pointer;
  color: #f2530f;
  transition: all 300ms ease-in;
  :hover {
    transition: all 300ms ease-out;
    color: #ff6f32;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* transform: translate(0%, -50%);
  left: 3.385vw; */
  @media (max-width: 768px) {
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const LottieContainer = styled.div`
  width: 6vw;
  height: 2vw;
  @media (max-width: 768px) {
    width: 15vw;
    height: 6vw;
  }
`;

export const TextSmall = styled(Small)`
  font-size: 0.625vw;
  line-height: 0.875vw;
  @media (max-width: 768px) {
    font-size: 3.299vw;
    line-height: 4.619vw;
  }
`;

export const TextFuckMedium = styled(Medium)`
  @media (max-width: 768px) {
    font-size: 7.462vw;
    line-height: 8.954vw;
  }
`;

export const YourCellzCharacters = () => {
  const cellz = useSelector((state) => state.profile.cellz);
  const isLoadingCellz = useSelector((state) => state.profile.isLoadingCellz);
  // const adpt = useIsAdaptive();
  const nav = useNavigate();

  if (isLoadingCellz) {
    return (
      <Container>
        <LottieContainer>
          <Lottie animationData={loader} loop />
        </LottieContainer>
      </Container>
    );
  }

  if (cellz.length === 0) {
    return (
      <TextMedium c="#000">
        If you just purchased Pillz - your Cellz pets are on their way to you.{" "}
        <br />
        In case you don&apos;t see them within 4h after payment please open a{" "}
        <br />
        ticket in <SpanBlue href="https://discord.gg/pillz">
          Discord
        </SpanBlue>{" "}
        or contact us at{" "}
        <SpanBlue href="mailto:support@pillz.tech">support@pillz.tech</SpanBlue>
        {/* You
        don&apos;t have a single character.{" "} */}
        {/* <StyledSpan onClick={() => nav("/redeem")}>Redeem</StyledSpan> you Pillz
        <br />
        NFT if you have one. If you didn&apos;t buy one, check
        <br />
        out our <StyledSpan onClick={() => nav("/catalog")}>catalog</StyledSpan>
        . */}
      </TextMedium>
    );
  }

  return (
    <Container>
      <Cards>
        {cellz.length > 0 &&
          cellz.map((el) => (
            <Card key={el.name}>
              <CardImage
                src={
                  el.image.includes("ipfs://")
                    ? `https://pillz-tech.infura-ipfs.io/ipfs/${el.image.replace(
                        "ipfs://",
                        "",
                      )}`
                    : el.image
                }
                // src={el.image}
                muted
                autoPlay
                loop
                playsInline
              />
              <FlexName>
                <TextFuckMedium c="#fff">{el.name}</TextFuckMedium>
                <ButtonPlay
                  onClick={() =>
                    nav("/cellzGame", {
                      state: {
                        cellzName: el.name,
                      },
                    })
                  }
                >
                  <TextPlay c="rgba(255, 255, 255, 0.4)">PLAY</TextPlay>
                </ButtonPlay>
                {/* <TextSmall c="rgba(255, 255, 255, 0.4)">
                  Game release: June 2023
                </TextSmall> */}
              </FlexName>
            </Card>
          ))}
      </Cards>
      {/* <ShineButton onClick={() => console.log("1")}>
        <BuyText>Transfer NFT to my wallet</BuyText>
      </ShineButton> */}
      {/* <LinkDevice bc="rgba(0, 0, 0, 0.05)" />
    <LinkDevice bc="rgba(42, 228, 72, 0.1)" /> */}
    </Container>
  );
};

// const LinkDevice = ({ bc }) => {
//   const adpt = useIsAdaptive();
//   return (
//     <DeviceBlock bc={bc}>
//       <FirstLine>
//         <TitleAndImage>
//           <Icon
//             w={adpt !== "mob" ? "1.563vw" : "5.584vw"}
//             h={adpt !== "mob" ? "1.563vw" : "5.584vw"}
//             src={pillzDelivered}
//           />
//           <Title>iPhone Kasqa</Title>
//         </TitleAndImage>
//         {adpt !== "mob" && (
//           <DisconnectDevice c="#FF434F">Disconnect device</DisconnectDevice>
//         )}
//       </FirstLine>
//       <Text c="rgba(0, 0, 0, 0.35)">
//         First activation: 23 November 2023, 09:02
//       </Text>
//       <ActiveOnDevice>
//         <Text c="#000000">Active on iPhone Kasqa</Text>
//         {adpt !== "mob" && <DetailText c="#F2530F">Details</DetailText>}
//       </ActiveOnDevice>
//       {/* <LastLogged>
//       <Text c="rgba(0, 0, 0, 0.35)">Last logged in: 1 March 2023, 11:44</Text>
//     </LastLogged> */}
//       {adpt === "mob" && (
//         <ButtonsFlex>
//           <Button bg="rgba(255, 255, 255, 0.6)">
//             <DisconnectDevice c="#FF434F">Disconnect device</DisconnectDevice>
//           </Button>
//           <Button bg="rgba(0, 0, 0, 0.04)">
//             <DisconnectDevice c="#000000">Details</DisconnectDevice>
//           </Button>
//         </ButtonsFlex>
//       )}
//     </DeviceBlock>
//   );
// };
