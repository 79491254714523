import styled from "styled-components";
import { Small } from "../../common/elements/Text";

// LeftPanelRooms

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.042vw;
  width: 30%;
  overflow: auto;
  max-height: calc(100vh - 17vw);
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const DeviceBlock = styled.div`
  padding: 1vw;
  display: flex;
  flex-direction: column;
  gap: 0.3vw;

  border: 0.052vw solid rgba(0, 0, 0, 0.15);
  border-radius: 1.042vw;
  background: #f2f2f2;
  width: 100%;
  cursor: pointer;

  &.active {
    border: 0.052vw solid #000;
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

export const ImageDeviceRoom = styled.img`
  width: 4.688vw;
  height: 5.975vw;
  margin-left: 1.563vw;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.25vw 0 0.208vw 0;
`;

export const Name = styled(Small)`
  font-weight: 450;
  font-size: 1.302vw;
  padding-bottom: 0.3vw;
`;

export const Status = styled(Name)``;

export const NameAndStatus = styled.div`
  display: flex;
  gap: 1.042vw;
`;

export const TrackAndDateBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.208vw;
`;

export const TextTrackAndDate = styled(Small)`
  font-weight: 450;
  line-height: 1vw;
`;

export const Span = styled.span`
  font-family: "medium";
  font-size: 0.833vw;
  font-weight: 450;
  line-height: 1vw;
  letter-spacing: 0.02em;
  color: ${(props) => props.c || "#000"};
  @media (max-width: 1280px) {
    font-size: 0.938vw;
    line-height: 1.313vw;
  }
  @media (max-width: 768px) {
    font-size: 3.077vw;
    line-height: 4.308vw;
  }
`;

// RightPanelChat

export const RightBlock = styled.div`
  height: calc(100vh - 17vw);
  width: 70%;
  position: relative;
`;

export const BottomPanel = styled.div`
  position: fixed;
  bottom: 2.083vw;
  right: 1.7vw;
  width: 49.3%;
  display: flex;
  flex-direction: column;
  gap: 0.781vw;
`;

export const Input = styled.input`
  flex: 1;
  height: 4.271vw;
  padding: 1.563vw 2.188vw;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.7);
  border: 0.052vw solid rgba(0, 0, 0, 0.35);
  border-radius: 1.055vw;
`;

export const Button = styled.button`
  width: 4.271vw;
  height: 4.271vw;
  border-radius: 50%;
  border: 1px solid #f2530f;
  margin-right: 0.313vw;
  transition: 1s;
  :disabled {
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.15);
    transform: rotate(90deg);
    transition: 1s;
  }
`;

export const ImageArrow = styled.img`
  width: 1.403vw;
  height: 1.634vw;
  transition: 1s;
  &.noactive {
    transition: 1s;
    filter: contrast(0);
    opacity: 0.4;
  }
`;

export const BlockInputAndButton = styled.div`
  display: flex;
  align-items: center;
  gap: 1.302vw;
`;

export const HintsBlock = styled.div`
  width: 100%;
  display: flex;
  gap: 0.781vw;
`;

export const Hint = styled.div`
  padding: 0.365vw 0.885vw;
  border: 0.052vw solid rgba(0, 0, 0, 0.15);
  border-radius: 0.625vw;
  cursor: pointer;
`;

export const Text = styled(Small)`
  font-weight: 450;
`;

export const Chat = styled.div`
  height: 100%;
  padding-bottom: 2.104vw;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 66%;
  margin-bottom: 1.042vw;
  &.sent {
    align-self: flex-end;
    padding: 1.042vw 1.406vw;
    background-color: rgba(0, 117, 255, 0.1);
    border-radius: 1.042vw;
  }
  &.received {
    align-self: flex-start;
    padding: 1.042vw 1.406vw;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 1.042vw;
  }
  &:nth-child(1) {
    margin-bottom: 0;
  }
  &:last-child {
    margin-top: 0.938vw;
  }
`;

export const Block = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
`;

export const SupportPillz = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.297vw;
  margin-bottom: 0.521vw;
`;

export const ImageSupportPillz = styled.img`
  width: 0.849vw;
  height: 0.849vw;
`;

export const ContentMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.469vw;
`;
