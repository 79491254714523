import styled from "styled-components";
import { Medium } from "../../../common/elements/Text";

export const AbsoluteContainer = styled.div`
  position: relative;
  height: 200vh;
  z-index: 1;
  @media (max-width: 768px) {
    height: 178.934vw;
    background-color: #000;
  }
`;

export const BackgroundContainer = styled.div`
  height: ${(props) => `${props.h}px`};
  padding: 1.198vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
  will-change: transform;
  backface-visibility: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 3.846vw;
    /* position: initial;
    height: 100%; */
    position: relative;
    height: 100%;
  }
`;

export const PaletteContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    &:nth-child(1) {
      align-items: flex-start;
    }
    &:nth-child(2) {
      align-items: flex-end;
    }
  }
`;

export const Plus = styled.img`
  height: 1.302vw;
  width: 1.302vw;
  @media (max-width: 768px) {
    height: 3.846vw;
    width: 3.846vw;
  }
`;

export const Palette = styled.img`
  width: 1.042vw;
  height: 11.51vw;
  @media (max-width: 768px) {
    height: 26.035vw;
    width: 2.366vw;
  }
`;

export const AbsoluteVideoContainer = styled.div`
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  will-change: transform;
  @media (max-width: 768px) {
    width: 100%;
    border-radius: 2.564vw;
    top: 12.69vw;
  }
`;

export const VideoContainer = styled.div`
  width: 77.604vw;
  height: 43.701vw;

  &.game {
    width: 43.701vw;
  }

  &.gameDrop {
    width: 21.094vw;
    height: 21.094vw;
  }

  &.present {
    height: 37.5vw;
    width: 66.667vw;
  }

  @media (max-width: 768px) {
    width: 81.218vw;
    height: 45.178vw;

    &.game {
      width: 68vw;
      height: 68vw;
    }
    &.gameDrop {
      height: 72.308vw;
      width: 72.308vw;
    }
    &.present {
      height: 56.667vw;
      width: 100%;
    }
  }
`;
export const PlayPause = styled.div`
  width: 77.604vw;
  height: 43.701vw;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  top: 0;
  left: 0;

  &.game {
    width: 43.701vw;
    height: 43.701vw;
  }

  &.gameDrop {
    width: 21.094vw;
    height: 21.094vw;
  }

  &.present {
    height: 37.5vw;
    width: 66.667vw;
  }

  @media (max-width: 768px) {
    width: 81.218vw;
    height: 45.178vw;
    &.game {
      width: 68vw;
      height: 68vw;
    }
    &.gameDrop {
      height: 72.308vw;
      width: 72.308vw;
    }
    &.present {
      height: 56.667vw;
      width: 100%;
    }
  }
`;

export const RelativeContainer = styled.div`
  position: relative;
  width: 77.604vw;
  height: 43.701vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 1.042vw;
  overflow: hidden;
  &.game {
    width: 43.701vw;
  }

  &.gameDrop {
    height: 21.094vw;
    width: 21.094vw;
  }

  &.present {
    height: 37.5vw;
    width: 66.667vw;
  }

  @media (max-width: 768px) {
    border-radius: 2.564vw;
    width: 81.218vw;
    height: 45.178vw;
    &.game {
      width: 68vw;
      height: 68vw;
    }
    &.gameDrop {
      height: 72.308vw;
      width: 72.308vw;
    }
    &.present {
      border-radius: 0;
      height: 56.667vw;
      width: 100%;
    }
  }
`;

export const FirstBlock = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #f2f2f2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    background-color: #000;
    height: 100%;
  }
`;

export const SecondBlock = styled.div`
  position: absolute;
  top: 100vh;
  width: 100%;
  background-color: #000000;

  height: 100vh;
  display: flex;
  gap: 0.521vw;
  align-items: center;
  justify-content: center;
`;

export const VideoAd = styled.video`
  width: 77.604vw;
  /* height: 43.701vw; */
  border-radius: 1.302vw;
  @media (max-width: 768px) {
    width: 81.218vw;
    /* height: 51.282vw;  */
    border-radius: 2.564vw;
  }
`;

export const VideoTime = styled.div`
  position: absolute;
  top: 2vw;
  left: 2vw;
  @media (max-width: 768px) {
    top: 2vw;
    left: 3vw;
  }
`;

export const Volume = styled.div`
  position: absolute;
  top: 2vw;
  right: 2vw;
  cursor: pointer;
  z-index: 1;

  &.gameDrop {
    top: 1vw;
    right: 1vw;
  }

  @media (max-width: 768px) {
    top: 3vw;
    right: 3vw;
    &.gameDrop {
      top: 3vw;
      right: 3vw;
    }
  }
`;

export const StyledMedium = styled(Medium)`
  @media (max-width: 768px) {
    font-size: 3.077vw;
    line-height: 4.308vw;
  }
`;

export const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 1.156vw;
  @media (max-width: 768px) {
    gap: 2.564vw;
  }
`;

export const PlayArrow = styled.img`
  width: 2.9vw;
  height: 3.4vw;
  margin-top: 0.1vw;
  @media (max-width: 768px) {
    height: 11.026vw;
    width: 9.8vw;
  }
`;
