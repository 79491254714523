import styled from "styled-components";
import { Medium } from "../../common/elements/Text";

export const ContainerLoginPage = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 3.385vw;
  @media (max-width: 768px) {
    padding: 0 4.061vw;
  }
`;

export const TextLogin = styled(Medium)`
  font-size: 4.688vw;
  line-height: 4.453vw;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 10.152vw;
    line-height: 9.645vw;
    gap: 0.5vw;
  }
`;

export const Label = styled.label`
  margin: 0.781vw 0.521vw 0 1.302vw;
  display: flex;
  align-items: center;
`;

export const RadioBox = styled.div`
  width: 2.708vw;
  height: 2.708vw;
  background: #f2f2f2;
  border: 0.052vw solid rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.15s, border-color 0.15s;
  padding: 0.104vw;

  &::after {
    content: "";
    width: 2.188vw;
    height: 2.188vw;
    display: block;
    background: #2266dc;
    border-radius: 50%;
    cursor: pointer;
    transform: scale(0);
    background: #000000;
  }
`;

export const Input = styled.input`
  display: none;
  &:checked + ${RadioBox} {
    &::after {
      transform: scale(1);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    align-items: center;
    width: 100%;
  }
`;

export const BlockInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1.1vw;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 7.614vw;
    align-items: center;
  }
`;

export const ChangeBlockAuth = styled.div`
  margin-bottom: 0.521vw;
  display: flex;
  gap: 0.417vw;

  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    gap: 2.538vw;
  }
`;

export const ImageWalletConnect = styled.img`
  width: 6.867vw;
  height: 0.803vw;

  @media (max-width: 768px) {
    width: 27.297vw;
    height: 3.19vw;
  }
`;

export const ImageMetaMask = styled.img`
  width: 5.24vw;
  height: 0.96vw;

  @media (max-width: 768px) {
    width: 20.83vw;
    height: 3.815vw;
  }
`;

export const ImageCoinbase = styled.img`
  width: 4.4vw;
  height: 0.78vw;

  @media (max-width: 768px) {
    width: 18.528vw;
    height: 3.31vw;
  }
`;

export const ImageLedger = styled.img`
  width: 3.5vw;
  height: 1.1vw;

  @media (max-width: 768px) {
    width: 15.99vw;
    height: 5.343vw;
  }
`;

export const StageCodeBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 1.563vw;
  padding-bottom: 0.4vw;

  @media (max-width: 768px) {
    gap: 2.538vw;
    flex-direction: column;
  }
`;

export const LargePoint = styled.div`
  display: flex;
  align-items: ${(props) => props.ai || "center"};
  cursor: pointer;
  margin: 0 0.521vw 0 1.302vw;
  gap: 0.4vw;

  @media (max-width: 768px) {
    margin: 0 0.521vw 0 1.302vw;
    gap: 1vw;
  }
`;
