/* eslint-disable no-restricted-globals */
import styled from "styled-components";
import { BlueContainer, FieldContainer } from "../../elements/Blocks";
import { BlueTyping, Input } from "../../elements/Inputs";
import { FieldLabel } from "../../elements/Labels";
import { InsideRadio, Radio } from "./InputStyle";

const Error = styled.div`
  border: 0.052vw solid #ff434f;
  position: absolute;
  z-index: 1;
  background-color: ${(props) => (props.bc === "#fff" ? "#000" : "#f2f2f2")};
  top: 3.3vw;
  border-radius: 0.417vw;
  padding: 0.729vw;
  color: #ff434f;
  font-family: "medium";
  font-size: 0.833vw;
  line-height: 1.167vw;
  font-weight: 530;
  letter-spacing: 0.02em;
  color: ${(props) => props.c || "#000"};
  text-align: left;
  :before {
    content: " ";
    position: absolute;
    background-color: ${(props) => (props.bc === "#fff" ? "#000" : "#f2f2f2")};
    width: 0.7vw;
    height: 0.7vw;
    top: -0.38vw;
    left: 20%;
    transform: rotate(45deg);
    border-top: 0.052vw solid #ff434f;
    border-left: 0.052vw solid #ff434f;
    border-top-left-radius: 0.2vw;
  }
  @media (max-width: 1280px) {
    font-size: 0.938vw;
    line-height: 1.313vw;
    padding: 0.703vw;
    border-radius: 0.512vw;
  }
  @media (max-width: 768px) {
    border: 0.256vw solid #ff434f;
    border-radius: 2.051vw;
    padding: 3.846vw;
    top: 13.4vw;
    font-size: 3.077vw;
    line-height: 4.308vw;
    :before {
      width: 3vw;
      height: 3vw;
      top: -1.65vw;
      border-top: 0.256vw solid #ff434f;
      border-left: 0.256vw solid #ff434f;
      border-top-left-radius: 0.8vw;
    }
  }
`;

const StyledSpan = styled.span`
  color: #ff434f;
  font-size: 1vw;
  @media (max-width: 768px) {
    font-size: 3.8vw;
  }
`;

export const TextInput = ({
  type = "text",
  label,
  w,
  c,
  bc,
  fc,
  brc,
  p,
  name,
  value,
  errorMessage = null,
  onChange,
  validate,
  nrb,
  disabled,
  onBlur,
  autoFocus,
  onKeyPress,
}) => (
  <FieldContainer w={w}>
    <Input
      disabled={disabled}
      p={p}
      w={w}
      c={c}
      bc={bc}
      brc={brc}
      type={type}
      value={value}
      onChange={onChange}
      name={name}
      nrb={nrb}
      autoFocus={autoFocus}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      {...validate}
    />
    <FieldLabel
      fc={fc}
      className={
        (value || type === "date" || type === "datetime-local") && "filled"
      }
      htmlFor={name}
    >
      {label}
      {{ ...validate }.required && <StyledSpan>*</StyledSpan>}
    </FieldLabel>
    {errorMessage && (
      <Error c="red" bc={c}>
        {errorMessage}
      </Error>
    )}
  </FieldContainer>
);

export const RadioInput = ({ active, background, borderColor, mt }) => (
  <Radio mt={mt} borderColor={borderColor}>
    {active ? <InsideRadio bc={background} /> : null}
  </Radio>
);

export const BlueInput = ({ value, setValue }) => {
  function handleChange(e) {
    setValue(e.target.value);
  }
  return (
    <BlueContainer>
      <BlueTyping placeholder="Email" value={value} onChange={handleChange} />
    </BlueContainer>
  );
};
