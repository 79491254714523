import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import coinbase from "../../assets/images/wallet/coinbase.png";
import ledger from "../../assets/images/wallet/ledger.png";
import metamask from "../../assets/images/wallet/metamask.png";
import walletConnect from "../../assets/images/wallet/wallet_connect.png";
import { ButtonLogin } from "../../common/components/button/Button";
import { RadioInput, TextInput } from "../../common/components/input/Input";
import { Small } from "../../common/elements/Text";
import {
  loginEmail,
  otpThunk,
  setNoValid,
} from "../../features/auth/authSlice";
import { useWallet } from "../../hooks/use-wallet";
import { useForm } from "../../hooks/useForm";
import { useIsAdaptive } from "../../hooks/useIsAdaptive";
import {
  BlockInput,
  ChangeBlockAuth,
  ContainerLoginPage,
  Content,
  ImageCoinbase,
  ImageLedger,
  ImageMetaMask,
  ImageWalletConnect,
  LargePoint,
  // Input,
  // Label,
  // RadioBox,
  StageCodeBlock,
  TextLogin,
} from "./LoginStyle";
import { OTPCode } from "./code/OTPCode";

export const Login = () => {
  const [value, setValue] = useState(1);
  const [codeStage, setCodeStage] = useState(false);
  const [pinReady, setPinReady] = useState(false);
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(30);
  const {
    address,
    isConnected,
    connect,
    connectors,
    goSignMessage,
    disconnect,
  } = useWallet();
  const { values, onChange, isValid, errors } = useForm();
  const adpt = useIsAdaptive();
  const noValid = useSelector((state) => state.auth.noValid);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pinReady && values?.email) {
      dispatch(loginEmail({ otp: code, email: values?.email }));
    } else {
      dispatch(setNoValid());
    }
  }, [pinReady]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const sendOTP = () => {
    setCode("");
    setCodeStage(true);
    setSeconds(30);
    if (values?.email) {
      dispatch(otpThunk({ email: values?.email }));
    }
  };

  return (
    <ContainerLoginPage>
      <Content>
        <TextLogin>
          Log in using
          {adpt !== "mob" && (
            <>
              <LargePoint onClick={() => setValue(1)}>
                <RadioInput
                  mt={adpt !== "mob" ? "0.7vw" : "1.7vw"}
                  active={value === 1}
                />
                <TextLogin>wallet</TextLogin>
              </LargePoint>
            </>
          )}
        </TextLogin>
        <BlockInput>
          <TextLogin>
            {adpt === "mob" && (
              <>
                <LargePoint onClick={() => setValue(1)}>
                  <RadioInput
                    mt={adpt !== "mob" ? "0.7vw" : "1.7vw"}
                    active={value === 1}
                  />
                  <TextLogin>wallet</TextLogin>
                </LargePoint>
              </>
            )}
            or
            <LargePoint onClick={() => setValue(2)}>
              <RadioInput
                mt={adpt !== "mob" ? "0.7vw" : "1.7vw"}
                active={value === 2}
              />
              <TextLogin>email</TextLogin>
            </LargePoint>
          </TextLogin>
          {value === 1 ? (
            <>
              {address && isConnected ? (
                <ChangeBlockAuth>
                  <ButtonLogin
                    wM={adpt === "mob" && "70.5vw"}
                    action={() => goSignMessage()}
                    title="Sign message"
                  />
                  <ButtonLogin
                    wM="18vw"
                    w="5.313vw"
                    bg="rgba(255, 67, 79, 0.1)"
                    c="rgba(255, 67, 79, 1)"
                    action={() => disconnect()}
                    type="button"
                  >
                    <Small c="#FF434F">Decline</Small>
                  </ButtonLogin>
                </ChangeBlockAuth>
              ) : (
                <ChangeBlockAuth>
                  <ButtonLogin
                    wM={
                      // eslint-disable-next-line no-nested-ternary
                      adpt === "mob" && !window?.ethereum?.isMetaMask
                        ? "91.37vw"
                        : adpt === "mob"
                        ? "44.416vw"
                        : false
                    }
                    bg="#0075FF"
                    action={() => connect({ connector: connectors[0] })}
                  >
                    <ImageWalletConnect src={walletConnect} />
                  </ButtonLogin>
                  {window?.ethereum && window?.ethereum?.isMetaMask && (
                    <ButtonLogin
                      bg="#000000"
                      wM={adpt === "mob" && "44.416vw"}
                      action={() => connect({ connector: connectors[1] })}
                    >
                      <ImageMetaMask src={metamask} />
                    </ButtonLogin>
                  )}
                  <ButtonLogin
                    bg="#0052FF"
                    wM={adpt === "mob" && "44.416vw"}
                    action={() => connect({ connector: connectors[2] })}
                  >
                    <ImageCoinbase src={coinbase} />
                  </ButtonLogin>
                  <ButtonLogin
                    bg="#FF5300"
                    wM={adpt === "mob" && "44.416vw"}
                    action={() => connect({ connector: connectors[3] })}
                  >
                    <ImageLedger src={ledger} />
                  </ButtonLogin>
                </ChangeBlockAuth>
              )}
            </>
          ) : (
            <>
              {!codeStage ? (
                <ChangeBlockAuth>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      sendOTP(event);
                    }}
                  >
                    <TextInput
                      name="email"
                      type="email"
                      value={values?.email || ""}
                      onChange={onChange}
                      errorMessage={errors?.email}
                      validate={{
                        required: true,
                      }}
                      w={adpt !== "mob" ? "16.146vw" : "90.863vw"}
                      label="Email"
                      brc="rgba(0, 0, 0, 0.1)"
                    />
                  </form>
                  <ButtonLogin
                    disabled={!isValid}
                    title="Send code"
                    wM={adpt === "mob" && "90.863vw"}
                    action={() => sendOTP()}
                  />
                </ChangeBlockAuth>
              ) : (
                <StageCodeBlock>
                  <Small c="rgba(0, 0, 0, 0.35)">
                    The authorization code has been sent
                    <br />
                    to{" "}
                    {values?.email.length >= 20
                      ? `${values?.email.slice(0, 3)}...${values?.email.slice(
                          -13,
                        )}`
                      : values?.email}
                    . Enter it into the form.
                  </Small>
                  <OTPCode
                    code={code}
                    noValid={noValid}
                    setCode={setCode}
                    pinReady={pinReady}
                    setPinReady={setPinReady}
                  />
                  {seconds !== 0 ? (
                    <Small c="rgba(0, 0, 0, 0.35)">
                      Send code again in{" "}
                      {seconds < 10 ? `0:0${seconds}` : `0:${seconds}`}
                    </Small>
                  ) : (
                    <ButtonLogin
                      wM={adpt === "mob" && "44.416vw"}
                      title="Resend"
                      action={() => sendOTP()}
                    >
                      {/* <Medium c="#FFF">Resend</Medium> */}
                    </ButtonLogin>
                  )}
                </StageCodeBlock>
              )}
            </>
          )}
        </BlockInput>
      </Content>
    </ContainerLoginPage>
  );
};
