import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import backgroundThird from "../../../assets/images/drop/bg_cellz_desktop.png";
import backgroundFourth from "../../../assets/images/drop/cellznftbg.png";
import { Large, Medium, Small } from "../../../common/elements/Text";

export const DropNewContainer = styled.div`
  position: relative;
`;

export const PayPanel = styled.div`
  position: fixed;
  width: 43.125vw;
  height: 4.948vw;
  background-color: #fff;
  border-radius: 0.781vw;
  bottom: 2.031vw;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0px 0px 0.2vw 0px rgba(0, 0, 0, 0.2);
  z-index: 4;
  padding: 0 1.458vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 0 2.821vw 0 5.641vw;
    width: 91.795vw;
    height: 14.615vw;
    border-radius: 3.846vw;
    bottom: 3.5vw;
    box-shadow: 0px 0px 0.417vw 0px rgba(0, 0, 0, 0.2);
  }
`;

export const MediumPay = styled(Medium)`
  font-size: 1.667vw;
  line-height: 1.583vw;
  @media (max-width: 768px) {
    font-size: 3.846vw;
    line-height: 4.231vw;
  }
`;

export const DropFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 7.031vw;
  @media (max-width: 1280px) {
    padding-top: 8.594vw;
  }
  @media (max-width: 768px) {
    padding-top: 21.626vw;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const DropTop = styled.div`
  display: flex;
  align-items: center;
  gap: 1.042vw;
  margin-bottom: 1.146vw;
  @media (max-width: 768px) {
    margin-bottom: 4.872vw;
    gap: 2.564vw;
  }
`;

export const TileContainer = styled.div`
  width: 61.979vw;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    order: 2;
  }
`;

export const FlexComponents = styled.div`
  width: 61.979vw;
  display: flex;
  flex-wrap: wrap;
  gap: 0.521vw;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    width: 100%;
    flex-direction: column;
    gap: 2.051vw;
  }
`;

export const DeviceInfo = styled.div`
  width: 29.167vw;
  @media (max-width: 768px) {
    width: 100%;
    order: 1;
  }
`;
export const ComponentCard = styled.div`
  width: 30.729vw;
  height: 30.729vw;
  border-radius: 1.042vw;
  background-color: #fff;
  display: flex;
  justify-content: center;
`;

export const ComponentCardImage = styled.img`
  height: 30.729vw;
  max-height: 100%;
  width: auto;
`;

export const PriceBlock = styled.div`
  margin-top: 1.146vw;
  border-top: 0.052vw solid #000;
  border-bottom: 0.052vw solid #000;
  padding-top: 1.146vw;
  @media (max-width: 768px) {
    margin-top: 3.846vw;
    padding-top: 3.846vw;
    border-top: 0.256vw solid #000;
    border-bottom: 0.256vw solid #000;
  }
`;

export const PriceBlockTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.146vw;
  border-bottom: 0.052vw solid #000;
  @media (max-width: 768px) {
    padding-bottom: 3.846vw;
    border-bottom: 0.256vw solid #000;
  }
`;

export const PriceBlockBottom = styled.div`
  padding-bottom: 1.146vw;
  padding-top: 1.146vw;
  display: flex;
  gap: 2.604vw;
  align-items: center;
  @media (max-width: 768px) {
    gap: 11.2vw;
    padding-top: 5.128vw;
    padding-bottom: 5.128vw;
  }
`;

export const BlackButton = styled.button`
  height: 4.427vw;
  border-radius: 0.521vw;
  background-color: #000;
  width: ${(props) => (props.mid ? "50%" : "100%")};
  :disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.15);
  }

  &.opac {
    transition: all 300ms ease-out;
    &:hover {
      transition: all 300ms ease-out;
      opacity: 0.8;
    }
  }

  &.orange {
    background-color: #f2530f;
    transition: all 300ms ease-out;
    &:hover {
      transition: all 300ms ease-out;
      background-color: #ff6f32;
    }
  }

  @media (max-width: 1280px) {
    height: 4.844vw;
    border-radius: 0.781vw;
  }
  @media (max-width: 768px) {
    height: 12.821vw;
    border-radius: 2.564vw;
  }
`;

export const FlexLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.781vw 0;
  border-bottom: 0.052vw solid #000;

  @media (max-width: 768px) {
    padding: 2.564vw 0;
    border-bottom: 0.256vw solid #000;
  }
`;

export const DeviceDescription = styled.div`
  padding-top: 0.781vw;
  @media (max-width: 768px) {
    padding-top: 2.564vw;
    margin-bottom: 5.128vw;
  }
`;

export const Suk = styled.div`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const LargeDrop = styled(Large)`
  @media (max-width: 768px) {
    font-size: 10.256vw;
    line-height: 9.744vw;
  }
`;

export const DropOne = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 6.576vw);
  position: relative;
  padding-bottom: 6.576vw;
  @media (max-width: 1280px) {
    height: calc(100vh - 8.045vw);
    padding-bottom: 8.045vw;
  }
  @media (max-width: 768px) {
    justify-content: flex-start;
    height: 175.385vw;
    padding-top: 5vw;
    padding-bottom: 18.805vw;
  }
`;

export const DropOneTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.198vw;
  @media (max-width: 768px) {
    gap: 3.077vw;
  }
`;

export const IncludesBlock = styled.div`
  margin-top: 4.5vw;
  height: 18.49vw;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    height: 84.564vw;
    margin-top: 10vw;
  }
`;

export const IncludesCards = styled.div`
  height: 11.7vw;
  display: flex;
  gap: 1.5vw;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: 78vw;
    justify-content: center;
    align-items: center;
    background-color: #000;
  }
`;

export const TitleFirst = styled(Large)`
  @media (max-width: 768px) {
    font-size: 10.256vw;
    line-height: 9.744vw;
  }
`;

export const ScrollBlock = styled.div`
  margin-top: 3.073vw;
  height: 3.542vw;
  @media (max-width: 768px) {
    margin-top: 5.128vw;
    height: 12.518vw;
  }
`;

export const Border = styled.div`
  height: 1.042vw;
  width: 68.5vw;
  border-left: 0.052vw solid rgba(255, 255, 255, 0.5);
  border-right: 0.052vw solid rgba(255, 255, 255, 0.5);
  &.top {
    border-top: 0.052vw solid rgba(255, 255, 255, 0.5);
  }
  &.bottom {
    border-bottom: 0.052vw solid rgba(255, 255, 255, 0.5);
  }
  @media (max-width: 768px) {
    width: 91.795vw;
    height: 3.846vw;
    border-left: 0.256vw solid rgba(255, 255, 255, 0.5);
    border-right: 0.256vw solid rgba(255, 255, 255, 0.5);
    &.top {
      border-top: 0.256vw solid rgba(255, 255, 255, 0.5);
    }
    &.bottom {
      border-bottom: 0.256vw solid rgba(255, 255, 255, 0.5);
    }
  }
`;

export const GreySpan = styled.span`
  color: rgba(255, 255, 255, 0.5);
`;

export const IncludeImage = styled.img`
  height: 8.594vw;
  width: 8.594vw;
  margin-top: -0.469vw;
  transition: all 300ms ease-in;
  :hover {
    transition: all 300ms ease-out;
    scale: 1.02;
  }
  @media (max-width: 768px) {
    width: 24.359vw;
    height: 24.359vw;
  }
`;

export const IncludeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.417vw;
`;

export const ArrowImage = styled.img`
  width: 0.967vw;
  height: 0.53vw;

  animation: MoveUpDown 1.6s linear infinite;
  @keyframes MoveUpDown {
    0%,
    100% {
      margin-top: 1vw;
    }
    50% {
      margin-top: 1.6vw;
    }
  }
  @media (max-width: 768px) {
    width: 3.726vw;
    height: 2vw;
    @keyframes MoveUpDown {
      0%,
      100% {
        margin-top: 2vw;
      }
      50% {
        margin-top: 3.5vw;
      }
    }
  }
`;

export const IncludesAbsolute = styled.div`
  position: absolute;
  width: 6.458vw;
  height: 1.563vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  top: -0.85vw;
  @media (max-width: 768px) {
    width: 19.487vw;
    height: 4.359vw;
    top: -2.2vw;
  }
`;

export const MediumD = styled(Medium)`
  @media (max-width: 768px) {
    font-size: 3.077vw;
    line-height: 3.385vw;
  }
`;

export const SmallD = styled(Small)`
  @media (max-width: 768px) {
    font-size: 2.564vw;
    line-height: 4.2vw;
  }
`;

export const DropSecondContainer = styled.div`
  height: 100vh;
  background-color: #f2f2f2;
  gap: 4.167vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  &.white {
    background-color: #f2f2f2;
    height: 97.3vw;
    padding: 0 3.385vw;
  }
  &.orange {
    background-color: #f2530f;
    gap: 2.031vw;
    height: 64.9vw;
  }
  &.black {
    background-color: #000;
    gap: 2.031vw;
    position: relative;
    height: 56.25vw;
  }
  &.transparent {
    background-color: transparent;
    height: 56.25vw;
  }
  @media (max-width: 768px) {
    height: 170vw;
    gap: 12.821vw;
    &.orange {
      gap: 7.692vw;
      height: 150.513vw;
    }
    &.white {
      height: 490.8vw;
      padding: 0 4.103vw;
    }
    &.black {
      height: 185.59vw;
      justify-content: flex-end;
      padding-bottom: 18.205vw;
    }
    &.transparent {
      height: 173.59vw;
    }
  }
`;

export const DropThirdContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${backgroundThird});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  gap: 2.24vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 200.41vw;
    gap: 12.821vw;
  }
`;

export const DropFourthContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${backgroundFourth});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f2f2f2;
  gap: 4.167vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media (max-width: 768px) {
    gap: 12.821vw;
    height: 174.051vw;
  }
`;

export const TextContainerStyleD = styled.div`
  margin-top: 1.563vw;
  margin-bottom: 1.302vw;
  @media (max-width: 768px) {
    margin-top: 5.128vw;
    text-align: center;
    margin-bottom: 3.846vw;
  }
`;

export const DropSixthContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 55.9vw;
  }
`;

export const DropSeventhContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5.208vw;
  @media (max-width: 768px) {
    height: 192vw;
    gap: 11.538vw;
  }
`;

export const SpanStatus = styled.span`
  color: ${(props) => props.c || "#000"};
`;

export const SmallContainer = styled.div`
  width: 31.25vw;
  margin-top: 1.302vw;

  @media (max-width: 768px) {
    width: 83.333vw;
  }
`;

export const StepD = styled.span`
  font-size: 3.6vw;
  line-height: 3.5vw;
  font-family: "regular";
  font-weight: 400;
  letter-spacing: -0.02em;
  color: ${(props) => props.c || "#000"};
  position: relative;
  bottom: 0.4vw;
  @media (max-width: 768px) {
    font-size: 9.8vw;
    line-height: 9.8vw;
    bottom: 0.2vw;
  }
`;

export const DeviceFirstImage = styled.img`
  height: 24.479vw;
  width: 24.479vw;
  border-radius: 0.781vw;
`;

export const DeviceFirstVideo = styled.video`
  height: 24.479vw;
  width: 24.479vw;
  border-radius: 0.781vw;
  &.cellz {
    box-shadow: 0px 2.604vw 6.25vw -2.188vw rgba(128, 0, 255, 0.7);
  }

  @media (max-width: 768px) {
    width: 72.308vw;
    height: 72.308vw;
    border-radius: 2.564vw;
  }
`;

export const DeviceMonkeyImage = styled.img`
  height: 23.854vw;
  width: 46.25vw;
  @media (max-width: 768px) {
    width: 81.538vw;
    height: 42.051vw;
  }
`;

export const SdkImage = styled.img`
  height: 35.573vw;
  width: 50.208vw;
  @media (max-width: 768px) {
    width: 93.077vw;
    height: 65.928vw;
    background-color: #f2530f;
  }
`;

export const TopContainer = styled.div`
  &.abs {
    position: absolute;
    z-index: 3;
  }
`;

export const BottomThird = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.448vw;
  @media (max-width: 768px) {
    gap: 10.256vw;
  }
`;

export const ButtonCheckout = styled.button`
  height: 2.552vw;
  border-radius: 0.521vw;

  transition: all 300ms ease-out;

  width: 7.76vw;
  background-color: #f2530f;

  :hover {
    transition: all 300ms ease-out;
    background-color: #ff6f32;
  }

  :disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.15);
  }
  @media (max-width: 768px) {
    width: 27.949vw;
    height: 9.487vw;
    border-radius: 2.051vw;
  }
`;

export const ButtonThird = styled(HashLink)`
  width: 13.958vw;
  height: 2.552vw;
  border-radius: 0.521vw;
  background-color: #000;
  transition: all 300ms ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  &.orange {
    width: 17.083vw;
    background-color: #f2530f;
    opacity: 1;
  }
  :hover {
    &.orange {
      transition: all 300ms ease-out;
      background-color: #ff6f32;
    }
  }
  @media (max-width: 1280px) {
    width: 15.958vw;
    &.orange {
      width: 19.083vw;
    }
  }
  @media (max-width: 768px) {
    width: 60.256vw;
    height: 11.282vw;
    border-radius: 2.051vw;
    &.orange {
      width: 72.821vw;
    }
  }
`;

export const PackageContainer = styled.div`
  width: 93.125vw;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 12.769vw;
    overflow-x: auto;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const BorderTop = styled.div`
  min-height: 1.042vw;
  width: 15.469vw;
  border-top: 0.052vw solid #000;
  border-right: 0.052vw solid #000;
  &.first {
    border-left: 0.052vw solid #000;
  }
  @media (max-width: 768px) {
    min-height: 5.128vw;
    width: 74.359vw;
    border-top: 0.256vw solid #000;
    border-right: 0.256vw solid #000;
    &.first {
      border-left: 0.256vw solid #000;
    }
  }
`;

export const PackageImage = styled.img`
  width: 15.469vw;
  height: 20.938vw;
  margin-top: -0.99vw;
  margin-bottom: 1.042vw;
  @media (max-width: 768px) {
    width: 74.359vw;
    height: 100.808vw;
  }
`;

export const BorderBottom = styled.div`
  min-height: 1.042vw;
  width: 15.469vw;
  border-bottom: 0.052vw solid #000;
  border-right: 0.052vw solid #000;
  &.first {
    border-left: 0.052vw solid #000;
  }
  @media (max-width: 768px) {
    min-height: 5.128vw;
    width: 74.359vw;
    border-bottom: 0.256vw solid #000;
    border-right: 0.256vw solid #000;
    &.first {
      border-left: 0.256vw solid #000;
    }
  }
`;

export const PackageCard = styled.div`
  width: 15.469vw;
  height: 23.021vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    height: 111.256vw;
    width: 74.359vw;
  }
`;

export const AbsoluteName = styled.div`
  position: absolute;
  bottom: 1.7vw;

  @media (max-width: 768px) {
    bottom: 6.2vw;
  }
`;

export const SmallP = styled(Small)`
  @media (max-width: 768px) {
    font-size: 3.351vw;
    line-height: 4.021vw;
  }
`;

export const LeftImage = styled.img`
  position: absolute;
  top: 0;
  left: 8.75vw;
  width: 18.54vw;
  height: 39.733vw;
  @media (max-width: 768px) {
    left: 8.462vw;
    width: 35.677vw;
    height: 76.459vw;
  }
`;

export const RightImage = styled.img`
  position: absolute;
  top: 8.125vw;
  right: 4.635vw;
  width: 21.875vw;
  height: 40vw;
  @media (max-width: 768px) {
    right: 9.923vw;
    top: 14.433vw;
    width: 43.41vw;
    height: 79.377vw;
  }
`;

export const IrlVideo = styled.video`
  width: 100%;
  height: 56.25vw;
  position: absolute;
  z-index: 1;
  @media (max-width: 768px) {
    height: 173.59vw;
    object-fit: cover;
  }
`;

export const IrlOpac = styled.div`
  width: 100%;
  height: 56.25vw;
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  z-index: 2;
  @media (max-width: 768px) {
    height: 173.59vw;
  }
`;

export const SmallB = styled(Small)`
  @media (max-width: 768px) {
    font-size: 3.846vw;
    line-height: 5.769vw;
  }
`;
