import styled from "styled-components";

export const Radio = styled.div`
  margin-top: ${(props) => props.mt};
  box-sizing: border-box;
  height: 2.708vw;
  width: 2.708vw;
  border: 0.052vw solid ${(props) => props.borderColor || "#000"};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 1280px) {
    width: 2.969vw;
    height: 2.969vw;
    border: 0.152vw solid ${(props) => props.borderColor || "#000"};
  }
  @media (max-width: 768px) {
    width: 5.897vw;
    height: 5.897vw;
    border: 0.252vw solid ${(props) => props.borderColor || "#000"};
  }
`;

export const InsideRadio = styled.div`
  background-color: ${(props) => props.bc || "#000"};
  height: 2.188vw;
  width: 2.188vw;
  border-radius: 50%;
  @media (max-width: 1280px) {
    width: 2.344vw;
    height: 2.344vw;
  }
  @media (max-width: 768px) {
    width: 4.615vw;
    height: 4.615vw;
  }
`;
