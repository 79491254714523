import styled from "styled-components";
import cycle from "../../assets/images/delivery/cycle.png";
import cycleW from "../../assets/images/delivery/cycle.webp";
import { Large, Medium, Small } from "../../common/elements/Text";

export const WrapperVideo = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  @media (max-width: 768px) {
    height: none;
  }
`;

export const BackVideo = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  z-index: 1;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Container = styled.div`
  position: relative;
  top: 6.576vw;
  z-index: 2;
  height: calc(100vh - 6.576vw);
  padding: 1.354vw 3.385vw 2.604vw 3.385vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;

  @media (max-width: 1280px) {
    height: calc(100vh - 8.045vw);
    top: 8.045vw;
  }
  @media (max-width: 768px) {
    padding: 0 4.061vw 18.528vw 4.061vw;
    width: 100%;
    height: calc(100vh - 18.805vw);
    gap: 5.838vw;
    top: 18.805vw;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;

  &.right {
    gap: 1.042vw;
  }
`;

export const Border = styled.div`
  width: 50%;
  height: 1.563vw;
  border-top: 0.052vw solid ${(props) => (props.claimed ? "#fff" : "#000")};
  &.full {
    border-left: 0.052vw solid ${(props) => (props.claimed ? "#fff" : "#000")};
    border-right: 0.052vw solid ${(props) => (props.claimed ? "#fff" : "#000")};
  }
  &.right {
    border-right: 0.052vw solid ${(props) => (props.claimed ? "#fff" : "#000")};
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 5.076vw;
    border-top: 0.256vw solid ${(props) => (props.claimed ? "#fff" : "#000")};

    &.full {
      border-left: 0.256vw solid ${(props) => (props.claimed ? "#fff" : "#000")};
      border-right: 0.256vw solid
        ${(props) => (props.claimed ? "#fff" : "#000")};
    }
    &.right {
      border-right: 0.256vw solid
        ${(props) => (props.claimed ? "#fff" : "#000")};
    }
  }
`;

export const BorderBottom = styled.div`
  width: 100%;
  height: 1.563vw;
  border-bottom: 0.052vw solid ${(props) => (props.claimed ? "#fff" : "#000")};
  &.full {
    border-left: 0.052vw solid ${(props) => (props.claimed ? "#fff" : "#000")};
    border-right: 0.052vw solid ${(props) => (props.claimed ? "#fff" : "#000")};
  }
  &.right {
    border-right: 0.052vw solid ${(props) => (props.claimed ? "#fff" : "#000")};
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 5.076vw;
    border-bottom: 0.256vw solid ${(props) => (props.claimed ? "#fff" : "#000")};

    &.full {
      border-left: 0.256vw solid ${(props) => (props.claimed ? "#fff" : "#000")};
      border-right: 0.256vw solid
        ${(props) => (props.claimed ? "#fff" : "#000")};
    }
    &.right {
      border-right: 0.256vw solid
        ${(props) => (props.claimed ? "#fff" : "#000")};
    }
  }
`;

export const CenterBlock = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10.152vw;
  }
`;

export const YouSuccessfully = styled.div`
  display: flex;
  gap: 0.781vw;
  align-items: center;
  @media (max-width: 768px) {
    gap: 1.015vw;
  }
`;

export const ImgReady = styled.img`
  width: 3.385vw;
  height: 3.385vw;
  filter: invert(${(props) => (props.claimed ? 1 : 0)});
  @media (max-width: 768px) {
    width: 7.36vw;
    height: 7.36vw;
  }
`;

export const TextLarge = styled(Large)`
  text-align: center;
  @media (max-width: 768px) {
    font-size: 10.152vw;
    line-height: 9.645vw;
  }
`;

export const TextSmallAdpt = styled(Small)`
  @media (max-width: 768px) {
    font-size: 3.046vw;
    line-height: 4.264vw;
  }
`;

export const LeftBlock = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const RightBlock = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextLeftBlock = styled.div`
  margin-top: 1.302vw;
  width: ${(props) => props.width};
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 3.807vw;
  }
`;

export const ButtonGoToProfile = styled.button`
  margin-top: 2.604vw;
  padding: 1.042vw 2.526vw;
  background-color: ${(props) => (props.claimed ? "#fff" : "#000")};
  border-radius: 0.521vw;
  @media (max-width: 768px) {
    margin-top: 3.553vw;
    border-radius: 1.269vw;
    padding: 2.411vw 4.315vw;
  }
`;

export const TextButton = styled(Medium)`
  font-weight: 450;
  font-size: 0.938vw;
  line-height: 1.125vw;
  color: ${(props) => (props.claimed ? "#000" : "#fff")};

  @media (max-width: 768px) {
    font-size: 3.046vw;
    line-height: 4.264vw;
  }
`;

export const CycleBlock = styled.div`
  width: 41.771vw;
  height: 41.771vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-image: url(${cycle});
  background-image: -webkit-image-set(url(${cycleW}) 1x);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 768px) {
    width: 90.863vw;
    height: 90.863vw;
  }
`;

export const TextSmall = styled(Small)`
  margin: ${(props) =>
    props.isAuth ? "1.302vw 0 2.604vw 0" : "1.302vw 0 0 0"};
  @media (max-width: 768px) {
    font-size: 3.046vw;
    line-height: 4.264vw;
    margin: ${(props) =>
      props.isAuth ? "2.538vw 0 6.345vw 0" : "2.538vw 0 0 0"};
  }
`;

export const ButtonCycle = styled.button`
  padding: 1.042vw 3.828vw;
  background-color: #000;
  border: 0.052vw solid #ffffff;
  border-radius: 0.521vw;
  @media (max-width: 768px) {
    border-radius: 1.269vw;
    padding: 2.411vw 8.883vw;
  }
`;

export const TextCycleButton = styled(Medium)`
  font-size: 0.938vw;
  @media (max-width: 768px) {
    font-size: 3.046vw;
    line-height: 4.264vw;
  }
`;

export const BlockButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.801vw;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2.538vw;
  }
`;

export const ImageAppStore = styled.img`
  width: 11.178vw;
  height: 3.229vw;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 31.629vw;
    height: 9.137vw;
  }
`;

export const ButtonWithAppStore = styled.button`
  height: 3.229vw;
  width: 7.292vw;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0.052vw solid #ffffff;
  border-radius: 0.521vw;
  background-color: transparent;

  @media (max-width: 768px) {
    height: 9.137vw;
    width: 31.218vw;
    border-radius: 1.269vw;
    border: 0.256vw solid #ffffff;
  }
`;
