import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Medium, Small } from "../common/elements/Text";
import { getOrdersThunk } from "../features/admin/adminSlice";
import { DropParams } from "./AdminDrop";

export const AdminDeliveryContainer = styled.div`
  width: 100%;
  padding: 7vw 2vw;
  display: flex;
  gap: 1vw;
`;

export const AdminCol = styled.div`
  width: 25%;
  height: calc(100vh - 14.437vw);
`;
export const ScrollCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  padding-bottom: 2vw;
  height: calc(100vh - 11.437vw);
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const DeliveryCard = styled.div`
  background-color: #fff;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  padding: 2vw;
  justify-content: center;
  gap: 1vw;
  &.active {
    border: 0.052vw solid #000;
  }
`;
export const AdminDelivery = () => {
  const orders = useSelector((state) => state.admin.orders);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrdersThunk());
  }, []);

  return (
    <AdminDeliveryContainer>
      <AdminCol>
        <Medium>IN PROCESSING</Medium>
        <ScrollCol>
          {orders
            .filter((o) => o.orderStatus === "IN_PROCESSING")
            .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
            .map((d) => (
              <DeliveryCard>
                <DropParams>
                  <Small>
                    Created on: {new Date(d.createdOn).toLocaleString()}
                  </Small>
                  <Small>Id: {d.id}</Small>
                  <Small>Payment type: {d.paymentType}</Small>
                  <Small>Delivery method: {d.deliveryMethod}</Small>
                  <br />
                  <Small>First name: {d.firstName}</Small>
                  <Small>Last name: {d.lastName}</Small>
                  <Small>Region: {d.country}</Small>
                  <Small>City: {d.city}</Small>
                  <Small>PostalCode: {d.postalCode}</Small>
                  <Small>Address: {d.address}</Small>
                  <Small>Additional address: {d.additionalAddress}</Small>

                  <br />
                  <Small>Email: {d.email}</Small>
                  <Small>Phone: {d.phone}</Small>
                </DropParams>
              </DeliveryCard>
            ))}
        </ScrollCol>
      </AdminCol>
    </AdminDeliveryContainer>
  );
};
