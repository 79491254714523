import styled from "styled-components";
import { Small } from "../../elements/Text";

export const TextButton = styled(Small)`
  color: ${(props) => props.c || "#fff"};
  font-weight: 500;
`;

export const Button = styled.button`
  background: #f2530f;
  border-radius: 0.521vw;
  width: ${(props) => props.w || "10vw"};
  height: 2.865vw;
  color: #ffffff;
  background: ${(props) => props.bg || "#F2530F"};
  :disabled {
    background: rgba(242, 83, 15, 0.15);
    cursor: not-allowed;
  }
  transition: all 300ms ease-in;
  :hover:enabled {
    transition: all 300ms ease-out;
    background-color: ${(props) => !props.wallet && "#ff6f32"};
    opacity: ${(props) => props.wallet && 0.8};
  }
  &.pay {
    width: 49.15%;
  }
  @media (max-width: 768px) {
    height: 11.282vw;
    border-radius: 2.051vw;
    width: ${(props) => props.wM};
  }
`;

export const StyledBlueButton = styled.button`
  width: 100%;
  height: 2.24vw;
  box-sizing: border-box;
  border-radius: 0.521vw;
  background-color: #f2530f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :disabled {
    background: rgba(242, 83, 15, 0.15);
    cursor: not-allowed;
  }
  @media (max-width: 768px) {
    height: 11.282vw;
    border-radius: 2.051vw;
  }
`;
