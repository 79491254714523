/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dropApi } from "../../api/dropApi";
import { catchNotif } from "../notification/notificationSlice";

export const addToWaitListThunk = createAsyncThunk(
  "/drop/addToWL",
  async ({ email }, { dispatch, rejectWithValue }) => {
    try {
      const response = await dropApi.addToWl(email);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);
export const getDropsThunk = createAsyncThunk(
  "/drop/getDrops",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await dropApi.getDrops();
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getDropLimitThunk = createAsyncThunk(
  "/drop/getDropLimit",
  async ({ deviceSku }, { rejectWithValue, dispatch }) => {
    try {
      const response = await dropApi.getDropLimit(deviceSku);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getDropThunk = createAsyncThunk(
  "/drop/getDrop",
  async ({ dropId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await dropApi.getDrop(dropId);
      dispatch(
        getDropLimitThunk({ deviceSku: response.data.deviceList[0].id }),
      );
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const dropSlice = createSlice({
  name: "drop",
  initialState: {
    drop: [],
    drops: [],
    dropLimit: 0,
    isLoading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getDropsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDropsThunk.fulfilled, (state, action) => {
      state.drops = action.payload.content;
      state.isLoading = false;
    });
    builder.addCase(getDropThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDropLimitThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDropLimitThunk.fulfilled, (state, action) => {
      state.dropLimit = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getDropThunk.fulfilled, (state, action) => {
      state.drop = action.payload;
      state.isLoading = false;
    });
  },
});

export default dropSlice.reducer;
