/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/common/pillz_logo.png";
import logoW from "../../assets/images/common/pillz_logo.webp";
import logoBc from "../../assets/images/common/pillz_logo_footer.png";
import logoBcW from "../../assets/images/common/pillz_logo_footer.webp";
import { Image } from "../../common/components/Image";
import { Medium, Small } from "../../common/elements/Text";
import { logoutReducer } from "../../features/auth/authSlice";
import { useBuy } from "../../hooks/useBuy";
import { useIsAdaptive } from "../../hooks/useIsAdaptive";
import { routes } from "../../pages";
import {
  BurgerButton,
  BuyText,
  Dot,
  DropDot,
  FlexEl,
  FlexMailBlocks,
  HeaderContainer,
  HeaderLeftSide,
  HeaderRightSide,
  LargeTab,
  Logo,
  MailBlock,
  MailBlockTop,
  Nav,
  NavElement,
  RightPanel,
  ShineButton,
  // Shine,
  // ShineButton,
  SocLink,
  SocLinkA,
  Tabs,
} from "./HeaderStyle";
import { BurgerClosed, BurgerOpened } from "./svg/svg";

// const row1 = [
//   { id: 1, text: "PUBLIC SALE IS LIVE NOW" },
//   { id: 2, text: "PUBLIC SALE IS LIVE NOW" },
//   { id: 3, text: "PUBLIC SALE IS LIVE NOW" },
//   { id: 4, text: "PUBLIC SALE IS LIVE NOW" },
//   { id: 5, text: "PUBLIC SALE IS LIVE NOW" },
//   { id: 6, text: "PUBLIC SALE IS LIVE NOW" },
//   { id: 7, text: "PUBLIC SALE IS LIVE NOW" },
//   { id: 8, text: "PUBLIC SALE IS LIVE NOW" },
//   { id: 9, text: "PUBLIC SALE IS LIVE NOW" },
//   { id: 10, text: "PUBLIC SALE IS LIVE NOW" },
// ];

// export const linksArray = [
// { name: "How to buy", duration: 2500 },
// { name: "Contacts", duration: 2000 },
// ];

// {linksArray.map((item) => (
// <ListItem key={item.name} to={`/#${item.name}`} smooth>
// <Dot whiteTheme={whiteTheme} />
// <Small c={!whiteTheme && "#fff"}>{item.name}</Small>
// </ListItem>
// ))}
// }

export const Header = ({ whiteTheme }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTop, setIsTop] = useState(false);
  const { pathname } = useLocation();
  const nav = useNavigate();
  const adpt = useIsAdaptive();
  const isAuth = useSelector((state) => state.auth.isAuth);
  // const items = useSelector((state) => state.xsolla.items);
  // const isLoadingCart = useSelector((state) => state.xsolla.isLoadingCart);
  const dispatch = useDispatch();
  const { buyFunc } = useBuy();

  useEffect(() => {
    if (isOpen && isTop) {
      document.body.style.overflow = "hidden";
    } else if (!isOpen) {
      document.body.style.overflow = "auto";
    }
    // if (isOpen && adpt !== "mob") {
    //   setIsOpen(false);
    //   document.body.style.overflow = "auto";
    // }
  }, [isOpen, isTop]);

  const scrollToTop = () => {
    if (window.pageYOffset > 0) {
      window.scrollBy(0, -10);
      setIsTop(false);
      window.requestAnimationFrame(scrollToTop);
    } else {
      setIsTop(true);
    }
  };

  const toggling = () => {
    const isOpenNow = isOpen;
    scrollToTop();

    if (isOpenNow) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }

    const handleTouchEnd = () => {
      const isOpenAtTouchEnd = isOpen;
      if (!isOpenAtTouchEnd) {
        scrollToTop();
      }
      window.removeEventListener("touchend", handleTouchEnd);
    };

    window.addEventListener("touchend", handleTouchEnd, { passive: false });
  };

  useEffect(() => {
    if (isOpen) {
      toggling();
    }
  }, [pathname]);

  return (
    <div id="pillz">
      <div id="manual">
        <div id="redeem">
          {/* {pathname === "/" && (
            <StyledMarquee gradient={false}>
              {row1.map((el) => (
                <BlockMarquee key={el.id}>
                  <Text>{el.text}</Text>
                  <div style={{ color: "#fff" }}>&#8226;</div>
                </BlockMarquee>
              ))}
            </StyledMarquee>
          )} */}
          <HeaderContainer
            bc={whiteTheme}
            none={pathname.includes("cellzGame")}
            transparent={
              pathname === "/" ||
              pathname.includes("game") ||
              pathname.includes("tokens_received") ||
              pathname.includes("not_arrange_delivery") ||
              pathname.includes("claim_success") ||
              pathname.includes("privacy_policy")
            }
            className={
              pathname.includes("/catalog/4d610900-b7f5-402b-a5f1-c24b383f1f14")
                ? "drop"
                : pathname.length !== 1
                ? "game-header"
                : "first"
            }
          >
            <HeaderLeftSide>
              {!whiteTheme ? (
                <Image webp={logoBcW} png={logoBc}>
                  <Logo
                    src={logoBc}
                    alt="Logo"
                    loading="lazy"
                    onClick={() => nav("/")}
                  />
                </Image>
              ) : (
                <Image webp={logoW} png={logo}>
                  <Logo
                    src={logo}
                    alt="Logo"
                    loading="lazy"
                    onClick={() => nav("/")}
                  />
                </Image>
              )}
              <Nav>
                {routes.map(
                  (p) =>
                    p.title &&
                    !p.personal_info && (
                      <NavElement
                        key={p.path}
                        onClick={() => nav(p.path)}
                        whiteTheme={whiteTheme}
                        className={
                          // eslint-disable-next-line no-nested-ternary
                          pathname.length === p.path.length &&
                          pathname.includes(p.path)
                            ? "active"
                            : "null"
                        }
                      >
                        <Dot whiteTheme={whiteTheme} />
                        <Small c={!whiteTheme && "#fff"}>{p.title}</Small>
                      </NavElement>
                    ),
                )}
              </Nav>
            </HeaderLeftSide>
          </HeaderContainer>
          <HeaderRightSide
            className={
              !pathname.includes(
                "/catalog/4d610900-b7f5-402b-a5f1-c24b383f1f14",
              ) && pathname.length !== 1
                ? "fixed"
                : null
            }
            none={pathname.includes("cellzGame")}
          >
            {/* {adpt !== "mob" && (
              <NavElement
                key="/redeem"
                onClick={() => nav("/redeem")}
                whiteTheme={whiteTheme}
                className={
                  // eslint-disable-next-line no-nested-ternary
                  pathname.length === "/redeem".length &&
                  pathname.includes("/redeem")
                    ? "active"
                    : "null"
                }
              >
                <Dot whiteTheme={whiteTheme} />
                <Small c={!whiteTheme && "#fff"}>Redeem</Small>
              </NavElement>
            )} */}
            {(!isOpen || adpt !== "mob") && (
              <>
                {/* {items.reduce((sum, { quantity }) => sum + quantity, 0) !==
                0 ? (
                  <NavElement
                    key="/cart"
                    onClick={() => nav("/cart")}
                    none={pathname.includes("cellzGame")}
                    whiteTheme={whiteTheme}
                    className={
                      // eslint-disable-next-line no-nested-ternary
                      adpt === "mob" && !pathname.includes("/cart")
                        ? "cart"
                        : pathname.includes("/cart")
                        ? "activecart"
                        : "null"
                    }
                  >
                    <Dot whiteTheme={whiteTheme} />
                    <Small c={!whiteTheme && "#fff"}>
                      Cart (
                      {items.reduce((sum, { quantity }) => sum + quantity, 0)})
                    </Small>
                  </NavElement>
                ) : ( */}
                <ShineButton
                  whiteTheme={whiteTheme}
                  className={
                    pathname.includes("privacy_policy") ? "black" : null
                  }
                  onClick={() => buyFunc()}
                >
                  <BuyText>BUY</BuyText>
                </ShineButton>
                {/* )} */}
              </>
            )}

            {adpt !== "mob" && (
              <>
                {isAuth ? (
                  <>
                    <NavElement
                      key="/profile/info"
                      onClick={() => nav("/profile/info")}
                      whiteTheme={whiteTheme}
                      className={
                        // eslint-disable-next-line no-nested-ternary
                        pathname.includes("profile") ? "active" : "null"
                      }
                    >
                      <Dot whiteTheme={whiteTheme} />
                      <Small c={!whiteTheme && "#fff"}>Account</Small>
                    </NavElement>
                    <Small
                      c="rgba(255, 67, 79, 1)"
                      style={{ cursor: "pointer" }}
                      onClick={() => dispatch(logoutReducer())}
                    >
                      Logout
                    </Small>
                  </>
                ) : (
                  <NavElement
                    key="/login"
                    onClick={() => nav("/login")}
                    whiteTheme={whiteTheme}
                    className={
                      // eslint-disable-next-line no-nested-ternary
                      pathname.length === "/login".length &&
                      pathname.includes("login")
                        ? "active"
                        : "null"
                    }
                  >
                    <Dot whiteTheme={whiteTheme} />
                    <Small c={!whiteTheme && "#fff"}>Login</Small>
                  </NavElement>
                )}
              </>
            )}
            {/* {adpt !== "mob" && (
              <ShineButton
                whiteTheme={whiteTheme}
                game={pathname.includes("game")}
                onClick={() => buyFunc()}
              >
                {pathname.includes("game") && (
                  <Image webp={shineW} png={shine}>
                    <Shine src={shine} alt="shine" loading="lazy" />
                  </Image>
                )}
                <BuyText>Join waitlist</BuyText>
              </ShineButton>
            )} */}
            <BurgerButton onClick={() => toggling()}>
              {isOpen ? (
                <BurgerOpened
                  width={adpt !== "mob" ? "1.401vw" : "5.641vw"}
                  height={adpt !== "mob" ? "1.401vw" : "5.641vw"}
                  fill={whiteTheme ? "#000" : "#FFF"}
                />
              ) : (
                <BurgerClosed
                  width={adpt !== "mob" ? "1.875vw" : "7.692vw"}
                  height={adpt !== "mob" ? "0.625vw" : "2.564vw"}
                  fill={whiteTheme ? "#000" : "#FFF"}
                />
              )}
            </BurgerButton>
          </HeaderRightSide>
          <RightPanel
            whiteTheme={whiteTheme}
            className={isOpen ? "active" : null}
          >
            <Tabs whiteTheme={whiteTheme} className="onlymob">
              <FlexEl onClick={() => nav("/")}>
                <DropDot
                  whiteTheme={whiteTheme}
                  className={
                    // eslint-disable-next-line no-nested-ternary
                    pathname === "/"
                      ? whiteTheme
                        ? "active"
                        : "active_white"
                      : "null"
                  }
                />
                <LargeTab c={whiteTheme ? "#000" : "#fff"}>Product</LargeTab>
              </FlexEl>
              {/* <FlexEl onClick={() => nav("/cart")}>
                <DropDot
                  whiteTheme={whiteTheme}
                  className={
                    // eslint-disable-next-line no-nested-ternary
                    pathname === "/cart"
                      ? whiteTheme
                        ? "active"
                        : "active_white"
                      : "null"
                  }
                />
                <LargeTab c={whiteTheme ? "#000" : "#fff"}>Cart</LargeTab>
              </FlexEl> */}
              <FlexEl onClick={() => nav("/catalog")}>
                <DropDot
                  whiteTheme={whiteTheme}
                  className={
                    // eslint-disable-next-line no-nested-ternary
                    pathname === "/catalog"
                      ? whiteTheme
                        ? "active"
                        : "active_white"
                      : "null"
                  }
                />
                <LargeTab c={whiteTheme ? "#000" : "#fff"}>Catalog</LargeTab>
              </FlexEl>
              {!isAuth ? (
                <FlexEl onClick={() => nav("/login")}>
                  <DropDot
                    whiteTheme={whiteTheme}
                    className={
                      // eslint-disable-next-line no-nested-ternary
                      pathname === "/login"
                        ? whiteTheme
                          ? "active"
                          : "active_white"
                        : "null"
                    }
                  />
                  <LargeTab c={whiteTheme ? "#000" : "#fff"}>Log In</LargeTab>
                </FlexEl>
              ) : (
                <FlexEl onClick={() => nav("/profile/info")}>
                  <DropDot
                    whiteTheme={whiteTheme}
                    className={
                      // eslint-disable-next-line no-nested-ternary
                      pathname.includes("/profile")
                        ? whiteTheme
                          ? "active"
                          : "active_white"
                        : "null"
                    }
                  />
                  <LargeTab c={whiteTheme ? "#000" : "#fff"}>Profile</LargeTab>
                </FlexEl>
              )}

              <FlexEl onClick={() => nav("/game")}>
                <DropDot
                  whiteTheme={whiteTheme}
                  className={
                    // eslint-disable-next-line no-nested-ternary
                    pathname === "/game"
                      ? whiteTheme
                        ? "active"
                        : "active_white"
                      : "null"
                  }
                />
                <LargeTab c={whiteTheme ? "#000" : "#fff"}>Game</LargeTab>
              </FlexEl>
              {/* <FlexEl onClick={() => nav("/redeem")}>
                <DropDot
                  whiteTheme={whiteTheme}
                  className={
                    // eslint-disable-next-line no-nested-ternary
                    pathname === "/redeem"
                      ? whiteTheme
                        ? "active"
                        : "active_white"
                      : "null"
                  }
                />
                <LargeTab c={whiteTheme ? "#000" : "#fff"}>Redeem</LargeTab>
              </FlexEl> */}
              {isAuth && (
                <FlexEl onClick={() => dispatch(logoutReducer())}>
                  <LargeTab c="rgba(255, 67, 79, 1)">Logout</LargeTab>
                </FlexEl>
              )}
            </Tabs>
            <Tabs whiteTheme={whiteTheme}>
              <FlexEl>
                <LargeTab>
                  <SocLink
                    onClick={() => window.open("https://pillztech.medium.com/")}
                    c={whiteTheme ? "#000" : "#fff"}
                  >
                    Medium
                  </SocLink>
                </LargeTab>
              </FlexEl>
              <FlexEl>
                <LargeTab>
                  <SocLink
                    onClick={() =>
                      window.open("https://instagram.com/pillztech")
                    }
                    c={whiteTheme ? "#000" : "#fff"}
                  >
                    Instagram
                  </SocLink>
                </LargeTab>
              </FlexEl>
              <FlexEl>
                <LargeTab>
                  <SocLink
                    onClick={() => window.open("https://twitter.com/PillzTech")}
                    c={whiteTheme ? "#000" : "#fff"}
                  >
                    Twitter
                  </SocLink>
                </LargeTab>
              </FlexEl>
              <FlexEl>
                <LargeTab>
                  <SocLink
                    onClick={() =>
                      window.open("https://discord.com/invite/pillz")
                    }
                    c={whiteTheme ? "#000" : "#fff"}
                  >
                    Discord
                  </SocLink>
                </LargeTab>
              </FlexEl>
            </Tabs>
            <FlexMailBlocks whiteTheme={whiteTheme}>
              <MailBlock>
                <MailBlockTop>
                  <Small c={whiteTheme ? "#000" : "#fff"}>
                    For any questions
                  </Small>
                  <Medium>
                    <SocLinkA
                      href="mailto:hi@pillz.tech"
                      c={whiteTheme ? "#000" : "#fff"}
                    >
                      hi@pillz.tech
                    </SocLinkA>
                  </Medium>
                </MailBlockTop>
                <Small
                  onClick={() => nav("/privacy_policy")}
                  style={{ cursor: "pointer" }}
                  c={
                    whiteTheme
                      ? "rgba(0, 0, 0, 0.35)"
                      : "rgba(255, 255, 255, 0.35)"
                  }
                >
                  Privacy Policy
                </Small>
              </MailBlock>
              <MailBlock>
                <MailBlockTop>
                  <Small c={whiteTheme ? "#000" : "#fff"}>
                    Collabs and partnerships
                  </Small>
                  <Medium>
                    <SocLinkA
                      href="mailto:partners@pillz.tech"
                      c={whiteTheme ? "#000" : "#fff"}
                    >
                      partners@pillz.tech
                    </SocLinkA>
                  </Medium>
                </MailBlockTop>
                <Small
                  c={
                    whiteTheme
                      ? "rgba(0, 0, 0, 0.35)"
                      : "rgba(255, 255, 255, 0.35)"
                  }
                >
                  Terms and Conditions
                </Small>
              </MailBlock>
            </FlexMailBlocks>
          </RightPanel>
        </div>
      </div>
    </div>
  );
};
