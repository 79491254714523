/* eslint-disable import/no-cycle */
import { lazy } from "react";
import styled from "styled-components";
import { Profile } from "../components/profile/Profile";
import { Cellz } from "../components/profile/helpers/Cellz";
// import { DevicesRoom } from "../components/profile/helpers/devicesRoom/DevicesRoom";
import { DropNew } from "../components/catalog/drop/DropNew";
import { CellzGame } from "../components/cellz/CellzGame";
import { Tokens } from "../components/profile/helpers/Tokens";
import { AdminDelivery } from "./AdminDelivery";
import { AdminDrop } from "./AdminDrop";
import { AdminProduct } from "./AdminProduct";
// import { CartPage } from "./CartPage";
import { CatalogPage } from "./CatalogPage";
import { LoginPage } from "./LoginPage";
import { MadeDelivery } from "./MadeDelivery";
import ManualPage from "./ManualPage";
import { NavigateAdminPage } from "./NavigateAdminPage";
import { NavigateProfilePage } from "./NavigateProfilePage";
import { NavigateSupportPage } from "./NavigateSupportPage";
import { PrivacyPage } from "./PrivacyPage";

const ProductPage = lazy(() => import("./ProductPage"));
// const CatalogPage = lazy(() => import("./CatalogPage"));
// const DropPage = lazy(() => import("./DropPage"));
const GamePage = lazy(() => import("./GamePage"));
// const BreedingPage = lazy(() => import("./BreedingPage"));
// const CartPage = lazy(() => import("./CartPage"));
// const SuccessfulPurchasePage = lazy(() => import("./SuccessfulPurchasePage"));
// const ClaimPage = lazy(() => import("./ClaimPage"));
// const MadeDelivery = lazy(() => import("./MadeDelivery"));
// const NotArrangeDeliveryPage = lazy(() => import("./NotArrangeDeliveryPage"));

const routes = [
  {
    path: "/",
    element: <ProductPage />,
    title: "Product",
    noContainer: true,
    isWhite: true,
  },
  {
    path: "/catalog",
    element: <CatalogPage />,
    title: "Catalog",
    isWhite: true,
  },
  {
    path: "/catalog/:dropId",
    element: <DropNew />,
    isWhite: false,
    noContainer: true,
  },
  {
    path: "/game",
    element: <GamePage />,
    title: "Game",
    isWhite: false,
    noContainer: true,
  },
  {
    path: "/privacy_policy",
    element: <PrivacyPage />,
    isWhite: true,
    noContainer: true,
  },
  // {
  //   path: "/breeding",
  //   element: <BreedingPage />,
  //   noContainer: true,
  //   isWhite: false,
  // },
  // {
  //   path: "/cart",
  //   element: <CartPage />,
  //   isWhite: true,
  // },
  // {
  //   path: "/successful_purchase",
  //   element: <SuccessfulPurchasePage />,
  //   isWhite: true,
  //   noContainer: true,
  // },
  // {
  //   path: "/redeem",
  //   element: <ClaimPage />,
  //   isWhite: false,
  // },
  {
    path: "/manual",
    element: <ManualPage />,
    isWhite: false,
    noContainer: true,
  },
  {
    path: "/tokens_received",
    element: <MadeDelivery />,
    isWhite: true,
    noContainer: true,
  },
  // {
  //   path: "/not_arrange_delivery",
  //   element: <NotArrangeDeliveryPage />,
  //   isWhite: true,
  //   noContainer: true,
  // },
  // {
  //   path: "/redeem_success",
  //   element: <AfterClaim />,
  //   isWhite: true,
  //   noContainer: true,
  // },
];

const authRoutes = [
  {
    path: "/cellzGame",
    element: <CellzGame />,
    isWhite: false,
    noContainer: true,
  },

  {
    path: "/profile/",
    element: <NavigateProfilePage />,
    isWhite: true,
    outlet: [
      {
        path: "info",
        element: <Profile />,
        title: "Personal info",
        isWhite: true,
      },
      // {
      //   path: "devices",
      //   element: <DevicesRoom />,
      //   title: "Devices",
      //   isWhite: true,
      // },
      {
        path: "tokens",
        element: <Tokens />,
        title: "Devices",
        isWhite: true,
      },
      {
        path: "pets",
        element: <Cellz />,
        title: "Pets - Cellz",
        isWhite: true,
      },
    ],
  },
  // {
  //   path: "/breeding/tokens",
  //   element: <SelectTokenPage />,
  //   noContainer: true,
  //   isWhite: false,
  // },
  // {
  //   path: "/breeding/action/",
  //   element: <NavigateBreedingPage />,
  //   noContainer: true,
  //   isWhite: false,
  //   outlet: [
  //     {
  //       path: "genecode",
  //       element: <GenecodeAction />,
  //       noContainer: true,
  //       isWhite: false,
  //     },
  //     {
  //       path: "result",
  //       element: <BreedingInProgress />,
  //       noContainer: true,
  //       isWhite: false,
  //     },
  // ],
  // },
];

const adminRoute = {
  path: "/admin/",
  element: <NavigateAdminPage />,
  noContainer: true,
  isWhite: true,
  outlet: [
    {
      path: "drop",
      element: <AdminDrop />,
      noContainer: true,
      isWhite: true,
    },
    {
      path: "product",
      element: <AdminProduct />,
      noContainer: true,
      isWhite: true,
    },
    {
      path: "delivery",
      element: <AdminDelivery />,
      noContainer: true,
      isWhite: true,
    },
  ],
};

const supportRoute = {
  path: "/support",
  element: <NavigateSupportPage />,
  noContainer: true,
  isWhite: true,
};

const noAuthRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
    noContainer: true,
    isWhite: true,
  },
];

const PageContainer = ({ children }) => <Container>{children}</Container>;

const Container = styled.div`
  padding: 0 3.385vw 0 3.385vw;

  @media (max-width: 1280px) {
    padding: 0 3.516vw 0 3.516vw;
  }
  @media (max-width: 768px) {
    padding: 0 3.516vw 0 3.516vw;
  }
`;

export {
  PageContainer,
  adminRoute,
  authRoutes,
  noAuthRoutes,
  routes,
  supportRoute,
};
