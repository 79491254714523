import styled from "styled-components";
import { Small } from "../../../common/elements/Text";

export const Text = styled(Small)`
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 3.046vw;
    line-height: 4.264vw;
  }
`;

export const BlockCredential = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.781vw;
  margin-bottom: ${(props) => props.mb};
  width: ${(props) => props.w || "30.938vw"};

  @media (max-width: 768px) {
    width: 100%;
    gap: ${(props) => props.g || "1.015vw"};
  }
`;

export const ButtonDelete = styled.button`
  padding: 3.299vw 2.03vw;
  background: rgba(255, 67, 79, 0.1);
  border-radius: 2.03vw;
`;

export const TextDelete = styled(Small)`
  font-size: 3.299vw;
  line-height: 4.619vw;
`;
