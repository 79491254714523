import { useEffect, useRef, useState } from "react";
import {
  HiddenTextInput,
  OtpInput,
  OtpInputContainer,
  OtpInputFocused,
  OtpInputSection,
  OtpInputText,
} from "./OTPCodeStyle";

export const OTPCode = ({ code = [], setCode, setPinReady, noValid }) => {
  const [inputContainerIsFocused, setInputContainerIsFocused] = useState(false);
  const textInputRef = useRef(null);
  const codeDigitsArray = new Array(6).fill(0);

  const handleOnPress = () => {
    setInputContainerIsFocused(true);
    textInputRef?.current?.focus();
  };

  const handleOnBlur = () => {
    setInputContainerIsFocused(false);
  };

  useEffect(() => {
    setPinReady(code.length === 6);
    return () => setPinReady(false);
  }, [code]);

  const toCodeDigitInput = (_value, index) => {
    const emptyInputChar = " ";
    const digit = code[index] || emptyInputChar;

    const isCurrentDigit = index === code.length;
    const isLastDigit = index === 5;
    const isCodeFull = code.length === 6;

    const isDigitFocused = isCurrentDigit || (isLastDigit && isCodeFull);

    const StyledOtpInput =
      inputContainerIsFocused && isDigitFocused ? OtpInputFocused : OtpInput;

    return (
      <StyledOtpInput key={index} noValid={noValid}>
        <OtpInputText noValid={noValid}>{digit}</OtpInputText>
      </StyledOtpInput>
    );
  };

  return (
    <>
      <OtpInputSection onClick={() => handleOnPress()}>
        <OtpInputContainer>
          {codeDigitsArray.map(toCodeDigitInput)}
        </OtpInputContainer>
      </OtpInputSection>
      <HiddenTextInput
        value={code}
        onChange={(e) => setCode(e.target.value)}
        maxLength={6}
        ref={textInputRef}
        onBlur={handleOnBlur}
      />
    </>
  );
};
