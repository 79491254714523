import styled from "styled-components";
import { Large, Medium } from "../../common/elements/Text";

export const ManualContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3.031vw;
  @media (max-width: 1280px) {
    padding-top: 6.594vw;
  }
  @media (max-width: 768px) {
    padding-top: 9.626vw;
  }
`;

export const PaddingContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9.896vw 3.385vw 0 3.385vw;
  @media (max-width: 768px) {
    padding: 15.385vw 6.154vw 0 6.154vw;
  }
`;

export const ManualCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f4f5;
  border-radius: 2.083vw;
  margin-top: 9.896vw;

  @media (max-width: 768px) {
    border-radius: 2.564vw;
  }
`;

export const ManualFlex = styled.div`
  width: 100%;
  display: flex;
  height: 40.521vw;
  &.small {
    height: 32.417vw;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    gap: 1.282vw;
    &.small {
      height: auto;
      margin-bottom: 15.385vw;
    }
  }
`;

export const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    order: ${(props) => props.order && 1};
  }
`;

export const RightSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;

export const Source = styled.source`
  height: 40.521vw;
  width: 40.521vw;

  &.small {
    width: 40.521vw;
    height: 32.417vw;
  }

  @media (max-width: 768px) {
    height: 51.282vw;
    width: 51.282vw;
    margin-top: 3.846vw;

    &.small {
      width: 51.282vw;
      height: 32.051vw;
    }
  }
`;
export const ManualImg = styled.video`
  height: 40.521vw;
  width: 40.521vw;

  &.small {
    width: 40.521vw;
    height: 32.417vw;
  }

  @media (max-width: 768px) {
    height: 51.282vw;
    width: 51.282vw;
    margin-top: 3.846vw;

    &.small {
      width: 51.282vw;
      height: 32.051vw;
    }
  }
`;

export const ManualTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.344vw;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.w};

  @media (max-width: 768px) {
    width: auto;
    gap: 5.128vw;
  }
`;

export const ManualDescription = styled(Medium)`
  width: ${(props) => props.w || "40.521vw"};
  line-height: 1.823vw;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 768px) {
    line-height: 4.308vw;
    font-size: 3.077vw;
    width: 69vw;
  }
`;

export const Line = styled.div`
  height: 0.052vw;
  width: 77.604vw;
  background-color: #9d9d9d;
  margin-top: 1.146vw;

  @media (max-width: 768px) {
    margin-top: 11.538vw;
    height: 0.256vw;
  }
`;

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 42.24vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 88.718vw;
  }
`;

export const NeckImage = styled.img`
  width: 42.917vw;
  height: 42.24vw;

  @media (max-width: 768px) {
    height: 88.718vw;
    width: 81.538vw;
  }
`;

export const InfoIcon = styled.img`
  height: 0.8vw;
  width: 0.729vw;
  margin-top: 0.2vw;
  margin-right: 0.3vw;
  margin-left: -2vw;
  @media (max-width: 768px) {
    height: 2.636vw;
    width: 2.4vw;
    margin-top: 0.5vw;
    margin-right: 1vw;
    margin-left: -2vw;
  }
`;

export const InfoContainer = styled.div`
  margin-top: 1.302vw;
  width: ${(props) => props.w || "30.521vw"};
  display: inline-block;
  vertical-align: middle;
  white-space: pre;
  text-indent: 2vw;
  white-space: pre-line;
  font-family: "regular";
  font-size: 0.833vw;
  line-height: 1.167vw;
  font-weight: 450;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.35);

  @media (max-width: 768px) {
    margin-top: 3.846vw;
    width: 69vw;
    font-size: 2.564vw;
    line-height: 3.59vw;
  }
`;

export const InfoTextContainer = styled.div`
  width: ${(props) => props.w};
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    width: 69vw;
  }
`;

export const LargeStyle = styled(Large)`
  @media (max-width: 768px) {
    font-size: 8.974vw;
    line-height: 8.526vw;
    width: 70vw;
  }
`;

export const AbsolutedLarge = styled(Large)`
  position: absolute;
  @media (max-width: 768px) {
    font-size: 8.974vw;
    line-height: 8.526vw;
  }
`;

export const LinkStepContainer = styled.div`
  margin-top: 8.333vw;
  display: flex;
  flex-direction: column;
  gap: 2.344vw;
  @media (max-width: 768px) {
    gap: 5.128vw;
    margin-top: 11.538vw;
  }
`;

export const LinkBlocks = styled.div`
  margin-top: 3.646vw;
  margin-bottom: 7.187vw;
  display: flex;
  gap: 2.604vw;
  @media (max-width: 768px) {
    margin-top: 8.974vw;
    gap: 2.308vw;
    margin-bottom: 0vw;
  }
`;

export const LinkImage = styled.img`
  width: 11.384vw;
  height: 3.348vw;
  cursor: pointer;
  transition: all 300ms ease-in;
  :hover {
    transition: all 300ms ease-out;
    transform: scale(1.02);
  }
  @media (max-width: 768px) {
    width: 29.582vw;
    height: 8.7vw;
  }
`;
