import irl from "../../../../assets/videos/drop/irl.mp4";
import {
  DropSecondContainer,
  IrlOpac,
  IrlVideo,
  SmallB,
  SmallContainer,
  StepD,
  TextContainerStyleD,
  TitleFirst,
  TopContainer,
} from "../DropStyle";

export const DropTenth = () => (
  <DropSecondContainer className="transparent" id="IrlEvents">
    <IrlOpac />
    <IrlVideo muted loop playsInline autoPlay>
      <source src={irl} type="video/mp4" />
    </IrlVideo>
    <TopContainer className="abs">
      <SmallB c="#fff">2023-2025</SmallB>
      <TextContainerStyleD>
        <TitleFirst c="#fff">
          <StepD c="#fff">⑥</StepD>&nbsp;IRL events
        </TitleFirst>
      </TextContainerStyleD>
      <SmallContainer>
        <SmallB c="rgba(255,255,255,0.5)">
          By purchasing The Pillz Pack, you become part of our community&apos;s
          exclusive club. You&apos;ll have the opportunity to attend private
          events both online and in real life, not only hosted by us but also by
          our partners. Get ready to enjoy some fantastic experiences and
          connections as a valued member of our community!
        </SmallB>
      </SmallContainer>
    </TopContainer>
  </DropSecondContainer>
);
