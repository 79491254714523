import styled from "styled-components";
import { Small } from "../../elements/Text";

export const ArrowContainer = styled.div`
  right: 0;
  position: absolute;
  height: 2.865vw;
  width: 2.865vw;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 11.282vw;
    height: 11.282vw;
  }
`;

export const ArrowBlueContainer = styled.div`
  right: 0;
  top: 0;
  position: absolute;
  height: 2.24vw;
  width: 2.24vw;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 11.282vw;
    width: 11.282vw;
  }
`;

export const SelectArrow = styled.img`
  height: 0.52vw;
  width: 1vw;
  right: 1.094vw;
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  &.abs_active {
    transform: rotate(180deg);
    transform-origin: 50% 25%;
    transition: 0.3s;
  }
  &.abs {
    transition: 0.3s;
  }
  @media (max-width: 768px) {
    height: 2vw;
    width: 3.9vw;
    right: 5.094vw;
  }
`;

export const SelectPopup = styled.div`
  position: relative;
`;

export const BlueList = styled.div`
  width: 100%;
  min-height: 2.24vw;
  max-height: 8.1vw;
  background: transparent;
  position: absolute;
  top: 2.5vw;
  z-index: 2;
  border-radius: 0.521vw;
  border: 0.052vw solid #f2530f;
  background-color: ${(props) => props.bc || "#f2f2f2"};
  overflow: auto;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 117, 255, 0.3);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 117, 255, 0.3);
  box-shadow: 0px 0px 3px 0px rgba(0, 117, 255, 0.3);
  @media (max-width: 768px) {
    border: 0.256vw solid #f2530f;
    top: 12.15vw;
    border-radius: 2.051vw;
    min-height: 11.282vw;
    max-height: 41.2vw;
  }
`;

export const List = styled.div`
  width: 100%;
  min-height: 2.865vw;
  max-height: 10.38vw;
  background: #fff;
  position: absolute;
  z-index: 2;
  top: 3.15vw;
  border-radius: 0.521vw;
  border: 0.052vw solid rgba(0, 0, 0, 0.15);
  background-color: ${(props) => props.bc || "#f2f2f2"};
  overflow: auto;
  -webkit-box-shadow: 0px 0px 3px 0px
    ${(props) => props.brc || "rgba(0, 0, 0, 0.2)"};
  -moz-box-shadow: 0px 0px 3px 0px
    ${(props) => props.brc || "rgba(0, 0, 0, 0.2)"};
  box-shadow: 0px 0px 3px 0px ${(props) => props.brc || "rgba(0, 0, 0, 0.2)"};
  @media (max-width: 768px) {
    border: 0.256vw solid
      ${(props) =>
        !props.black ? "rgba(0, 0, 0, 0.15)" : "rgba(255, 255, 255, 0.15)"};
    top: 12.15vw;
    border-radius: 2.051vw;
    min-height: 11.282vw;
    max-height: 41.2vw;
  }
`;

export const ListElement = styled.div`
  height: 2.865vw;
  border-bottom: 0.052vw solid
    ${(props) =>
      !props.black ? "rgba(0, 0, 0, 0.15)" : "rgba(255, 255, 255, 0.15)"};
  padding: 0 0.833vw 0 0.833vw;
  display: flex;
  align-items: center;
  cursor: pointer;
  :last-child {
    border-bottom: 0;
  }
  :hover {
    background: ${(props) =>
      !props.black ? "rgba(0, 0, 0, 0.05)" : "rgba(255, 255, 255, 0.05)"};
  }
  &.active {
    background: #f2530f;
  }
  @media (max-width: 768px) {
    height: 11.282vw;
    border-bottom: 0.256vw solid
      ${(props) =>
        !props.black ? "rgba(0, 0, 0, 0.15)" : "rgba(255, 255, 255, 0.15)"};
    padding: 0vw 3.846vw 0vw 3.846vw;
  }
`;

export const BlueListElement = styled.div`
  min-height: 2.24vw;
  border-bottom: 0.052vw solid #f2530f;
  padding: 0 0.833vw 0 0.833vw;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${(props) => (!props.black ? "#f1f1f1" : "#000")};
  :last-child {
    border-bottom: 0;
  }
  :hover {
    background: ${(props) =>
      !props.black ? "rgba(242, 83, 15, 0.15)" : "rgba(255, 240, 225, 0.05)"};
  }
  &.active {
    background: #f2530f;
  }
  @media (max-width: 768px) {
    height: 11.282vw;
    border-bottom: 0.256vw solid #f2530f;
    padding: 0vw 3.846vw 0vw 3.846vw;
  }
`;

export const StyledSpan = styled.span`
  color: #ff434f;
  font-size: 1vw;
  @media (max-width: 768px) {
    font-size: 3.8vw;
  }
`;

export const StyledSmallLabel = styled(Small)`
  @media (max-width: 768px) {
    font-size: 3.333vw;
    line-height: 4.667vw;
  }
`;
