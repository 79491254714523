import { $api } from ".";
import { $apiMiscUtills } from ".";

export const profileApi = {
  walletAdd(signature, publicAddress) {
    return $api
      .post("/profile/wallets", { signature, publicAddress })
      .then((res) => res);
  },
  walletDelete(walletAddress) {
    return $api.delete(`/profile/wallets/${walletAddress}`).then((res) => res);
  },
  getProfile() {
    return $api.get("/profile").then((res) => res);
  },
  getChatDevices() {
    return $api.get("/profile/chat/devices").then((res) => res);
  },
  getChatMessages(deviceId, page, size) {
    return $api
      .get(
        `/profile/chat/devices/${deviceId}/messages?sort=createdOn,desc&page=${page}&size=${size}`,
      )
      .then((res) => res);
  },
  getCellz() {
    return $apiMiscUtills.get("/private/my-cellz").then((res) => res);
  },
  otpSend(email) {
    return $api
      .get("/profile/email", {
        params: { email },
      })
      .then((res) => res);
  },
};
