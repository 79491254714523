/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { breedingApi } from "../../api/breedingApi";
import { catchNotif } from "../notification/notificationSlice";

export const checkBreedingThunk = createAsyncThunk(
  "/breeding/checkBreeding",
  async ({ wallet }, { dispatch, rejectWithValue }) => {
    try {
      const response = await breedingApi.checkBreeding(wallet);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getGencodeThunk = createAsyncThunk(
  "/breeding/gencode",
  async ({ wallet, tokenId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await breedingApi.getGencode(wallet, tokenId);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getGencodeInfoThunk = createAsyncThunk(
  "/breeding/gencodeInfo",
  async ({ gencode }, { dispatch, rejectWithValue }) => {
    try {
      const response = await breedingApi.getGencodeInfo(gencode);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const pushFriendThunk = createAsyncThunk(
  "/breeding/pushFriend",
  async (
    { selfGencode, friendGencode, selfTwitter, friendTwitter },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await breedingApi.pushFriend(
        selfGencode,
        friendGencode,
        selfTwitter,
        friendTwitter,
      );
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const breedingSlice = createSlice({
  name: "breeding",
  initialState: {
    isLoading: false,
    tokens: [],
    myToken: null,
    friendToken: null,
    cachedFriendCode: "",
    method: "copy",
    inProgress: false,
    isClaimed: false,
  },
  reducers: {
    resetFriendInfo(state) {
      state.friendToken = null;
      state.cachedFriendCode = "";
    },
    setMethod(state, action) {
      state.method = action.payload;
    },
    cacheFriendCode(state, action) {
      state.cachedFriendCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkBreedingThunk.fulfilled, (state, action) => {
      state.tokens = action.payload;
      state.isLoading = false;
    });
    builder.addCase(checkBreedingThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGencodeThunk.fulfilled, (state, action) => {
      state.myToken = action.payload;
    });
    builder.addCase(getGencodeInfoThunk.fulfilled, (state, action) => {
      state.friendToken = action.payload;
    });
    builder.addCase(getGencodeInfoThunk.rejected, (state) => {
      state.friendToken = null;
    });
    builder.addCase(pushFriendThunk.fulfilled, (state) => {
      state.inProgress = true;
    });
  },
});

export const { resetFriendInfo, setMethod, cacheFriendCode } =
  breedingSlice.actions;

export default breedingSlice.reducer;
