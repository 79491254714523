import { useSelector } from "react-redux";
import { CountButtons } from "../../../common/elements/Blocks";
import { Small } from "../../../common/elements/Text";
import { useIsAdaptive } from "../../../hooks/useIsAdaptive";
import {
  ButtonCheckout,
  DropNewContainer,
  MediumPay,
  PayPanel,
} from "./DropStyle";
// import { DropEight } from "./dropEight/dropEight";
// import { DropFifth } from "./dropFifth/dropFifth";
import { DropFirst } from "./dropFirst/dropFirst";
import { DropFourth } from "./dropFourth/dropFourth";
// import { DropNinth } from "./dropNinth/dropNinth";
// import { DropSecond } from "./dropSecond/dropSecond";
import { DropSeventh } from "./dropSeventh/dropSeventh";
// import { DropSixth } from "./dropSixth/dropSixth";
import { DropTenth } from "./dropTenth/dropTenth";
import { DropThird } from "./dropThird/dropThird";

export const DropNew = () => {
  // const drop = useSelector((state) => state.drop.drop);
  // const { dropId } = useParams();
  // const [isInput, setIsInput] = useState(false);
  // const [customCountValue, setCustomCountValue] = useState(0);
  const adpt = useIsAdaptive();
  // const nav = useNavigate();
  // const dropLimit = useSelector((state) => state.drop.dropLimit);
  // const cart = useSelector((state) => state.delivery.cart);
  // const items = useSelector((state) => state.xsolla.items);
  // const cartId = useSelector((state) => state.xsolla.cartId);
  const isLoading = useSelector((state) => state.xsolla.isLoading);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (dropId) {
  //     dispatch(getDropThunk({ dropId }));
  //   }
  // }, []);

  // const setCountFunc = async (device, type) => {
  //   const item = await items.find((i) => i.sku === device.id);

  //   if (type === "plus") {
  //     if (items.length !== 0 && item) {
  //       dispatch(
  //         fillCartThunk(
  //           items.map((i) =>
  //             i.sku === item.sku
  //               ? { sku: item.sku, quantity: item.quantity + 1 }
  //               : i,
  //           ),
  //         ),
  //       );
  //     } else {
  //       dispatch(fillCartThunk([...items, { sku: device.id, quantity: 1 }]));
  //     }
  //   } else if (type === "minus") {
  //     if (item.quantity !== 1) {
  //       dispatch(
  //         fillCartThunk(
  //           items.map((i) =>
  //             i.sku === item.sku
  //               ? { sku: item.sku, quantity: item.quantity - 1 }
  //               : i,
  //           ),
  //         ),
  //       );
  //     } else {
  //       dispatch(deleteCartItemThunk({ cartId, itemSku: item.sku }));
  //     }
  //   }
  // };

  // const setIsInputFunc = (bool) => {
  //   const storeCount = items.find(
  //     (c) => c.sku === drop.deviceList[0].id,
  //   )?.quantity;
  //   if (bool) {
  //     if (storeCount !== customCountValue) {
  //       setCustomCountValue(storeCount);
  //       setIsInput(bool);
  //     }
  //   }
  //   setIsInput(bool);
  // };

  // const setCustomCountFunc = async (device, value) => {
  //   const item = await items.find((i) => i.sku === device.id);

  //   if (value > 0 && value <= dropLimit) {
  //     dispatch(
  //       fillCartThunk(
  //         items.map((i) =>
  //           i.sku === item.sku ? { sku: item.sku, quantity: value } : i,
  //         ),
  //       ),
  //     );
  //   } else if (value === 0) {
  //     dispatch(deleteCartItemThunk({ cartId, itemSku: item.sku }));
  //   }

  // setIsInputFunc(false);
  // };

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     setCustomCountFunc(drop.deviceList[0], customCountValue);
  //   }
  // };

  // if (!drop?.deviceList?.length) {
  //   return null;
  // }

  return (
    <DropNewContainer>
      <PayPanel>
        <MediumPay>The Pillz pack</MediumPay>
        <MediumPay c="rgba(0,0,0,0.5)">
          {/* {drop.deviceList[0].price}$ or 0.1eth */}
        </MediumPay>
        {adpt !== "mob" ? (
          <CountButtons
          // className={isInput ? "dropwide" : null}
          >
            {/* <CircleBlock
              className="new"
              // red={cart.find((c) => c.id === drop.deviceList[0].id)?.count < 2}
              // onClick={() =>
              //   !isLoading && setCountFunc(drop.deviceList[0], "minus")
              // }
              isLoading={isLoading}
            >
              <Minus src={minus} isLoading={isLoading} className="new" />
            </CircleBlock> */}
            {/* {isInput ? (
              <TextInput
                w={adpt !== "mob" ? "4.7vw" : "15vw"}
                label="quantity"
                name="customCount"
                type="number"
                autoFocus
                value={customCountValue}
                onChange={({ target }) =>
                  setCustomCountValue(Number(target.value))
                }
                onKeyPress={handleKeyPress}
                onBlur={() =>
                  setCustomCountFunc(drop.deviceList[0], customCountValue)
                }
              />
            ) : ( */}
            <MediumPay
              style={
                isLoading ? { cursor: "not-allowed" } : { cursor: "pointer" }
              }
              c={isLoading && "#cccccc"}
              // onClick={() => setIsInputFunc(true)}
            >
              {/* {items.find((c) => c.sku === drop.deviceList[0].id)?.quantity ||
                0} */}
            </MediumPay>
            {/* )} */}
            {/* <CircleBlock
              className="new"
              // onClick={() =>
              //   !isLoading &&
              //   dropLimit >
              //     (items.find((c) => c.sku === drop.deviceList[0].id)
              //       ?.quantity || 0) &&
              //   setCountFunc(drop.deviceList[0], "plus")
              // }
              // isLoading={
              //   isLoading ||
              //   dropLimit <=
              //     (items.find((c) => c.sku === drop.deviceList[0].id)
              //       ?.quantity || 0)
              // }
            >
              <Plus
                src={plus}
                className="new"
                // isLoading={
                //   isLoading ||
                //   dropLimit <=
                //     (items.find((c) => c.sku === drop.deviceList[0].id)
                //       ?.quantity || 0)
                // }
              />
            </CircleBlock> */}
          </CountButtons>
        ) : null}
        <ButtonCheckout
          disabled
          // disabled={isLoading}
          // onClick={() => nav("/cart")}
        >
          <Small c="#fff">OUT OF STOCK</Small>
        </ButtonCheckout>
      </PayPanel>

      <DropFirst />
      <DropSeventh />
      {/* <DropSecond /> */}
      <DropThird />
      <DropFourth />
      {/* <DropFifth /> */}
      {/* <DropSixth /> */}
      {/* <DropEight /> */}
      {/* <DropNinth /> */}
      <DropTenth />
    </DropNewContainer>
  );
};
