import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIsAdaptive } from "../../../hooks/useIsAdaptive";
import { getCellzThunk } from "../../../features/profile/profileSlice";
import {
  CartTitles,
  CartZone,
  DeliveryAndLinkedContainers,
} from "../ProfileStyle";
import { Medium } from "../../../common/elements/Text";
import { DropDownProfile } from "../../../common/components/dropDownBlock/DropDownBlock";
import { YourCellzCharacters } from "../subcomponents/LinkedDevices";
import { Notification } from "../../../common/components/notification/Notification";

export const Cellz = () => {
  const adpt = useIsAdaptive();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCellzThunk());
  }, []);

  return (
    <>
      {adpt !== "mob" && (
        <CartTitles>
          <CartZone>
            <Medium>Cellz Characters</Medium>
          </CartZone>
        </CartTitles>
      )}
      <DeliveryAndLinkedContainers>
        <CartZone>
          {adpt === "mob" ? (
            <DropDownProfile label={<>Cellz Characters</>}>
              <YourCellzCharacters />
            </DropDownProfile>
          ) : (
            <YourCellzCharacters />
          )}
        </CartZone>
      </DeliveryAndLinkedContainers>
      <Notification whiteTheme />
    </>
  );
};
