import styled from "styled-components";

export const WithTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.083vw;
  padding-top: 7.031vw;
  @media (max-width: 1280px) {
    gap: 2.656vw;
    padding-top: 8.594vw;
  }
  @media (max-width: 768px) {
    gap: 6.41vw;
    padding-top: 21.626vw;
  }
`;

export const NoTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6.576vw;
  @media (max-width: 1280px) {
    padding-top: 8.045vw;
  }
  @media (max-width: 768px) {
    padding-top: 18.805vw;
    padding-bottom: 4vw;
  }
`;

export const CircleBlock = styled.div`
  display: flex;
  width: 4.115vw;
  height: 4.115vw;
  border: 0.052vw solid ${(props) => (!props.isLoading ? "#f2530f" : "#f2d9ce")};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (!props.isLoading ? "pointer" : "not-allowed")};
  transition: all 300ms ease-out;
  &:hover {
    transition: all 300ms ease-out;
    opacity: 0.8;
  }
  &.new {
    height: 1.667vw;
    width: 1.667vw;
  }
  @media (max-width: 768px) {
    width: 9.487vw;
    height: 9.487vw;
    border: 0.256vw solid
      ${(props) => (!props.isLoading ? "#f2530f" : "#f2d9ce")};
    &.cart {
      width: 6.154vw;
      height: 6.154vw;
    }
  }
`;

export const CountButtons = styled.div`
  display: flex;
  min-width: ${(props) => (props.inCart ? "100%" : "5.573vw")};
  gap: 0.3vw;
  justify-content: space-between;
  align-items: center;
  &.cart {
    width: 13.802vw;
  }
  &.cartwide {
    width: 13.802vw;
  }
  &.dropwide {
    width: 10vw;
  }
  @media (max-width: 768px) {
    min-width: ${(props) => (props.inCart ? "100%" : "35.256vw")};
    gap: 0.8vw;
    &.cart {
      min-width: 32.802vw;
      width: 32.802vw;
      gap: 0.2vw;
    }
    &.cartwide {
      min-width: 37.802vw;
      width: 37.802vw;
      gap: 0.2vw;
    }
  }
`;

export const BlueContainer = styled.div`
  width: 100%;
  height: 2.24vw;
  box-sizing: border-box;
  border-radius: 0.521vw;
  border: 0.052vw solid #f2530f;
  padding: 0 0.833vw 0 0.833vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 3.333vw;
    line-height: 4.667vw;
    height: 11.282vw;
    border: 0.256vw solid #f2530f;
    border-radius: 2.051vw;
    padding: 0 3.846vw 0 3.846vw;
  }
`;

export const FieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.w || "100%"};
  &:focus-within label {
    transform: translate(0, 0.521vw) scale(0.7);
  }
`;

export const TextContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LottieContainer = styled.div`
  width: 6vw;
  height: 2vw;
  padding-top: 6.771vw;
  @media (max-width: 768px) {
    position: absolute;
    top: 42.6%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15vw;
    height: 6vw;
    padding-top: 30vw;
  }
`;
