import { $api } from ".";

export const claimApi = {
  checkTokens(wallet) {
    return $api
      .get("/claim/pillzOwnedTokes", {
        params: { wallet },
      })
      .then((res) => res);
  },
  claimBurn(
    country,
    firstName,
    lastName,
    address,
    additionalAddress,
    postalCode,
    phone,
    city,
    email,
    tokenId,
    tokenAddress,
    wallet,
    deviceId,
  ) {
    return $api
      .post("/claim/burnToken", {
        country,
        firstName,
        lastName,
        address,
        additionalAddress,
        postalCode,
        phone,
        city,
        email,
        tokenId,
        tokenAddress,
        wallet,
        deviceId,
      })
      .then((res) => res);
  },
};
