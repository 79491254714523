/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { xsollaApi } from "../../api/xsollaApi";
import { catchNotifXsolla } from "../notification/notificationSlice";

export const getCartThunk = createAsyncThunk(
  "/xsolla/getCart",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await xsollaApi.getCart();
      return response.data;
    } catch (e) {
      dispatch(catchNotifXsolla(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getTokenThunk = createAsyncThunk(
  "/xsolla/getToken",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await xsollaApi.createToken();
      localStorage.setItem("xsollaToken", response.data.token);
      dispatch(getCartThunk());
      return response.data;
    } catch (e) {
      dispatch(catchNotifXsolla(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const xsollaInitializeThunk = createAsyncThunk(
  "/xsolla/initialize",
  async ({ updateToken }, { dispatch, rejectWithValue }) => {
    try {
      if (!localStorage.getItem("xsollaToken") || updateToken) {
        return dispatch(getTokenThunk());
      }
      return dispatch(getCartThunk());
    } catch (e) {
      dispatch(catchNotifXsolla(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const fillCartThunk = createAsyncThunk(
  "/xsolla/fillCart",
  async (items, { dispatch, rejectWithValue }) => {
    try {
      const response = await xsollaApi.fillCart(items);
      return response.data;
    } catch (e) {
      dispatch(catchNotifXsolla(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const deleteCartItemThunk = createAsyncThunk(
  "/xsolla/deleteCartItem",
  async ({ cartId, itemSku }, { dispatch, rejectWithValue }) => {
    try {
      await xsollaApi.deleteItemCart(cartId, itemSku);
      return { itemSku };
    } catch (e) {
      dispatch(catchNotifXsolla(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const redeemPromoThunk = createAsyncThunk(
  "/xsolla/redeemPromo",
  async ({ promo, cartId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await xsollaApi.redeemPromo(promo, cartId);
      return response.data;
    } catch (e) {
      dispatch(catchNotifXsolla(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const removePromoThunk = createAsyncThunk(
  "/xsolla/removePromo",
  async ({ cartId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await xsollaApi.removePromo(cartId);
      return response.data;
    } catch (e) {
      dispatch(catchNotifXsolla(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const createOrderThunk = createAsyncThunk(
  "/xsolla/createOrder",
  async (
    { pillzOrderId, payMethod, wallet, token_count, return_url },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await xsollaApi.createOrder(
        pillzOrderId,
        payMethod,
        wallet,
        token_count,
        return_url,
      );
      return response.data;
    } catch (e) {
      dispatch(catchNotifXsolla(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

const xsollaSlice = createSlice({
  name: "xsolla",
  initialState: {
    items: [],
    prepItems: [],
    price: null,
    cartId: null,
    promotions: [],
    isLoading: false,
    isLoadingCart: false,
    cartIsSettled: false,
  },
  reducers: {
    savePrevCartItems(state, action) {
      state.prepItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCartThunk.pending, (state) => {
      state.isLoadingCart = true;
    });
    builder.addCase(getCartThunk.fulfilled, (state, action) => {
      state.cartId = action.payload.cart_id;
      state.items = action.payload.items;
      state.price = action.payload.price;
      state.promotions = action.payload.promotions;
      state.isLoadingCart = false;
    });
    builder.addCase(getCartThunk.rejected, (state) => {
      state.isLoadingCart = false;
    });
    builder.addCase(xsollaInitializeThunk.pending, (state) => {
      state.cartIsSettled = false;
    });
    builder.addCase(xsollaInitializeThunk.fulfilled, (state) => {
      state.cartIsSettled = true;
    });
    builder.addCase(xsollaInitializeThunk.rejected, (state) => {
      state.cartIsSettled = true;
    });
    builder.addCase(fillCartThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fillCartThunk.fulfilled, (state, action) => {
      state.cartId = action.payload.cart_id;
      state.items = action.payload.items;
      state.price = action.payload.price;
      state.promotions = action.payload.promotions;
      state.isLoading = false;
      if (state.prepItems.length !== 0) {
        state.prepItems = [];
      }
    });
    builder.addCase(fillCartThunk.rejected, (state) => {
      state.isLoading = false;
      if (state.prepItems.length !== 0) {
        state.prepItems = [];
      }
    });

    builder.addCase(redeemPromoThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(redeemPromoThunk.fulfilled, (state, action) => {
      state.cartId = action.payload.cart_id;
      state.items = action.payload.items;
      state.price = action.payload.price;
      state.promotions = action.payload.promotions;
      state.isLoading = false;
    });
    builder.addCase(redeemPromoThunk.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(removePromoThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removePromoThunk.fulfilled, (state, action) => {
      state.cartId = action.payload.cart_id;
      state.items = action.payload.items;
      state.price = action.payload.price;
      state.promotions = action.payload.promotions;
      state.isLoading = false;
    });
    builder.addCase(removePromoThunk.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(deleteCartItemThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCartItemThunk.fulfilled, (state, action) => {
      const deliveryPrice =
        // eslint-disable-next-line no-nested-ternary
        action.payload.itemSku.slice(-1) === "3"
          ? 20
          : action.payload.itemSku.slice(-1) === "2"
          ? 10
          : 0;
      state.items = state.items.filter((i) => i.sku !== action.payload.itemSku);
      if (
        action.payload.itemSku === "additionalBundle1" ||
        action.payload.itemSku === "additionalBundle2" ||
        action.payload.itemSku === "additionalBundle3"
      ) {
        state.price = {
          amount_without_discount:
            state.price.amount_without_discount - deliveryPrice,
          amount: state.price.amount - deliveryPrice,
        };
      }
      state.isLoading = false;
    });
    builder.addCase(deleteCartItemThunk.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { savePrevCartItems } = xsollaSlice.actions;

export default xsollaSlice.reducer;
