/* eslint-disable no-use-before-define */
import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
  },
  reducers: {
    addNotif(state, action) {
      state.notifications = [
        ...state.notifications,
        {
          id: state.notifications.length
            ? state.notifications[state.notifications.length - 1].id + 1
            : 1,
          text: action.payload.text,
          type: action.payload.type,
        },
      ];
    },
    catchNotif(state, action) {
      if (
        action.payload?.response?.status !== 403 &&
        action.payload?.response?.status !== 401
      ) {
        state.notifications = [
          ...state.notifications,
          {
            id: state.notifications.length
              ? state.notifications[state.length - 1].id + 1
              : 1,
            text:
              action.payload?.response?.status !== 500
                ? action.payload.response?.data?.message
                : action.payload.response?.data?.error,
            type: "cancel",
          },
        ];
      }
    },
    catchNotifXsolla(state, action) {
      if (
        action.payload?.response?.data?.statusCode !== 403 &&
        action.payload?.response?.data?.statusCode !== 401
      ) {
        state.notifications = [
          ...state.notifications,
          {
            id: state.notifications.length
              ? state.notifications[state.length - 1].id + 1
              : 1,
            text: action.payload.response?.data?.errorMessage
              ? action.payload.response?.data?.errorMessage
              : "Bad request",
            type: "cancel",
          },
        ];
      }
    },
    delNotif(state, action) {
      state.notifications = state.notifications.filter(
        (el) => el.id !== action.payload,
      );
    },
  },
});

export const { addNotif, delNotif, catchNotif, catchNotifXsolla } =
  notificationSlice.actions;

export default notificationSlice.reducer;
