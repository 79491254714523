/* eslint-disable no-nested-ternary */
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import loader from "../../assets/lottie/l_g.json";
import { LoadingContainer } from "../../components/catalog/CatalogStyle";
import { FlexComponents, Suk } from "../../components/catalog/drop/DropStyle";
import { useIsAdaptive } from "../../hooks/useIsAdaptive";
import { LottieContainer } from "../elements/Blocks";

/* eslint-disable react/destructuring-assignment */
export const Image = ({ children, png, webp, jpg }) => (
  <picture>
    <source srcSet={webp} type="image/webp" />
    <source
      srcSet={png || jpg}
      type={(png && "image/png") || (jpg && "image/jpeg")}
    />
    {children}
  </picture>
);

const checkSizes = (ratio, adpt) => {
  if (adpt !== "mob") {
    if (ratio < 1) {
      return { width: "30.729vw", height: "61.979vw" };
    }
    if (ratio === 1) {
      return { width: "30.729vw", height: "30.729vw" };
    }
    if (ratio > 1) {
      return { width: "61.979vw", height: "30.729vw" };
    }
  }
  if (ratio < 1) {
    return { width: "100%", height: "185.936vw" };
  }
  if (ratio === 1) {
    return { width: "100%", height: "92.968vw" };
  }
  if (ratio > 1) {
    return { width: "100%", height: "46.484vw" };
  }

  return null;
};

export const ImageTileGrid = ({ imagePaths, loading, setLoading }) => {
  const [imageElements, setImageElements] = useState([]);
  const adpt = useIsAdaptive();

  useEffect(() => {
    setImageElements([]);
    const loadImage = (path) =>
      new Promise((resolve) => {
        const img = new window.Image();
        const cacheBuster = Math.random();
        img.src = `${path.photo}?cacheBuster=${cacheBuster}`;
        img.onload = () => {
          const ratio = img.width / img.height;
          // eslint-disable-next-line no-nested-ternary
          const finalRatio = ratio === 1 ? 1 : ratio < 1 ? 0.5 : 2;
          const { width, height } = checkSizes(ratio, adpt);
          const elementStyle = {
            backgroundImage: `url(${path.photo}?cacheBuster=${cacheBuster})`,
            borderRadius: adpt === "mob" ? "3.077vw" : "0.761vw",
            flexGrow: finalRatio,
            marginBottom: 0,
            width,
            height,
          };

          resolve(<Suk key={path.name} style={elementStyle} />);
        };
      });

    const loadImages = async () => {
      setLoading(true);

      const loadedImages = await Promise.all(imagePaths.map(loadImage));

      const sortedImages = loadedImages.sort((a, b) => {
        const nameA = parseInt(a.key, 10);
        const nameB = parseInt(b.key, 10);
        return nameA - nameB;
      });
      setImageElements(sortedImages);
      setLoading(false);
    };

    loadImages();
  }, [imagePaths, adpt]);

  if (loading) {
    return (
      <LoadingContainer>
        <LottieContainer animationData={loader} loop>
          <Lottie animationData={loader} loop />
        </LottieContainer>
      </LoadingContainer>
    );
  }
  return <FlexComponents>{imageElements}</FlexComponents>;
};
