import { $api } from ".";

export const deliveryApi = {
  pushDelivery(
    country,
    firstName,
    lastName,
    address,
    additionalAddress,
    postalCode,
    phone,
    city,
    email,
    tokenId,
  ) {
    return $api
      .post("/localOrder", {
        country,
        firstName,
        lastName,
        address,
        additionalAddress,
        postalCode,
        phone,
        city,
        email,
        tokenId,
      })
      .then((res) => res);
  },
  pushOrder(
    country,
    firstName,
    lastName,
    address,
    additionalAddress,
    postalCode,
    phone,
    city,
    email,
    paymentType,
    deliveryMethod,
    orderDevices,
    wallet,
  ) {
    return $api
      .post("/order", {
        // sandbox: true,
        country,
        firstName,
        lastName,
        address,
        additionalAddress,
        postalCode,
        phone,
        city,
        email,
        paymentType,
        deliveryMethod,
        orderDevices,
        wallet,
      })
      .then((res) => res);
  },
  getCountries() {
    return $api.get("/countries").then((res) => res);
  },
  notifyCountry(country, email) {
    return $api
      .post("/countries/waitlist", {
        country,
        email,
      })
      .then((res) => res);
  },
};
