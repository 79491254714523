import styled from "styled-components";

export const Small = styled.p`
  font-family: "medium";
  font-size: 0.833vw;
  line-height: 1.167vw;
  font-weight: 530;
  letter-spacing: 0.02em;
  color: ${(props) => props.c || "#000"};
  @media (max-width: 1280px) {
    font-size: 0.938vw;
    line-height: 1.313vw;
  }
  @media (max-width: 768px) {
    font-size: 3.077vw;
    line-height: 4.308vw;
  }
`;

export const Medium = styled.p`
  font-family: "regular";
  font-size: 1.302vw; // 25px
  line-height: 1.432vw;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: ${(props) => props.c || "#000"};
  @media (max-width: 1280px) {
    font-size: 1.406vw;
    line-height: 1.688vw;
  }
  @media (max-width: 768px) {
    font-size: 4.103vw;
    line-height: 4.513vw;
  }
`;

export const Large = styled.p`
  font-family: "regular";
  font-size: 4.688vw;
  line-height: 4.453vw;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: ${(props) => props.c || "#000"};
  @media (max-width: 1280px) {
    font-size: 5.078vw;
    line-height: 4.824vw;
  }
  @media (max-width: 768px) {
    font-size: 15.385vw;
    line-height: 14.615vw;
  }
`;
