/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adminApi } from "../../api/adminApi";
import { catchNotif } from "../notification/notificationSlice";

export const addDeviceThunk = createAsyncThunk(
  "/admin/newDevice",
  async ({ values, technical }, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.newDevice(values, technical);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const editDeviceThunk = createAsyncThunk(
  "/admin/editDevice",
  async ({ id, values, technical }, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.editDevice(id, values, technical);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const deleteDeviceThunk = createAsyncThunk(
  "/admin/deleteDevice",
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.deleteDevice(id);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const addEquipmentThunk = createAsyncThunk(
  "/admin/addEquipment",
  async ({ id, name, photo, order }, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.newEquipment(id, name, photo, order);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const editEquipmentThunk = createAsyncThunk(
  "/admin/editEquipment",
  async (
    { deviceId, equipmentId, name, photo, order },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await adminApi.editEquipment(
        deviceId,
        equipmentId,
        name,
        photo,
        order,
      );
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const deleteEquipmentThunk = createAsyncThunk(
  "/admin/deleteEquipment",
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.deleteEquipment(id);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getDevicesThunk = createAsyncThunk(
  "/admin/getDevices",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.getDevices();
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const addDropThunk = createAsyncThunk(
  "/admin/newDrop",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.newDrop(values);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const putDropThunk = createAsyncThunk(
  "/admin/putDrop",
  async ({ values }, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.putDrop(values);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const deleteDropThunk = createAsyncThunk(
  "/admin/deleteDrop",
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.deleteDrop(id);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getDropsThunk = createAsyncThunk(
  "/admin/getDrops",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.getDrops();
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const putDeviceInDropThunk = createAsyncThunk(
  "/admin/putDeviceInDrop",
  async ({ dropId, deviceId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.putDeviceInDrop(dropId, deviceId);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const deleteDeviceInDropThunk = createAsyncThunk(
  "/admin/deleteDeviceInDrop",
  async ({ dropId, deviceId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.deleteDeviceInDrop(dropId, deviceId);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getOrdersThunk = createAsyncThunk(
  "/admin/getOrders",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminApi.getOrders();
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    drops: [],
    devices: [],
    orders: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getDevicesThunk.fulfilled, (state, action) => {
      state.devices = action.payload.content;
    });
    builder.addCase(addDeviceThunk.fulfilled, (state, action) => {
      state.devices = [...state.devices, action.payload];
    });
    builder.addCase(editDeviceThunk.fulfilled, (state, action) => {
      state.devices = state.devices.map((sd) =>
        sd.id === action.payload.id ? action.payload : sd,
      );
    });
    builder.addCase(deleteDeviceThunk.fulfilled, (state, action) => {
      state.devices = state.devices.filter((sd) => sd.id !== action.payload);
    });
    builder.addCase(addEquipmentThunk.fulfilled, (state, action) => {
      state.devices = state.devices.map((sd) =>
        sd.id === action.payload.deviceId
          ? { ...sd, equipment: action.payload.deviceEquipments }
          : sd,
      );
    });
    builder.addCase(editEquipmentThunk.fulfilled, (state, action) => {
      state.devices = state.devices.map((sd) =>
        sd.id === action.payload.deviceId
          ? { ...sd, equipment: action.payload.deviceEquipments }
          : sd,
      );
    });
    builder.addCase(deleteEquipmentThunk.fulfilled, (state, action) => {
      state.devices = state.devices.map((sd) =>
        sd.id === action.payload.id ? action.payload : sd,
      );
    });
    builder.addCase(getDropsThunk.fulfilled, (state, action) => {
      state.drops = action.payload.content;
    });
    builder.addCase(addDropThunk.fulfilled, (state, action) => {
      state.drops = [...state.drops, action.payload];
    });
    builder.addCase(putDropThunk.fulfilled, (state, action) => {
      state.drops = state.drops.map((sd) =>
        sd.id === action.payload.id ? action.payload : sd,
      );
    });
    builder.addCase(deleteDropThunk.fulfilled, (state, action) => {
      state.drops = state.drops.filter((sd) => sd.id !== action.payload);
    });
    builder.addCase(putDeviceInDropThunk.fulfilled, (state, action) => {
      state.drops = state.drops.map((sd) =>
        sd.id === action.payload.id ? action.payload : sd,
      );
    });
    builder.addCase(deleteDeviceInDropThunk.fulfilled, (state, action) => {
      state.drops = state.drops.map((sd) =>
        sd.id === action.payload.id ? action.payload : sd,
      );
    });
    builder.addCase(getOrdersThunk.fulfilled, (state, action) => {
      state.orders = action.payload;
    });
  },
});

export default adminSlice.reducer;
