import Vimeo from "@u-wave/react-vimeo";
import { useEffect, useRef, useState } from "react";
import { Small } from "../../../../common/elements/Text";
import { useIsAdaptive } from "../../../../hooks/useIsAdaptive";
import useOnScreen from "../../../../hooks/useOnScreen";
import { VolOff, VolOn } from "../../../header/svg/svg";
import {
  PlayPause,
  RelativeContainer,
  VideoContainer,
  Volume,
} from "../../../product/second/ProductSecondStyle";
import {
  BottomThird,
  ButtonThird,
  DropThirdContainer,
  SmallB,
  SmallContainer,
  // SpanStatus,
  StepD,
  TextContainerStyleD,
  TitleFirst,
  TopContainer,
} from "../DropStyle";

export const DropThird = () => {
  const adpt = useIsAdaptive();
  const [isRunning, setIsRunning] = useState(false);
  const [volume, setVolume] = useState(false);
  const vidRef = useRef();
  const isVisible = useOnScreen(vidRef);

  const videoPreload = () => {
    setVolume(false);
    setIsRunning(false);
  };

  const videoPlay = () => {
    if (!isRunning) {
      setIsRunning(true);
    } else {
      setIsRunning(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      if (!volume) {
        setVolume(false);
      }
      setIsRunning(true);
    } else {
      setIsRunning(false);
    }
  }, [isVisible]);

  return (
    <DropThirdContainer id="CellzGame">
      <TopContainer>
        {/* <SmallB c="#fff">
          Availability: <SpanStatus c="#26BF3F">right away</SpanStatus>
        </SmallB> */}
        <TextContainerStyleD>
          <TitleFirst c="#fff">
            <StepD c="#fff">④</StepD>&nbsp;Mobile Game App
          </TitleFirst>
        </TextContainerStyleD>
        <SmallContainer>
          <SmallB c="rgba(255,255,255,0.5)">
            Cellz is an RPG we&apos;re developing. It evolves from a simple
            arcade game in the first levels to an online MMORPG in the last
            ones. Develop your character, starting with a cell, and turn it into
            a full-fledged friend who will live on your Pillz device.
          </SmallB>
        </SmallContainer>
      </TopContainer>
      <BottomThird>
        <RelativeContainer ref={vidRef} className="gameDrop">
          <PlayPause onClick={() => videoPlay()} className="gameDrop" />
          {volume ? (
            <Volume
              className="gameDrop"
              onClick={() => {
                setVolume(false);
              }}
            >
              <VolOn
                width={adpt === "mob" ? "5.692vw" : "2.08vw"}
                height={adpt === "mob" ? "5.692vw" : "2.08vw"}
              />
            </Volume>
          ) : (
            <Volume
              className="gameDrop"
              onClick={() => {
                setVolume(true);
              }}
            >
              <VolOff
                width={adpt === "mob" ? "5.692vw" : "2.08vw"}
                height={adpt === "mob" ? "5.692vw" : "2.08vw"}
              />
            </Volume>
          )}
          <VideoContainer className="gameDrop">
            <Vimeo
              responsive
              video="835939759"
              paused={!isRunning}
              muted={!volume}
              volume={!volume ? 0 : 0.5}
              controls={false}
              playsInline
              onEnd={() => videoPreload()}
              loop
            />
          </VideoContainer>
        </RelativeContainer>
        <ButtonThird to="/game/#pillz" smooth>
          <Small c="#fff">MORE ABOUT GAME →</Small>
        </ButtonThird>
      </BottomThird>
    </DropThirdContainer>
  );
};
