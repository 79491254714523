import React, { useCallback } from "react";

export function useForm() {
  const [values, setValues] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [isValid, setIsValid] = React.useState(false);

  const onChange = useCallback(
    (evt) => {
      const input = evt.target;
      const { value, name, type, checked } = input;

      if (type === "datetime-local") {
        setValues((prevValues) => ({
          ...prevValues,
          [name]: new Date(value).toISOString(),
        }));
      } else if (type === "checkbox") {
        setValues((prevValues) => ({ ...prevValues, [name]: checked }));
      } else {
        setValues((prevValues) => ({ ...prevValues, [name]: value }));
      }

      const newErrors = { ...errors };
      let newIsValid = true;

      if (name === "emailConfirm") {
        newErrors[name] = values.email === value ? "" : "Emails do not match";
        newIsValid =
          newErrors[name] === "" && input.closest("form").checkValidity();
      } else {
        newErrors[name] = input.validationMessage;
        newIsValid =
          Object.values(newErrors).every((error) => error === "") &&
          input.closest("form").checkValidity();
      }

      setErrors(newErrors);
      setIsValid(newIsValid);
    },
    [values, errors],
  );

  const resetForm = useCallback(() => {
    setValues({});
    setErrors({});
    setIsValid(false);
  }, []);

  return {
    values,
    onChange,
    resetForm,
    errors,
    isValid,
    setValues,
  };
}
