/* eslint-disable import/no-cycle */
import { useLocation } from "react-router-dom";
import logo from "../../../assets/images/common/pillz_logo_footer.png";
import logoW from "../../../assets/images/common/pillz_logo_footer.webp";
import { Image } from "../../../common/components/Image";
import { Small } from "../../../common/elements/Text";
import { useBuy } from "../../../hooks/useBuy";
import { useIsAdaptive } from "../../../hooks/useIsAdaptive";
import {
  SocialDiscord,
  SocialInstagram,
  SocialMedium,
  // SocialTikTok,
  SocialTwitter,
  // SocialYouTube,
} from "../../header/svg/svg";
import {
  ActiveInside,
  BlockLinks,
  BlockText,
  BorderTop,
  Dot,
  LinksSocial,
  ListItem,
  Logo,
  Nav,
  NavElement,
  ProductContainer,
  SocialsBlock,
  TextSmall,
  TitleBuy,
  TitleBuyContainer,
  ViewPillz,
} from "./ProductTenthStyle";

const footerRoutes = [
  {
    path: "/manual",
    isWhite: false,
    title: "Manual",
  },
];

const socials = [
  {
    id: 1,
    path: "https://pillztech.medium.com/",
    element: (
      <SocialMedium
        width="3.978vw"
        height="2.202vw"
        widthMobile="11.462vw"
        heightMobile="6.345vw"
      />
    ),
  },
  {
    id: 2,
    path: "https://twitter.com/PillzTech",
    element: (
      <SocialTwitter
        width="2.671vw"
        height="2.202vw"
        widthMobile="7.698vw"
        heightMobile="6.345vw"
      />
    ),
  },
  {
    id: 3,
    path: "https://www.instagram.com/pillztech/",
    element: (
      <SocialInstagram
        width="2.203vw"
        height="2.202vw"
        widthMobile="6.345vw"
        heightMobile="6.345vw"
      />
    ),
  },
  {
    id: 4,
    path: "https://discord.gg/pillz",
    element: (
      <SocialDiscord
        width="2.896vw"
        height="2.220vw"
        widthMobile="8.345vw"
        heightMobile="6.345vw"
      />
    ),
  },
  // {
  //   id: 5,
  //   element: (
  //     <SocialYouTube
  //       width="3.978vw"
  //       height="2.202vw"
  //       widthMobile="9.063vw"
  //       heightMobile="6.345vw"
  //     />
  //   ),
  // },
  // {
  //   id: 6,
  //   path: "https://www.tiktok.com/@pillztech",
  //   element: (
  //     <SocialTikTok
  //       width="3.978vw"
  //       height="2.202vw"
  //       widthMobile="5.414vw"
  //       heightMobile="6.345vw"
  //     />
  //   ),
  // },
];

export const ProductTenth = () => {
  const adpt = useIsAdaptive();
  // пока здесь будет
  const path = useLocation();
  const { buyFunc } = useBuy();

  return (
    <ProductContainer id="Contacts">
      {path.pathname !== "/manual" && (
        <BlockLinks>
          <Nav>
            {footerRoutes.map((p) => (
              <NavElement
                to={`${p.path}/#${p.path.slice(1)}`}
                smooth
                key={p.path}
              >
                {adpt !== "mob" ? (
                  <Dot>
                    <ActiveInside />
                  </Dot>
                ) : (
                  <></>
                )}
                <TextSmall c="#fff">
                  Manual for Pillz v1.0 (old device)
                </TextSmall>
              </NavElement>
            ))}
          </Nav>
        </BlockLinks>
      )}
      <TitleBuyContainer>
        {/* <LargeStyledJoinWl c="rgba(255, 255, 255, 0.35)">
          Public sales start on June 22 1 PM UTC
        </LargeStyledJoinWl> */}
        <TitleBuy
          // to="/#pillz"
          // smooth
          onClick={() => buyFunc()}
          // id="vKpGXHzK"
        >
          BUY
        </TitleBuy>
      </TitleBuyContainer>
      <ViewPillz>
        <ListItem to="/#pillz" smooth>
          <Image webp={logoW} png={logo}>
            <Logo src={logo} alt="Logo" loading="lazy" />
          </Image>
        </ListItem>
        <BlockText>
          <Small c="rgba(255, 255, 255, 0.35)">
            Legal address: State of Delaware, Country of New Castle, City of
            Wilmington, 919 North Market Street, ste 950, Zip 19801.
            <br />© Pillz Inc., 2023. All rights reserved.
          </Small>
        </BlockText>
      </ViewPillz>
      <SocialsBlock>
        {socials.map((el) => (
          <LinksSocial href={el.path} key={el.id}>
            <BorderTop
              className={
                adpt !== "mob"
                  ? el.id === 4 && "border"
                  : (el.id === 3 || el.id === 4) && "border"
              }
            />
            {el.element}
          </LinksSocial>
        ))}
      </SocialsBlock>
    </ProductContainer>
  );
};
