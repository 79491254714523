import { useIsAdaptive } from "../../../hooks/useIsAdaptive";
import {
  CartTitles,
  CartZone,
  DeliveryAndLinkedContainers,
} from "../ProfileStyle";
import { Medium } from "../../../common/elements/Text";
import { Notification } from "../../../common/components/notification/Notification";
import { YourTokens } from "../subcomponents/YourTokens";
import { DropDownProfile } from "../../../common/components/dropDownBlock/DropDownBlock";

export const Tokens = () => {
  const adpt = useIsAdaptive();
  return (
    <>
      {adpt !== "mob" && (
        <CartTitles>
          <CartZone>
            <Medium>Devices</Medium>
          </CartZone>
        </CartTitles>
      )}
      <DeliveryAndLinkedContainers>
        <CartZone>
          {adpt === "mob" ? (
            <DropDownProfile label={<>Tokens</>}>
              <YourTokens />
            </DropDownProfile>
          ) : (
            <YourTokens />
          )}
        </CartZone>
      </DeliveryAndLinkedContainers>
      <Notification whiteTheme />
    </>
  );
};
