import p1 from "../../../../assets/images/drop/p1.png";
import p2 from "../../../../assets/images/drop/p2.png";
import p3 from "../../../../assets/images/drop/p3.png";
import p4 from "../../../../assets/images/drop/p4.png";
import p5 from "../../../../assets/images/drop/p5.png";
import p6 from "../../../../assets/images/drop/p6.png";
import { Small } from "../../../../common/elements/Text";
import {
  AbsoluteName,
  BorderBottom,
  BorderTop,
  ButtonThird,
  DropSeventhContainer,
  PackageCard,
  PackageContainer,
  PackageImage,
  SmallP,
  StepD,
  TextContainerStyleD,
  TitleFirst,
} from "../DropStyle";

export const DropSeventh = () => (
  <DropSeventhContainer id="Fittings">
    <TextContainerStyleD>
      <TitleFirst c="#000">
        <StepD c="#000">①②③</StepD>&nbsp;What&apos;s in the box?
      </TitleFirst>
    </TextContainerStyleD>
    <PackageContainer>
      <PackageCard>
        <BorderTop className="first" />
        <PackageImage src={p1} />
        <AbsoluteName>
          <SmallP>Device</SmallP>
        </AbsoluteName>
        <BorderBottom className="first" />
      </PackageCard>
      <PackageCard>
        <BorderTop />
        <PackageImage src={p2} />
        <AbsoluteName>
          <SmallP>Dock-station</SmallP>
        </AbsoluteName>
        <BorderBottom />
      </PackageCard>
      <PackageCard>
        <BorderTop />
        <PackageImage src={p3} />
        <AbsoluteName>
          <SmallP>Pendant</SmallP>
        </AbsoluteName>
        <BorderBottom />
      </PackageCard>
      <PackageCard>
        <BorderTop />
        <PackageImage src={p4} />
        <AbsoluteName>
          <SmallP>Bracelet</SmallP>
        </AbsoluteName>
        <BorderBottom />
      </PackageCard>
      <PackageCard>
        <BorderTop />
        <PackageImage src={p5} />
        <AbsoluteName>
          <SmallP>Carabiner</SmallP>
        </AbsoluteName>
        <BorderBottom />
      </PackageCard>
      <PackageCard>
        <BorderTop />
        <PackageImage src={p6} />
        <AbsoluteName>
          <SmallP>USB type C</SmallP>
        </AbsoluteName>
        <BorderBottom />
      </PackageCard>
    </PackageContainer>
    <ButtonThird className="orange" to="/#pillz" smooth>
      <Small c="#fff">LEARN MORE ABOUT PILLZ DEVICE →</Small>
    </ButtonThird>
  </DropSeventhContainer>
);
