import Marquee from "react-fast-marquee";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import active from "../../assets/images/header/active.png";
import activeW from "../../assets/images/header/activeW.png";
import hover from "../../assets/images/header/hover.png";
import hoverW from "../../assets/images/header/hoverW.png";
import init from "../../assets/images/header/init.png";
import initW from "../../assets/images/header/initW.png";
import { Large, Medium, Small } from "../../common/elements/Text";

export const StyledMarquee = styled(Marquee)`
  height: 2.3vw;
  background-color: #f2530f;
  @media (max-width: 768px) {
    height: 5.604vw;
  }
`;
export const DropDown = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 22;
  background-color: ${(props) => (props.whiteTheme ? "#f2f2f2" : "#000")};
  padding: 0 4.103vw;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const InsideDropContainer = styled.div`
  padding: 10.417vw 8.125vw 3.385vw 3.333vw;
`;

export const ListItem = styled(HashLink)`
  display: flex;
  align-items: center;
  gap: 0.521vw;
  cursor: pointer;
`;

export const TargetLink = styled(HashLink)``;

export const CartButton = styled.div`
  display: flex;
  gap: 0.313vw;
  cursor: pointer;
`;
export const CartSummary = styled.div`
  color: ${(props) => (props.whiteTheme ? "#FFF" : "#000")};
  background-color: ${(props) => (props.whiteTheme ? "#000" : "#FFF")};
  border-radius: 1.042vw;
  padding: 0 0.417vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1280px) {
    padding: 0 0.7vw;
  }
`;

export const BuyText = styled(Small)`
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;

export const CartBlock = styled.div`
  display: flex;
  gap: 0.313vw;
  @media (max-width: 1280px) {
    gap: 0.547vw;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  background-color: #f2f2f2;
  padding: 0 3.385vw;
  height: 6.576vw;
  display: ${(props) => (props.none ? "none" : "flex")};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 5;
  &.first {
    background-color: #fff;
  }
  &.drop {
    background-color: #000;
  }
  &.game-header {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    background-color: ${(props) =>
      // eslint-disable-next-line no-nested-ternary
      props.transparent ? "transparent" : props.bc ? "#f2f2f2" : "#000"};
  }
  @media (max-width: 1280px) {
    height: 8.045vw;
    padding: 0 3.516vw;
  }
  @media (max-width: 768px) {
    height: 18.805vw;
    padding: 0 4.103vw;
  }
`;

export const HeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 3.177vw;
  @media (max-width: 1280px) {
    gap: 3.125vw;
  }
`;

export const HeaderRightSide = styled.div`
  top: 0;
  right: 0;
  padding: 0 3.385vw;
  height: 6.576vw;
  position: absolute;
  &.fixed {
    position: fixed;
  }
  z-index: 6;
  right: 0;
  display: ${(props) => (props.none ? "none" : "flex")};
  align-items: center;
  gap: 2.083vw;
  @media (max-width: 1280px) {
    height: 8.045vw;
    padding: 0 3.516vw;
    gap: 2.5vw;
  }
  @media (max-width: 768px) {
    height: 18.805vw;
    padding: 0 4.103vw;
    gap: 5.128vw;
  }
`;

export const Logo = styled.img`
  width: 6.458vw;
  height: 2.097vw;
  cursor: pointer;
  @media (max-width: 1280px) {
    width: 8.203vw;
    height: 2.663vw;
  }
  @media (max-width: 768px) {
    width: 23.077vw;
    height: 7.492vw;
  }
`;

export const LogoImg = styled.img`
  width: 6.458vw;
  height: 2.097vw;
  cursor: pointer;
  @media (max-width: 1280px) {
    width: 8.203vw;
    height: 2.663vw;
  }
  @media (max-width: 768px) {
    width: 23.077vw;
    height: 7.492vw;
  }
`;
export const Nav = styled.div`
  display: flex;
  gap: 2.083vw;
  @media (max-width: 1280px) {
    gap: 2.344vw;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Dot = styled.div`
  width: 0.625vw;
  height: 0.625vw;
  border-radius: 50%;
  @media (max-width: 1280px) {
    width: 0.703vw;
    height: 0.703vw;
  }
  @media (max-width: 768px) {
    width: 2.308vw;
    height: 2.308vw;
    ${"" /* margin-bottom: 0.2vw; */}
  }
`;

export const NavElement = styled.div`
  display: flex;
  align-items: center;
  gap: 0.521vw;
  cursor: pointer;
  transition: all 100ms ease-in;
  ${Dot} {
    background-image: url(${(props) => (props.whiteTheme ? init : initW)});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &:hover ${Dot} {
    transition: all 100ms ease-out;
    transform: scale(1.02);
    background-image: url(${(props) => (props.whiteTheme ? hover : hoverW)});
  }
  &.active ${Dot} {
    transition: all 100ms ease-out;
    transform: scale(1.02);
    background-image: url(${(props) => (props.whiteTheme ? active : activeW)});
  }
  &.activecart ${Dot} {
    transition: all 100ms ease-out;
    transform: scale(1.02);
    background-image: url(${(props) => (props.whiteTheme ? active : activeW)});
  }

  @media (max-width: 768px) {
    display: none;
    &.cart {
      gap: 1.282vw;
      display: flex;
      ${Dot} {
        background-image: url(${(props) => (props.whiteTheme ? init : initW)});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      &:hover ${Dot} {
        transition: all 100ms ease-out;
        transform: scale(1.02);
        background-image: url(${(props) =>
          props.whiteTheme ? hover : hoverW});
      }
    }
    &.activecart {
      gap: 1.282vw;
      display: flex;
      ${Dot} {
        transition: all 100ms ease-out;
        transform: scale(1.02);
        background-image: url(${(props) =>
          props.whiteTheme ? active : activeW});
      }
    }
  }
`;

// export const Dot = styled.div`
//   width: 0.59vw;
//   height: 0.59vw;
//   border: 0.052vw solid ${(props) => (props.whiteTheme ? "#000" : "#FFF")};
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   @media (max-width: 1280px) {
//     width: 0.859vw;
//     height: 0.859vw;
//     border: 0.078vw solid ${(props) => (props.whiteTheme ? "#000" : "#FFF")};
//   }
//   @media (max-width: 768px) {
//     width: 2.308vw;
//     height: 2.308vw;
//     border: 0.256vw solid ${(props) => (props.whiteTheme ? "#000" : "#FFF")};
//   }
// `;

export const ActiveInside = styled.div`
  height: 0.313vw;
  width: 0.313vw;
  border-radius: 50%;
  &.active {
    background-color: #000000;
  }
  &.active_white {
    background-color: #ffffff;
  }
  @media (max-width: 768px) {
    height: 1.2vw;
    width: 1.2vw;
  }
`;

export const ShineButton = styled.button`
  position: relative;
  cursor: pointer;
  background-color: transparent;
  width: 3.402vw;
  height: 2.188vw;
  border-radius: 0.521vw;
  background-color: ${(props) => !props.game && "#F2530F"};
  transition: all 300ms ease-out;
  &:hover {
    transition: all 300ms ease-out;
    background-color: ${(props) => !props.game && "#FF6F32"};
  }
  :disabled {
    background-color: rgba(0, 0, 0, 0.4);
    cursor: not-allowed;
  }
  &.black {
    background-color: #000;
  }
  @media (max-width: 1280px) {
    width: 4vw;
    height: 2.813vw;
    border-radius: 0.681vw;
  }
  @media (max-width: 768px) {
    width: 11.5vw;
    height: 8vw;
    border-radius: 1.481vw;
  }
`;

export const Shine = styled.img`
  width: 3.802vw;
  height: 2.188vw;
  border-radius: 0.521vw;
  @media (max-width: 1280px) {
    width: 4vw;
    height: 2.813vw;
    border-radius: 0.781vw;
  }
`;

export const LoginButton = styled.button`
  box-sizing: border-box;
  width: 8.75vw;
  height: 2.24vw;
  /* border: 0.052vw solid #000000; */
  border-radius: 0.521vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.052vw solid ${(props) => (props.whiteTheme ? "#000" : "#fff")};
  background-color: transparent;
  @media (max-width: 1280px) {
    width: 9.375vw;
    height: 2.813vw;
    border-radius: 0.781vw;
  }
`;

export const ProfileButton = styled.button`
  box-sizing: border-box;
  width: 8.75vw;
  height: 2.24vw;
  border-radius: 0.521vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.052vw solid ${(props) => (props.whiteTheme ? "#000" : "#fff")};
  background-color: transparent;
  &.logout {
    border: 0.052vw solid #ff434f;
  }
  @media (max-width: 1280px) {
    width: 9.375vw;
    height: 2.813vw;
    border-radius: 0.781vw;
    border: ${(props) => !props.whiteTheme && "0.078vw solid #fff"};
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 0.521vw;

  @media (max-width: 1280px) {
    gap: 0.781vw;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const RightElements = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 2.051vw;
  }
`;

export const StyledBuy = styled(Medium)`
  font-size: 7.554vw;
  line-height: 7.177vw;
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavButton = styled.button`
  display: none;
  box-sizing: border-box;
  width: 8.974vw;
  height: 8.974vw;
  background-color: ${(props) => (!props.isOpen ? "#ffffff" : "#f2f2f2")};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  ${"" /* border: ${(props) => !props.isOpen && "0.256vw solid #555"}; */}
  touch-action: none;
  &.black {
    border: ${(props) => !props.isOpen && "0.256vw solid #FFF"};
    background-color: ${(props) => (!props.isOpen ? "#000" : "#000")};
  }
  @media (max-width: 768px) {
    display: block;
  }
`;

export const MobBreedingButton = styled.button`
  display: none;
  box-sizing: border-box;
  width: 20.256vw;
  height: 8.974vw;
  background-color: ${(props) => (!props.whiteTheme ? "#000" : "#FFF")};
  border-radius: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => !props.whiteTheme && "0.256vw solid #fff"};
  @media (max-width: 768px) {
    display: ${(props) => (!props.isOpen ? "block" : "none")};
  }
`;

export const NavPlus = styled.img`
  height: 6.154vw;
  width: 6.154vw;
  transform: ${(props) => props.isOpen && "rotate(135deg)"};
  transition: 1s;
`;

export const BlockMarquee = styled.div`
  display: flex;
  align-items: center;
  gap: 1.042vw;
  padding: 0vw 0.521vw;
`;

export const Text = styled(Small)`
  color: #ffffff;
  font-weight: 500;
  text-align: center;
`;

export const IconElement = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 2.24vw;
  width: 2.24vw;
  border-radius: 50%; */
  /* background: #121212; */

  &:hover path {
    fill: ${(props) => props.fill};
  }

  @media (max-width: 600px) {
    height: 9.38vw;
    width: 9.38vw;
  }
`;

export const ButtonsMedia = styled.div`
  display: flex;
  gap: 1.241vw;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.26vw;
  &.onlymob {
    display: none;
  }
  @media (max-width: 768px) {
    &.onlymob {
      display: flex;
    }
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 7vw;
    padding: 3.846vw 0 12.821vw 0;
    row-gap: 3.846vw;
    border-top: ${(props) =>
      props.whiteTheme ? "0.256vw solid #000" : "0.256vw solid #FFF"};
  }
`;

export const SocLink = styled.p`
  color: ${(props) => props.c};
`;

export const SocLinkA = styled.a`
  color: ${(props) => props.c};
`;

export const FlexMailBlocks = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: flex-start;
    column-gap: 26vw;
    padding-top: 3.846vw;
    border-top: ${(props) =>
      props.whiteTheme ? "0.256vw solid #000" : "0.256vw solid #FFF"};
  }
`;

export const MailBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.396vw;
  @media (max-width: 768px) {
    gap: 7.692vw;
  }
`;
export const MailBlockTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.521vw;
  @media (max-width: 768px) {
    gap: 1.282vw;
  }
`;

export const Socials = styled.div`
  border-bottom: 0.256vw solid
    ${(props) => (props.whiteTheme ? "#000" : "#FFF")};
  padding-bottom: 1.282vw;
`;

export const BurgerButton = styled.button`
  background-color: transparent;
  width: 1.875vw;
  align-items: center;
  @media (max-width: 768px) {
    width: 7.692vw;
  }
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 39vw;
  background-color: ${(props) => (props.whiteTheme ? "#fff" : "#000")};
  height: 100vh;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: 0.3s;
  -webkit-box-shadow: -0.052vw 0px 0.2vw 0px rgba(130, 130, 130, 0.5);
  -moz-box-shadow: -0.052vw 0px 0.2vw 0px rgba(130, 130, 130, 0.5);
  box-shadow: -0.052vw 0px 0.2vw 0px rgba(130, 130, 130, 0.5);
  &.active {
    transition: 0.3s;
    transform: translateX(0%);
  }
  padding: 10.417vw 8.125vw 3.385vw 3.333vw;

  @media (max-width: 1280px) {
    padding: 11.719vw 8.125vw 3.385vw 3.333vw;
    width: 46.8vw;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    background-color: ${(props) => (props.whiteTheme ? "#fff" : "#000")};
    justify-content: flex-end;
    padding: 0vw 4.103vw 12.821vw 4.103vw;
  }
`;

export const FlexEl = styled.div`
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateX(1.042vw);
  }
  @media (max-width: 768px) {
    &:hover {
      transform: none;
    }
    width: 42vw;
    display: flex;
    gap: 2.564vw;
    align-items: center;
  }
`;
export const LargeTab = styled(Large)`
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 8.974vw;
    line-height: 8.462vw;
  }
`;

export const DropDot = styled.div`
  width: 6.41vw;
  height: 6.41vw;
  border: 0.256vw solid ${(props) => (props.whiteTheme ? "#000" : "#FFF")};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    background-color: #000000;
  }
  &.active_white {
    background-color: #ffffff;
  }
`;
