import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import greyArrow from "../assets/images/cart/grey_arrow.png";
import { TextInput } from "../common/components/input/Input";
import { StringSelect } from "../common/components/select/Select";
import { Small } from "../common/elements/Text";
import { dateFormat } from "../common/helperFunc";
import { LeftPanelRoomsSup } from "../components/support/LeftPanelRoomsSup";
import { RightPanelChatSup } from "../components/support/RightPanelChatSup";
import {
  changeOrderThunk,
  filterOrdersIsDevice,
  getSupportDevicesThunk,
  setActiveOrder,
} from "../features/support/supportSlice";
import { useForm } from "../hooks/useForm";
import { AdminCol, ScrollCol } from "./AdminDelivery";
import { AdminContainer, DropParams } from "./AdminDrop";
import { Checkbox } from "../common/components/input/Checkbox";
import { Input } from "../common/elements/Inputs";

const statuses = [
  "IN_PROCESSING",
  "DEVICE_ASSEMBLY",
  "PREPARED_FOR_DELIVERY",
  "IN_DELIVERY",
  "DELIVERED",
];

export const FlexButtons = styled.div`
  display: flex;
  margin: 1vw 0;
  justify-content: space-between;
`;

export const ActionButton = styled.button`
  width: 49%;
  height: 2vw;
  background-color: #f2530f;
  border-radius: 1vw;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  &.create {
    background-color: black;
    width: 49%;
    height: 2vw;
  }
  &.cancel {
    background-color: transparent;
    border: 1px solid #000;
    width: 49%;
    height: 2vw;
    color: #000;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SelectArrow = styled.img`
  cursor: pointer;
  height: 0.7vw;
  width: 1.3vw;

  transform: translate(0, -50%);
  &.abs_active {
    transform: rotate(180deg);
    transform-origin: 50% 25%;
    transition: 0.3s;
  }
  &.abs {
    transition: 0.3s;
  }
  @media (max-width: 768px) {
    height: 2vw;
    width: 3.9vw;
    right: 5.094vw;
  }
`;

export const OrderCard = styled.div`
  height: 9vw;
  min-height: 9vw;
  background-color: #fff;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  padding: 1.7vw 2vw;
  gap: 1vw;
  overflow: hidden;

  &.active {
    min-height: 39.5vw;
    border: 0.052vw solid #000;
  }
`;

const Line = styled.div`
  border-top: 0.052vw solid #000;
  padding-top: 1vw;
`;

export const SupportOrders = ({ status }) => {
  const [orderStatus, setOrderStatus] = useState("");
  const [isDevice, setisDevice] = useState(false);
  const { values, setValues, onChange } = useForm();
  const orders = useSelector((state) => state.support.orders);
  const activeOrder = useSelector((state) => state.support.activeOrder);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const results = !search
    ? orders
    : orders.filter((person) =>
        person.order.id.toLowerCase().includes(search.toLocaleLowerCase()),
      );

  useEffect(() => {
    if (status) {
      dispatch(getSupportDevicesThunk());
    }
  }, [status]);

  const setOrder = (d) => {
    dispatch(setActiveOrder(d));
    if (d) {
      setValues({
        trackNumber: d.order.trackNumber,
        expectedDeliveryDate: d.order.expectedDeliveryDate,
      });
      setOrderStatus(d.order.orderStatus);
    } else {
      setValues({});
      setOrderStatus("");
    }
  };

  const editOrder = async () => {
    await dispatch(
      changeOrderThunk({
        orderId: activeOrder.order.id,
        obj: {
          ...(activeOrder.order.trackNumber !== values?.trackNumber && {
            trackNumber: values?.trackNumber,
          }),
          ...(orderStatus !== activeOrder.order.orderStatus && { orderStatus }),
          ...(values.expectedDeliveryDate !==
            activeOrder.order.expectedDeliveryDate && {
            expectedDeliveryDate: values.expectedDeliveryDate,
          }),
        },
      }),
    );
    if (orderStatus !== activeOrder.order.orderStatus) {
      dispatch(setActiveOrder());
      setValues({});
      setOrderStatus("");
    }
  };

  const onChangeIsDevice = () => {
    if (isDevice) {
      dispatch(filterOrdersIsDevice());
      setisDevice(false);
    } else {
      dispatch(getSupportDevicesThunk({ orderStatus: status }));
      setisDevice(true);
    }
  };

  const onChangeSearch = (search) => {
    setSearch(search);
  };

  if (orders.length === 0) {
    return null;
  }

  return (
    <AdminContainer className="support">
      <AdminCol>
        <ScrollCol>
          <div style={{ display: "flex", gap: "5vw" }}>
            <Checkbox checked={isDevice} onChange={() => onChangeIsDevice()} />
            <Input
              value={search}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
            />
          </div>
          {results.map((d) => (
            <OrderCard
              key={d.order.id}
              className={activeOrder?.order.id === d.order.id ? "active" : null}
            >
              <DropParams>
                <Small>
                  Created on: {new Date(d.order.createdOn).toLocaleString()}
                </Small>
                <Small>
                  Id: {d.order.id.slice(0, 4)}...{d.order.id.slice(-4)}
                </Small>
                <Small>Payment type: {d.order.paymentType}</Small>
                <ArrowContainer>
                  <Small>Delivery method: {d.order.deliveryMethod}</Small>
                  <SelectArrow
                    onClick={() =>
                      activeOrder?.order.id !== d.order.id
                        ? setOrder(d)
                        : setOrder()
                    }
                    src={greyArrow}
                    className={
                      activeOrder?.order.id === d.order.id
                        ? "abs_active"
                        : "abs"
                    }
                    alt="grey_arrow"
                    isOpen={activeOrder?.order.id === d.order.id}
                  />
                </ArrowContainer>

                <br />
                <Line />
                {activeOrder?.order.id === d.order.id ? (
                  <>
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.3vw",
                      }}
                    >
                      <StringSelect
                        label="Order status"
                        values={statuses}
                        brc="rgba(0, 0, 0, 0.15)"
                        bc="#FFF"
                        value={orderStatus}
                        setValue={(value) => setOrderStatus(value)}
                      />
                      <TextInput
                        name="trackNumber"
                        type="text"
                        value={values?.trackNumber || ""}
                        onChange={onChange}
                        label="Track number"
                        brc="rgba(0, 0, 0, 0.15)"
                        bc="#FFF"
                      />
                      <TextInput
                        name="expectedDeliveryDate"
                        type="datetime-local"
                        value={
                          values?.expectedDeliveryDate
                            ? dateFormat(values?.expectedDeliveryDate)
                            : ""
                        }
                        onChange={onChange}
                        label="Expected delivery date"
                        brc="rgba(0, 0, 0, 0.15)"
                        bc="#FFF"
                      />
                    </form>
                    {(values?.expectedDeliveryDate !==
                      d.order.expectedDeliveryDate ||
                      values?.trackNumber !== d.order.trackNumber ||
                      orderStatus !== d.order.orderStatus) && (
                      <FlexButtons>
                        <ActionButton
                          onClick={() => setOrder(d)}
                          className="cancel"
                        >
                          Cancel
                        </ActionButton>
                        <ActionButton
                          onClick={() => editOrder()}
                          className="create"
                          type="button"
                        >
                          Save
                        </ActionButton>
                      </FlexButtons>
                    )}
                  </>
                ) : (
                  <>
                    <Small>Order status: {d.order?.orderStatus}</Small>
                    <Small>Track number: {d.order?.trackNumber}</Small>
                    <Small>
                      Expected delivery date:{" "}
                      {d.order.expectedDeliveryDate
                        ? dateFormat(d.order.expectedDeliveryDate)
                        : ""}
                    </Small>
                  </>
                )}
                <br />
                <Small>First name: {d.order.firstName}</Small>
                <Small>Last name: {d.order.lastName}</Small>
                <Small>Region: {d.order.country}</Small>
                <Small>City: {d.order.city}</Small>
                <Small>PostalCode: {d.order.postalCode}</Small>
                <Small>Address: {d.order.address}</Small>
                <Small>Additional address: {d.order.additionalAddress}</Small>
                <br />
                <Small>Email: {d.order.email}</Small>
                <Small>Phone: {d.order.phone}</Small>
              </DropParams>
            </OrderCard>
          ))}
        </ScrollCol>
      </AdminCol>
      <div
        style={{
          display: "flex",
          width: "75%",
          gap: "1vw",
        }}
      >
        <LeftPanelRoomsSup />
        <RightPanelChatSup />
      </div>
    </AdminContainer>
  );
};
