import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { delNotif } from "../../../features/notification/notificationSlice";
import { useTimeout } from "../../../hooks/use-timeout";
import { useIsAdaptive } from "../../../hooks/useIsAdaptive";
import { Small } from "../../elements/Text";

const color = (type) => {
  if (type === "complete") {
    return "#26D242";
  }
  if (type === "warning") {
    return "#F2530F";
  }
  if (type === "cancel") {
    return "#FF434F";
  }
  return null;
};

const CloseIcon = ({ type, s, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={s}
    height={s}
    viewBox="0 0 19 19"
    fill="none"
    onClick={onClick}
  >
    <rect
      x="0.707031"
      width="25"
      height="1"
      transform="rotate(45 0.707031 0)"
      fill={color(type)}
    />
    <rect
      x="18.3848"
      y="0.707031"
      width="25"
      height="0.999998"
      transform="rotate(135 18.3848 0.707031)"
      fill={color(type)}
    />
  </svg>
);

export const NotificationContainer = styled.div`
  position: fixed;
  z-index: 999999;
  top: 6.576vw;
  right: 3.385vw;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  width: 22.917vw;
  @media (max-width: 1280px) {
    width: 22.656vw;
  }
  @media (max-width: 768px) {
    top: auto;
    bottom: 2vw;
    right: 4.5vw;
    left: 4.5vw;
    width: 91vw;
  }
`;

export const NotificationStyle = styled.div`
  background: ${(props) => (props.whiteTheme ? "#fff" : "#000")};
  transition: 0.6s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin-bottom: 0.521vw;
  color: #fff;
  display: flex;
  align-items: flex-start;
  border-radius: 0.521vw;
  justify-content: space-between;
  border: 0.052vw solid ${(props) => props.color};
  width: 22.917vw;
  padding: 1.042vw;

  @media (max-width: 1280px) {
    width: 22.656vw;
    padding: 1.172vw;
    margin-bottom: 0.781vw;
    border-radius: 0.781vw;
    border: 0.078vw solid ${(props) => props.color};
  }

  @media (max-width: 768px) {
    width: 91vw;
    border: 0.256vw solid ${(props) => props.color};
    border-radius: 2.051vw;
    margin-bottom: 2.564vw;
    padding: 3.077vw;
  }
`;

export const AbsoluteIcon = styled.div`
  position: absolute;
  right: 1.2vw;
  top: 1.2vw;
  cursor: pointer;
  @media (max-width: 1280px) {
    right: 1.4vw;
    top: 1.4vw;
  }
  @media (max-width: 768px) {
    right: 3.8vw;
    top: 3.8vw;
  }
`;

export const Img = styled.img`
  height: 1.26vw;
  width: 1.26vw;
  @media (max-width: 768px) {
    height: 18.5px;
    width: 18.5px;
  }
`;

export const NotificationMessage = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.85vw;
  line-height: 140%;
  color: ${(props) => props.color};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const NotificationTextContainer = styled.div`
  width: 18.5vw;
  @media (max-width: 768px) {
    width: 80.7vw;
  }
`;

const ToastItem = ({ toast, deleteToast, whiteTheme }) => {
  useTimeout(deleteToast, 3500);
  const adpt = useIsAdaptive();

  return (
    <NotificationStyle whiteTheme={whiteTheme} color={color(toast.type)}>
      <NotificationTextContainer>
        <Small c={color(toast.type)}>
          {toast.text ? toast.text : "Internal Error"}
        </Small>
      </NotificationTextContainer>
      <AbsoluteIcon onClick={deleteToast}>
        <CloseIcon
          // eslint-disable-next-line no-nested-ternary
          s={adpt === "pc" ? "0.938vw" : adpt === "mob" ? "2.821vw" : "0.938vw"}
          type={toast.type}
        />
      </AbsoluteIcon>
    </NotificationStyle>
  );
};

export const Notification = ({ whiteTheme }) => {
  const notifications = useSelector(
    (state) => state.notification.notifications,
  );
  const dispatch = useDispatch();

  const deleteToast = (id) => {
    dispatch(delNotif(id));
  };

  if (notifications.length > 0) {
    return (
      <NotificationContainer>
        {notifications.map((toast) => (
          <ToastItem
            whiteTheme={whiteTheme}
            key={toast.id}
            toast={toast}
            deleteToast={() => deleteToast(toast.id)}
          />
        ))}
      </NotificationContainer>
    );
  }

  return null;
};
