import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextInput } from "../common/components/input/Input";
import { Medium, Small } from "../common/elements/Text";
import {
  addDeviceThunk,
  addEquipmentThunk,
  deleteDeviceThunk,
  deleteEquipmentThunk,
  editDeviceThunk,
  editEquipmentThunk,
  getDevicesThunk,
} from "../features/admin/adminSlice";
import { useForm } from "../hooks/useForm";
import {
  ActionButton,
  AdminContainer,
  DropCard,
  DropParams,
  DropsScroll,
  FlexButtons,
  FlexEquipment,
  Form,
  LeftSide,
  RightSide,
} from "./AdminDrop";

export const AdminProduct = () => {
  // device obj
  const [current, setCurrent] = useState();
  // new equipment
  const [photo, setPhoto] = useState();
  const [name, setName] = useState("");
  const [order, setOrder] = useState();
  // editable equipment
  const [currentEquipment, setCurrentEquipment] = useState();
  const [currentPhoto, setCurrentPhoto] = useState();
  const [currentName, setCurrentName] = useState("");
  const [currentOrder, setCurrentOrder] = useState();
  // device form
  const { values, onChange } = useForm();
  const devices = useSelector((state) => state.admin.devices);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDevicesThunk());
  }, []);

  useEffect(() => {
    if (current) {
      // в текущий изменяемый девайс обновляем значения
      setCurrent(devices.find((d) => d.id === current.id));
    }
  }, [devices]);

  const addDevice = () => {
    dispatch(addDeviceThunk({ values, technical: [] }));
  };

  const editDevice = () => {
    dispatch(editDeviceThunk({ id: current.id, values }));
  };

  const deleteDevice = () => {
    dispatch(deleteDeviceThunk({ id: current.id }));
  };

  const addEquipment = () => {
    dispatch(
      addEquipmentThunk({
        id: current.id,
        name,
        photo,
        order: order || current.equipment.length + 1,
      }),
    );
    setPhoto();
    setName("");
  };

  const editEquipment = () => {
    dispatch(
      editEquipmentThunk({
        deviceId: current.id,
        equipmentId: currentEquipment.id,
        name: currentName || undefined,
        photo: currentPhoto || undefined,
        order: currentOrder || undefined,
      }),
    );
    setPhoto();
    setName("");
    setCurrentEquipment();
    setCurrentOrder();
  };

  const deleteEquipment = (id) => {
    dispatch(deleteEquipmentThunk({ id }));
  };

  const setCurrentFunc = (d) => {
    setCurrent(d);
  };

  return (
    <AdminContainer>
      <LeftSide>
        {current ? (
          <>
            <Medium>
              Action with device:
              <br />
              <br /> id: {current.id}
              <br />
              name: {current.name}
            </Medium>
            <Medium c="red">*Fill only changeable field everywhere*</Medium>
          </>
        ) : (
          <Medium>New device:</Medium>
        )}
        <Form>
          <TextInput
            name="id"
            type="text"
            value={values?.id || ""}
            onChange={onChange}
            label="Id from Xsolla payments"
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
          <TextInput
            name="name"
            type="text"
            value={values?.name || ""}
            onChange={onChange}
            label="Name"
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
          <TextInput
            name="price"
            type="text"
            value={values?.price || ""}
            onChange={onChange}
            label="Price"
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
          <TextInput
            name="description"
            type="text"
            value={values?.description || ""}
            onChange={onChange}
            label="Description"
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
          <FlexButtons>
            {current && (
              <ActionButton onClick={() => setCurrent()} className="cancel">
                Cancel
              </ActionButton>
            )}
            {current ? (
              <ActionButton
                onClick={() => editDevice()}
                className="create"
                type="button"
              >
                Save
              </ActionButton>
            ) : (
              <ActionButton
                onClick={() => addDevice()}
                className="create"
                type="button"
              >
                Create new
              </ActionButton>
            )}
          </FlexButtons>
          {current && (
            <div style={{ borderTop: "0.052vw solid #000", paddingTop: "1vw" }}>
              <Medium>Equipment list:</Medium>
              {current.equipment.map((eq) => (
                <>
                  <FlexEquipment>
                    <Small>{eq.order}</Small>
                    <Small>{eq.name}</Small>
                    <img src={eq.photo} style={{ height: "2vw" }} alt={eq.id} />
                    <Small
                      c="#F2530F"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCurrentEquipment(eq);
                        setCurrentOrder(eq.order);
                      }}
                    >
                      Edit
                    </Small>
                    <Small
                      c="red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteEquipment(eq.id)}
                    >
                      Delete
                    </Small>
                  </FlexEquipment>
                  {currentEquipment?.id === eq.id && (
                    <>
                      <input
                        type="file"
                        onChange={({ target }) =>
                          setCurrentPhoto(target.files[0])
                        }
                      />
                      <TextInput
                        name="name"
                        type="text"
                        value={currentName}
                        onChange={({ target }) => setCurrentName(target.value)}
                        label="Name"
                        w="100%"
                        brc="rgba(0, 0, 0, 0.15)"
                        bc="#FFF"
                      />
                      <TextInput
                        name="order"
                        type="text"
                        value={currentOrder}
                        onChange={({ target }) => setCurrentOrder(target.value)}
                        label="Order"
                        w="100%"
                        brc="rgba(0, 0, 0, 0.15)"
                        bc="#FFF"
                      />
                      <FlexButtons>
                        <ActionButton
                          onClick={() => editEquipment()}
                          type="button"
                        >
                          Edit equipment
                        </ActionButton>
                        <ActionButton
                          onClick={() => setCurrentEquipment()}
                          type="button"
                        >
                          Cancel
                        </ActionButton>
                      </FlexButtons>
                    </>
                  )}
                </>
              ))}
              <Medium style={{ marginTop: "1vw" }}>Add new equipment:</Medium>
              <input
                type="file"
                onChange={({ target }) => setPhoto(target.files[0])}
              />
              <TextInput
                name="name"
                type="text"
                value={name}
                onChange={({ target }) => setName(target.value)}
                label="Name"
                w="100%"
                brc="rgba(0, 0, 0, 0.15)"
                bc="#FFF"
              />
              <TextInput
                name="order"
                type="text"
                value={order || current.equipment.length + 1}
                onChange={({ target }) => setOrder(target.value)}
                label="Order"
                w="100%"
                brc="rgba(0, 0, 0, 0.15)"
                bc="#FFF"
              />
              <FlexButtons>
                <ActionButton
                  type="button"
                  onClick={() => {
                    setPhoto();
                    setName("");
                    setOrder(current.equipment.length + 1);
                  }}
                  className="cancel"
                >
                  Cancel
                </ActionButton>
                <ActionButton
                  onClick={() => addEquipment()}
                  type="button"
                  className="create"
                >
                  Add
                </ActionButton>
              </FlexButtons>
              <ActionButton
                onClick={() => deleteDevice()}
                className="delete"
                type="button"
              >
                Delete device
              </ActionButton>
            </div>
          )}
        </Form>
      </LeftSide>
      <RightSide>
        <DropsScroll>
          {devices.map((d) => (
            <DropCard>
              <Small
                style={{ textAlign: "right", cursor: "pointer" }}
                c="#F2530F"
                onClick={() => setCurrentFunc(d)}
              >
                EDIT
              </Small>

              <DropParams>
                <Small>id: {d.id}</Small>
                <Small>name: {d.name}</Small>
                <Small>price: {d.price}</Small>
                <Small>description: {d.description.slice(0, 25)}...</Small>
                <Small>
                  equipment: {d.equipment.map((e) => `${e.name}, `)}
                </Small>
              </DropParams>
            </DropCard>
          ))}
        </DropsScroll>
      </RightSide>
    </AdminContainer>
  );
};
