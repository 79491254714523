import { useState } from "react";
import {
  ArrowDown,
  ArrowUp,
  Block,
  BlockProfile,
  Container,
  ContainerProfile,
  Minus,
  Plus,
  Text,
  TextProfile,
  TouchableOpacity,
  TouchableOpacityProfile,
} from "./DropDownBlockStyle";
import plus from "../../../assets/images/profile/plus.png";
import minus from "../../../assets/images/profile/minus.png";
import arrowDown from "../../../assets/images/profile/arrowDown.png";
import arrowUp from "../../../assets/images/profile/arrowUp.png";

export const DropDownBlock = ({ label, children, icon = "default" }) => {
  const [visible, setVisible] = useState(false);

  const toggleDropdown = () => {
    setVisible(!visible);
  };

  const renderDropdown = () => {
    if (visible) {
      return <Block>{children}</Block>;
    }
    return null;
  };

  const rulesIcon = (isOpen) => {
    if (isOpen) {
      if (icon !== "default") {
        return <ArrowUp src={arrowUp} />;
      }
      return <Minus src={minus} />;
    }
    if (icon !== "default") {
      return <ArrowDown src={arrowDown} />;
    }
    return <Plus src={plus} />;
  };

  return (
    <Container visible={!visible}>
      <TouchableOpacity visible={!visible} onClick={toggleDropdown}>
        <Text>{label}</Text>
        {visible ? rulesIcon(true) : rulesIcon()}
      </TouchableOpacity>
      {renderDropdown()}
    </Container>
  );
};

export const DropDownProfile = ({ label, children }) => {
  const [visible] = useState(true);

  const renderDropdown = () => {
    if (visible) {
      return <BlockProfile>{children}</BlockProfile>;
    }
    return null;
  };

  return (
    <ContainerProfile visible={!visible}>
      <TouchableOpacityProfile visible={!visible}>
        <TextProfile>{label}</TextProfile>
        {visible ? <ArrowUp src={arrowUp} /> : <ArrowDown src={arrowDown} />}
      </TouchableOpacityProfile>
      {renderDropdown()}
    </ContainerProfile>
  );
};
