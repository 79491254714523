import styled from "styled-components";

export const ClaimContainer = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 25vw;
  }
`;

export const ClaimTokens = styled.div`
  border: 0.052vw solid rgba(255, 255, 255, 0.3);
  border-radius: 1.042vw;
  height: 19.792vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &.no {
    ${"" /* border: none; */}
  }
  @media (max-width: 768px) {
    height: 94.872vw;
    border-radius: 3.4vw;
    border: 0.256vw solid rgba(255, 255, 255, 0.3);
  }
`;

export const SpanBlue = styled.a`
  color: #f2530f;
  cursor: pointer;
  text-decoration: underline;
  transition: all 300ms ease-in;
  :hover {
    transition: all 300ms ease-out;
    color: #ff6f32;
    text-decoration-color: underline;
  }
`;

export const TokenCards = styled.div`
  width: 46.354vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  gap: 0.521vw;
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 2.8vw;
  }
`;

export const TokenCard = styled.div`
  width: 15.104vw;
  height: 19.792vw;
  border-radius: 1.042vw;
  box-sizing: border-box;
  border: 0.052vw solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
  :hover {
    border: 0.052vw solid rgba(255, 255, 255, 0.3);
  }
  &.active {
    border: 0.052vw solid rgba(255, 255, 255, 1);
  }
  background-color: ${(props) => (props.white ? "#fff" : "#000")};
  @media (max-width: 768px) {
    width: 44.872vw;
    height: 61.874vw;
    border-radius: 2.564vw;
    :hover {
      border: 0.256vw solid rgba(255, 255, 255, 0.3);
    }
    &.active {
      border: 0.256vw solid rgba(255, 255, 255, 1);
    }
  }
`;

export const TokenImage = styled.video`
  width: 100%;
  height: 15.104vw;
  border-radius: 1.042vw;
  @media (max-width: 768px) {
    width: 100%;
    height: 44.872vw;
    border-radius: 2.564vw;
  }
`;

export const FlexName = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.26vw;
  gap: 0.521vw;
  align-items: center;
  @media (max-width: 768px) {
    gap: 1.887vw;
  }
`;

export const DotNotActive = styled.div`
  width: 0.938vw;
  height: 0.938vw;
  box-sizing: border-box;
  border-radius: 50%;
  border: 0.052vw solid ${(props) => (props.white ? "#000" : "#fff")};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 2.7vw;
    height: 2.7vw;
    border: 0.256vw solid ${(props) => (props.white ? "#000" : "#fff")};
  }
`;

export const DotActive = styled.div`
  width: 0.521vw;
  height: 0.521vw;
  border-radius: 50%;
  background-color: ${(props) => (props.white ? "#000" : "#fff")};
  @media (max-width: 768px) {
    width: 1.3vw;
    height: 1.3vw;
  }
`;

export const ClaimLottieContainer = styled.div`
  width: 6vw;
  height: 2vw;
  @media (max-width: 768px) {
    width: 15vw;
    height: 6vw;
  }
`;
