import { Small } from "../../elements/Text";
import { Button, StyledBlueButton, TextButton } from "./ButtonStyle";

export const ButtonLogin = ({
  title,
  children,
  bg,
  action,
  disabled,
  wM,
  className,
  type,
  w,
}) => (
  <Button
    bg={bg}
    disabled={disabled}
    onClick={action}
    w={w}
    wM={wM}
    wallet
    type={type}
    className={className}
  >
    {title ? <TextButton>{title}</TextButton> : children}
  </Button>
);

export const BlueButton = ({ action, disabled }) => (
  <StyledBlueButton onClick={action} disabled={disabled}>
    <Small c="#fff">NOTIFY ME</Small>
  </StyledBlueButton>
);
