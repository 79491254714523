import styled from "styled-components";

export const FieldLabel = styled.label`
  font-family: "medium";
  position: absolute;
  pointer-events: none;
  transform: translate(0, 0.885vw) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${(props) => props.fc || "rgba(0, 0, 0, 0.35)"};
  left: 0.833vw;

  &.filled {
    transform: translate(0, 0.521vw) scale(0.7);
  }

  @media (max-width: 768px) {
    font-size: 3.333vw;
    line-height: 4.667vw;
    left: 3.846vw;
    transform: translate(0, 2.3vw) scale(1);
    top: 0.8vw;
    &.filled {
      transform: translate(0, 0.5vw) scale(0.7);
    }
  }
`;
