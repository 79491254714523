/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { breedingApi } from "../../api/breedingApi";
import { deliveryApi } from "../../api/deliveryApi";
import { catchNotif } from "../notification/notificationSlice";

// это для старых
export const pushDeliveryThunk = createAsyncThunk(
  "/delivery/pushDelivery",
  async (
    {
      country,
      firstName,
      lastName,
      address,
      additionalAddress,
      postalCode,
      phone,
      city,
      email,
      tokenId,
      wallet,
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await deliveryApi.pushDelivery(
        country,
        firstName,
        lastName,
        address,
        additionalAddress,
        postalCode,
        phone,
        city,
        email,
        tokenId,
      );
      breedingApi.claim(tokenId, wallet);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

// это для новых
export const pushOrderThunk = createAsyncThunk(
  "/delivery/pushOrder",
  async (
    {
      country,
      firstName,
      lastName,
      address,
      additionalAddress,
      postalCode,
      phone,
      city,
      email,
      paymentType,
      deliveryMethod,
      orderDevices,
      wallet,
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await deliveryApi.pushOrder(
        country,
        firstName,
        lastName,
        address,
        additionalAddress,
        postalCode,
        phone,
        city,
        email,
        paymentType,
        deliveryMethod,
        orderDevices,
        wallet,
      );
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getCountriesThunk = createAsyncThunk(
  "/delivery/getCountries",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await deliveryApi.getCountries();
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const notifyCountryThunk = createAsyncThunk(
  "/countries/waitlist",
  async ({ country, email }, { dispatch, rejectWithValue }) => {
    try {
      const response = await deliveryApi.notifyCountry(country, email);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const deliverySlice = createSlice({
  name: "delivery",
  initialState: {
    isClaimed: null,
    cart: JSON.parse(localStorage.getItem("cart")) || [],
    countries: null,
    orderId: "",
    token: "",
  },
  reducers: {
    resetClaimed(state) {
      state.isClaimed = null;
    },
    setCart(state, action) {
      state.cart = action.payload;
      state.token = "";
      state.orderId = "";
    },
    resetOrderId(state) {
      state.orderId = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pushOrderThunk.fulfilled, (state, action) => {
      state.orderId = action.payload.pillzOrderId;
    });
    builder.addCase(pushDeliveryThunk.fulfilled, (state, action) => {
      state.isClaimed = action.payload;
    });
    builder.addCase(getCountriesThunk.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
  },
});

export const { resetClaimed, setCart, resetOrderId } = deliverySlice.actions;

export default deliverySlice.reducer;
