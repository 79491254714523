// import { Medium, Small } from "../../common/elements/Text";
import { PrivacyPolicyContainer } from "./PrivacyPolicyStyle";

export const PrivacyPolicy = () => (
  <PrivacyPolicyContainer>
    {/* <ContentContainer>
      <TopContainer>
        <LargePolicy>Privacy Policy</LargePolicy>
        <Medium>Updated 22.05.2023</Medium>
      </TopContainer>
      <BottomContainer>
        <Small>
          An NFT from the PILZZ collection. As a gift, you will receive a device
          and pendants. They will enable you to unlock the full functionality of
          your NFT character, including exclusive access to our signature game.
          * The actual appearance may vary slightlyAn NFT from the PILZZ
          collection. As a gift, you will receive a device and pendants. They
          will enable you to unlock the full functionality of your NFT
          character, including exclusive access to our signature game. * The
          actual appearance may vary slightlyAn NFT from the PILZZ collection.
          As a gift, you will receive a device and pendants. They will enable
          you to unlock the full functionality of your NFT character, including
          exclusive access to our signature game. * The actual appearance may
          vary slightlyAn NFT from the PILZZ collection. As a gift, you will
          receive a device and pendants. They will enable you to unlock the full
          functionality of your NFT character, including exclusive access to our
          signature game. * The actual appearance may vary slightlyAn NFT from
          the PILZZ collection. As a gift, you will receive a device and
          pendants. They will enable you to unlock the full functionality of
          your NFT character, including exclusive access to our signature game.
          * The actual appearance may vary slightlyAn NFT from the PILZZ
          collection. As a gift, you will receive a device and pendants. They
          will enable you to unlock the full functionality of your NFT
          character, including exclusive access to our signature game. * The
          actual appearance may vary slightlyAn NFT from the PILZZ collection.
          As a gift, you will receive a device and pendants. They will enable
          you to unlock the full functionality of your NFT character, including
          exclusive access to our signature game. * The actual appearance may
          vary slightlyAn NFT from the PILZZ collection. As a gift, you will
          receive a device and pendants. They will enable you to unlock the full
          functionality of your NFT character, including exclusive access to our
          signature game. * The actual appearance may vary slightlyAn NFT from
          the PILZZ collection. As a gift, you will receive a device and
          pendants. They will enable you to unlock the full functionality of
          your NFT character, including exclusive access to our signature game.
          * The actual appearance may vary slightly
        </Small>
      </BottomContainer>
    </ContentContainer> */}
  </PrivacyPolicyContainer>
);
