import { $api } from ".";

export const authApi = {
  nonceWallet(address) {
    return $api
      .get("/auth/crypto/nonce", {
        params: { address },
      })
      .then((res) => res);
  },
  otpSend(email) {
    return $api
      .get("/auth/otp/send", {
        params: { email },
      })
      .then((res) => res);
  },
  emailLogin(otp, email) {
    return $api.post("/auth/otp/verify", { otp, email }).then((res) => res);
  },
  walletLogin(signature, publicAddress) {
    return $api
      .post("/auth/crypto/verify", { signature, publicAddress })
      .then((res) => res);
  },
  me() {
    return $api.post("/auth/me").then((res) => res);
  },
  authRefresh() {
    return $api
      .post("/auth/refresh", {
        refreshToken: localStorage.getItem("refreshToken"),
      })
      .then((res) => res);
  },
  emailLoginChangeOtp(otp, email) {
    return $api.put("/profile/email", { otp, email }).then((res) => res);
  },
};
