import styled from "styled-components";

export const CartTitles = styled.div`
  border-bottom: 0.052vw solid ${(props) => props.border || "#000000"};
  display: flex;
  width: 100%;
  padding-bottom: 0.885vw;
  margin-bottom: ${(props) => props.mb};

  @media (max-width: 768px) {
    padding-bottom: 2.538vw;
    border-bottom: 0.256vw solid ${(props) => props.border || "#000000"};
  }
`;

export const CartZone = styled.div`
  width: 100%;
`;

export const DeliveryAndLinkedContainers = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 13.959vw;
    gap: 7.614vw;
    padding-bottom: 32.487vw;
  }
`;
