/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authApi } from "../../api/authApi";
import { profileApi } from "../../api/profileApi";
import { catchNotif } from "../notification/notificationSlice";
import { xsollaInitializeThunk } from "../xsolla/xsollaSlice";

export const nonceThunk = createAsyncThunk(
  "/auth/crypto/nonce",
  async ({ address }, { dispatch, rejectWithValue }) => {
    try {
      const response = await authApi.nonceWallet(address);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const loginWallet = createAsyncThunk(
  "/auth/crypto/verify",
  async ({ signature, publicAddress }, { dispatch, rejectWithValue }) => {
    try {
      const tokens = await authApi.walletLogin(signature, publicAddress);
      localStorage.setItem("accessToken", tokens.data.accessToken);
      localStorage.setItem("refreshToken", tokens.data.refreshToken);
      const response = await profileApi.getProfile();
      dispatch(xsollaInitializeThunk({ updateToken: true }));
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const otpThunk = createAsyncThunk(
  "/auth/email/otp",
  async ({ email }, { dispatch, rejectWithValue }) => {
    try {
      const response = await authApi.otpSend(email);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const loginEmail = createAsyncThunk(
  "/auth/otp/verify",
  async ({ otp, email }, { dispatch, rejectWithValue }) => {
    try {
      const tokens = await authApi.emailLogin(otp, email);
      localStorage.setItem("accessToken", tokens.data.accessToken);
      localStorage.setItem("refreshToken", tokens.data.refreshToken);
      const response = await profileApi.getProfile();
      dispatch(xsollaInitializeThunk({ updateToken: true }));
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const refresh = createAsyncThunk(
  "/auth/refresh",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const tokens = await authApi.authRefresh();
      localStorage.setItem("accessToken", tokens.data.accessToken);
      localStorage.setItem("refreshToken", tokens.data.refreshToken);
      const response = await profileApi.getProfile();
      dispatch(xsollaInitializeThunk({}));
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const addWallet = createAsyncThunk(
  "/profile/addWallet",
  async ({ signature, publicAddress }, { dispatch, rejectWithValue }) => {
    try {
      const response = await profileApi.walletAdd(signature, publicAddress);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const deleteWallet = createAsyncThunk(
  "/profile/deleteWallet",
  async ({ walletAddress }, { dispatch, rejectWithValue }) => {
    try {
      const response = await profileApi.walletDelete(walletAddress);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const initializeApp = createAsyncThunk(
  "initialize app",
  async (_, { dispatch }) => {
    const promise = await dispatch(refresh());
    Promise.all([promise]).then(() => {
      dispatch(setInitialized());
    });
  },
);

export const loginEmailChangeOTP = createAsyncThunk(
  "/auth/ChangeOTP",
  async ({ otp, email }, { dispatch, rejectWithValue }) => {
    try {
      const tokens = await authApi.emailLoginChangeOtp(otp, email);
      localStorage.setItem("accessToken", tokens.data.accessToken);
      localStorage.setItem("refreshToken", tokens.data.refreshToken);
      const response = await profileApi.getProfile();
      dispatch(xsollaInitializeThunk({ updateToken: true }));
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const authSlice = createSlice({
  name: "auth",
  reducers: {
    setInitialized(state) {
      state.isInitialized = true;
    },
    setNoValid(state) {
      state.noValid = false;
    },
    logoutReducer(state) {
      state.user = null;
      state.isAuth = false;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      window.location.reload();
    },
  },
  initialState: {
    user: null,
    isAuth: false,
    isLoading: false,
    isInitialized: false,
    noValid: false,
  },
  extraReducers: (builder) => {
    builder.addCase(loginWallet.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
    });
    builder.addCase(loginEmail.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
    });
    builder.addCase(loginEmailChangeOTP.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
    });
    builder.addCase(refresh.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
    });
    builder.addCase(addWallet.fulfilled, (state, action) => {
      state.user = { ...state.user, wallets: action.payload };
    });
    builder.addCase(deleteWallet.fulfilled, (state, action) => {
      state.user = { ...state.user, wallets: action.payload };
    });
    builder.addCase(refresh.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(refresh.rejected, (state) => {
      state.user = null;
      state.isAuth = false;
      state.isInitialized = true;
    });
    builder.addCase(loginEmail.rejected, (state) => {
      state.noValid = true;
    });
    builder.addCase(loginEmailChangeOTP.rejected, (state) => {
      state.noValid = true;
    });
  },
});

export const { setInitialized, logoutReducer, setNoValid } = authSlice.actions;
export default authSlice.reducer;
