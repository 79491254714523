/* eslint-disable camelcase */
import { $api, $apiXsolla } from ".";

export const PROJECT_ID = "215856";

export const xsollaApi = {
  createToken() {
    return $api.get("/order/token").then((res) => res);
  },
  getCart() {
    return $apiXsolla
      .get(`/project/${PROJECT_ID}/cart?currency=USD&locale=en`)
      .then((res) => res);
  },
  fillCart(items) {
    return $apiXsolla
      .put(`/project/${PROJECT_ID}/cart/fill`, { items })
      .then((res) => res);
  },
  deleteItemCart(cartId, itemSku) {
    return $apiXsolla
      .delete(`/project/${PROJECT_ID}/cart/${cartId}/item/${itemSku}`)
      .then((res) => res);
  },
  redeemPromo(promo, cartId) {
    return $apiXsolla
      .post(`/project/${PROJECT_ID}/promocode/redeem`, {
        coupon_code: promo,
        cart: {
          id: cartId,
        },
      })
      .then((res) => res);
  },
  removePromo(cartId) {
    return $apiXsolla
      .put(`/project/${PROJECT_ID}/promocode/remove`, {
        cart: {
          id: cartId,
        },
      })
      .then((res) => res);
  },
  createOrder(pillzOrderId, payMethod, wallet, token_count, return_url) {
    return $apiXsolla
      .post(`/project/${PROJECT_ID}/payment/cart`, {
        // sandbox: true,
        locale: "en",
        currency: "USD",
        settings: {
          payment_method: payMethod,
          return_url,
          redirect_policy: {
            redirect_conditions: "successful",
          },
        },
        custom_parameters: {
          pillzOrderId,
          wallet,
          token_count,
        },
      })
      .then((res) => res);
  },
};
