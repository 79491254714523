import { $api } from ".";

export const supportApi = {
  getDevices() {
    return $api.get("/support/devices?size=1000").then((res) => res);
  },
  getChatMessages(deviceId, page, size) {
    return $api
      .get(
        `/support/devices/${deviceId}/messages?sort=createdOn,desc&page=${page}&size=${size}`,
      )
      .then((res) => res);
  },
  changeOrder(orderId, obj) {
    return $api.post(`/support/order/${orderId}`, obj);
  },
};
