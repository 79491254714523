import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import i1 from "../../../../assets/images/drop/1st.png";
import i2 from "../../../../assets/images/drop/2nd.png";
import i3 from "../../../../assets/images/drop/3d.png";
import i45 from "../../../../assets/images/drop/45th.png";
import i4 from "../../../../assets/images/drop/4th.png";
// import i5 from "../../../../assets/images/drop/5th.png";
import i8 from "../../../../assets/images/drop/8th.png";
import arrow from "../../../../assets/images/drop/arrow.png";
import { Medium, Small } from "../../../../common/elements/Text";
import {
  ArrowImage,
  Border,
  DropOne,
  DropOneTop,
  GreySpan,
  IncludeCard,
  IncludeImage,
  IncludesAbsolute,
  IncludesBlock,
  IncludesCards,
  MediumD,
  ScrollBlock,
  // SmallD,
  TitleFirst,
} from "../DropStyle";

export const DropFirst = () => {
  const { pathname } = useLocation();
  return (
    <DropOne>
      <DropOneTop>
        <TitleFirst c="#fff">The Pillz pack</TitleFirst>
        {/* <Small c="rgba(255,255,255,0.5)">Only 5000pcs early bird</Small> */}
      </DropOneTop>
      <IncludesBlock>
        <IncludesAbsolute>
          <Medium c="#fff">Includes:</Medium>
        </IncludesAbsolute>
        <Border className="top" />
        <IncludesCards>
          <IncludeCard>
            <HashLink smooth to={`${pathname}#PillzNFT`}>
              <IncludeImage src={i1} />
              <MediumD c="#fff">
                <GreySpan>①</GreySpan> Pillz Device
              </MediumD>
            </HashLink>
            {/* <SmallD c="#26BF3F">Right away</SmallD> */}
          </IncludeCard>
          <IncludeCard>
            <HashLink smooth to={`${pathname}#CellzNFT`}>
              <IncludeImage src={i4} />
              <MediumD c="#fff">
                <GreySpan>②</GreySpan> Charging dock-station
              </MediumD>
            </HashLink>
            {/* <SmallD c="#26BF3F">Right away</SmallD> */}
          </IncludeCard>
          {/* <IncludeCard>
            <HashLink smooth to={`${pathname}#CellzGame`}>
              <IncludeImage src={i2} />
              <MediumD c="#fff">
                <GreySpan>②</GreySpan> Cellz Game
              </MediumD>
            </HashLink> */}
          {/* <SmallD c="rgba(255,255,255,0.5)">Q3 2023</SmallD> */}
          {/* </IncludeCard> */}
          <IncludeCard>
            <HashLink smooth to={`${pathname}#Fittings`}>
              <IncludeImage src={i45} />
              <MediumD c="#fff">
                <GreySpan>③</GreySpan> Fittings x3
              </MediumD>
            </HashLink>
            {/* <SmallD c="rgba(255,255,255,0.5)">Q4 2023</SmallD> */}
          </IncludeCard>
          <IncludeCard>
            <HashLink smooth to={`${pathname}#PillzDevice`}>
              <IncludeImage src={i2} />
              <MediumD c="#fff">
                <GreySpan>④</GreySpan> Mobile game
              </MediumD>
            </HashLink>
            {/* <SmallD c="rgba(255,255,255,0.5)">Q4 2023</SmallD> */}
          </IncludeCard>
          <IncludeCard>
            <HashLink smooth to={`${pathname}#SDK`}>
              <IncludeImage src={i3} />
              <MediumD c="#fff">
                <GreySpan>⑤</GreySpan> Digital pet character
              </MediumD>
            </HashLink>
            {/* <SmallD c="rgba(255,255,255,0.5)">Q1 2024</SmallD> */}
          </IncludeCard>
          <IncludeCard>
            <HashLink smooth to={`${pathname}#OtherGames`}>
              <IncludeImage src={i8} />
              <MediumD c="#fff">
                <GreySpan>⑥</GreySpan> IRL events
              </MediumD>
            </HashLink>
            {/* <SmallD c="rgba(255,255,255,0.5)">From Q3 2023</SmallD> */}
          </IncludeCard>
          {/* <IncludeCard>
            <HashLink smooth to={`${pathname}#IrlEvents`}>
              <IncludeImage src={i8} />
              <MediumD c="#fff">
                <GreySpan>⑧</GreySpan> IRL events
              </MediumD>
            </HashLink> */}
          {/* <SmallD c="rgba(255,255,255,0.5)">2023-2025</SmallD> */}
          {/* </IncludeCard> */}
        </IncludesCards>
        <Border className="bottom" />
      </IncludesBlock>
      <ScrollBlock>
        <Small c="rgba(255,255,255,0.5)">Scroll down to learn more</Small>
        <ArrowImage src={arrow} />
      </ScrollBlock>
    </DropOne>
  );
};
