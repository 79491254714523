import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TextInput } from "../common/components/input/Input";
import { Select } from "../common/components/select/Select";
import { Medium, Small } from "../common/elements/Text";
import {
  addDropThunk,
  deleteDeviceInDropThunk,
  deleteDropThunk,
  getDevicesThunk,
  getDropsThunk,
  putDeviceInDropThunk,
  putDropThunk,
} from "../features/admin/adminSlice";
import { useForm } from "../hooks/useForm";

export const AdminContainer = styled.div`
  padding: 7.576vw 2vw;
  display: flex;
  width: 100%;
  background-color: #f2f2f2;
  gap: 1vw;

  &.support {
    padding: 0 2vw;
  }
`;

export const LeftSide = styled.div`
  height: calc(100vh - 13vw);
  width: 20%;
  padding: 1vw 2vw 1vw 0vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  overflow: auto;
`;
export const RightSide = styled.div`
  width: 80%;
  padding: 1vw 2vw;
  overflow: auto;
  height: calc(100vh - 13vw);
`;

export const ActionButton = styled.button`
  width: 48%;
  height: 2vw;
  background-color: #f2530f;
  border-radius: 1vw;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1vw 0;
  &.create {
    background-color: black;
    width: 48%;
    height: 3vw;
  }
  &.delete {
    margin-top: 3vw;
    background-color: red;
    width: 100%;
    min-height: 2vw;
  }
  &.cancel {
    background-color: #f2f2f2;
    border: 0.052vw solid #000;
    color: #000;
    width: 48%;
    height: 3vw;
  }
  :disabled {
    cursor: not-allowed;
    background-color: #e3e3e3;
  }
`;

export const Form = styled.form`
  margin: 1vw 0vw;
  display: flex;
  flex-direction: column;
  gap: 0.4vw;
`;
export const DropsScroll = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;
`;

export const DropCard = styled.div`
  width: 30%;
  background-color: #fff;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  padding: 2vw;
  justify-content: center;
  gap: 1vw;
`;

export const DropParams = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3vw;
`;

export const DropPhoto = styled.img`
  height: 5vw;
  border: 0.052vw solid #e3e3e3;
  margin: 0 auto;
`;

export const FlexButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexEquipment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8vw;
  gap: 1vw;
`;
const statuses = [
  { value: "PLANNED", id: 1 },
  { value: "STARTED", id: 2 },
  { value: "ENDED", id: 3 },
];

export const AdminDrop = () => {
  const [current, setCurrent] = useState();
  const [deviceId, setDeviceId] = useState("");
  const { values, onChange, errors, isValid, setValues, resetForm } = useForm();
  const drops = useSelector((state) => state.admin.drops);
  const devices = useSelector((state) => state.admin.devices);
  const dispatch = useDispatch();

  useEffect(() => {
    if (current) {
      // в текущий изменяемый девайс обновляем значения
      setCurrent(drops.find((d) => d.id === current.id));
    }
  }, [drops]);

  useEffect(() => {
    dispatch(getDropsThunk());
  }, []);

  const addDrop = () => {
    dispatch(addDropThunk(values));
  };

  const setFormData = (currentDrop) => {
    const {
      baseLimit,
      amount,
      approximateDeliveryDate,
      available,
      collection,
      dateStart,
      name,
      status,
      id,
    } = currentDrop;
    setValues({
      ...values,
      baseLimit,
      amount,
      approximateDeliveryDate,
      available,
      collection,
      dateStart,
      name,
      status,
      id,
    });
  };

  const editDrop = () => {
    dispatch(putDropThunk({ values }));
  };

  const deleteDrop = () => {
    dispatch(deleteDropThunk({ id: current.id }));
  };

  const addDeviceInDrop = () => {
    dispatch(putDeviceInDropThunk({ dropId: current.id, deviceId }));
  };

  const deleteDeviceInDrop = (id) => {
    dispatch(deleteDeviceInDropThunk({ dropId: current.id, deviceId: id }));
  };

  return (
    <AdminContainer>
      <LeftSide>
        {current ? (
          <>
            <Medium>
              Action with drop:
              <br />
              <br /> id: {current.id}
              <br />
              name: {current.name}
            </Medium>
            <Medium c="red">*Fill only changeable field everywhere*</Medium>
          </>
        ) : (
          <Medium>New drop:</Medium>
        )}
        <Form>
          <input
            type="file"
            onChange={({ target }) =>
              setValues({
                ...values,
                photo: target.files[0],
              })
            }
          />
          <TextInput
            name="collection"
            type="text"
            value={values?.collection || ""}
            onChange={onChange}
            label="Collection"
            validate={{
              required: true,
            }}
            errorMessage={errors?.collection}
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
          <TextInput
            name="name"
            type="text"
            value={values?.name || ""}
            onChange={onChange}
            label="Name"
            validate={{
              required: true,
            }}
            errorMessage={errors?.name}
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
          <Select
            required
            w="100%"
            label="Status"
            values={statuses}
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
            value={values?.status || ""}
            setValue={(value) =>
              setValues({
                ...values,
                status: value,
              })
            }
          />
          <TextInput
            name="dateStart"
            type="text"
            value={values?.dateStart || ""}
            onChange={onChange}
            label="Date start"
            validate={{
              required: true,
            }}
            errorMessage={errors?.dateStart}
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
          <TextInput
            name="approximateDeliveryDate"
            type="text"
            value={values?.approximateDeliveryDate || ""}
            onChange={onChange}
            label="approximateDeliveryDate"
            validate={{
              required: true,
            }}
            errorMessage={errors?.approximateDeliveryDate}
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
          <TextInput
            name="amount"
            type="text"
            value={values?.amount || ""}
            onChange={onChange}
            label="Amount"
            validate={{
              required: true,
            }}
            errorMessage={errors?.amount}
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
          <TextInput
            name="available"
            type="text"
            value={values?.available || ""}
            onChange={onChange}
            label="Available"
            validate={{
              required: true,
            }}
            errorMessage={errors?.available}
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
          <TextInput
            name="baseLimit"
            type="number"
            value={values?.baseLimit || ""}
            onChange={onChange}
            label="Buy Limit"
            validate={{
              required: true,
            }}
            errorMessage={errors?.baseLimit}
            w="100%"
            brc="rgba(0, 0, 0, 0.15)"
            bc="#FFF"
          />
        </Form>
        <FlexButtons>
          {current && (
            <ActionButton
              onClick={() => {
                setCurrent();
                resetForm();
              }}
              className="cancel"
            >
              Cancel
            </ActionButton>
          )}
          {current ? (
            <ActionButton
              onClick={() => editDrop()}
              className="create"
              type="button"
            >
              Save
            </ActionButton>
          ) : (
            <ActionButton
              disabled={!isValid || !values?.status || !values?.photo}
              onClick={() => addDrop()}
              className="create"
              type="button"
            >
              Create new
            </ActionButton>
          )}
        </FlexButtons>
        {current && (
          <>
            <Medium>Devices:</Medium>
            {current?.deviceList &&
              current?.deviceList.length !== 0 &&
              current.deviceList.map((cd) => (
                <FlexEquipment>
                  <Small>id: {cd.id}</Small>
                  <Small>name: {cd.name}</Small>
                  <Small
                    c="red"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteDeviceInDrop(cd.id)}
                  >
                    Delete
                  </Small>
                </FlexEquipment>
              ))}
            <Medium>Add device:</Medium>
            <Select
              required
              w="100%"
              label="Device"
              values={devices?.map((d) => ({
                name: d.name,
                value: d.id,
              }))}
              brc="rgba(0, 0, 0, 0.15)"
              bc="#FFF"
              value={deviceId || ""}
              setValue={(value) => setDeviceId(value)}
            />
            <FlexButtons>
              <ActionButton
                onClick={() => setDeviceId("")}
                className="cancel"
                type="button"
              >
                Cancel
              </ActionButton>
              <ActionButton
                disabled={!deviceId}
                onClick={() => addDeviceInDrop()}
                className="create"
                type="button"
              >
                Add device
              </ActionButton>
            </FlexButtons>
          </>
        )}
        {current && (
          <ActionButton
            onClick={() => deleteDrop()}
            className="delete"
            type="button"
          >
            Delete drop
          </ActionButton>
        )}
      </LeftSide>
      <RightSide>
        <DropsScroll>
          {drops.map((d) => (
            <DropCard>
              <Small
                style={{ textAlign: "right", cursor: "pointer" }}
                c="#F2530F"
                onClick={() => {
                  dispatch(getDevicesThunk());
                  setFormData(d);
                  setCurrent(d);
                }}
              >
                EDIT
              </Small>
              <DropPhoto src={d.photo} alt="drop-photo" />
              <DropParams>
                <Small>id: {d.id}</Small>
                <Small>name: {d.name}</Small>
                <Small>status: {d.status}</Small>
                <Small>dateStart: {d.dateStart}</Small>
                <Small>
                  approximateDeliveryDate:
                  {d.approximateDeliveryDate}
                </Small>
                <Small>amount: {d.amount}</Small>
                <Small>available: {d.available}</Small>
                <Small>buy limit: {d.baseLimit}</Small>
              </DropParams>
            </DropCard>
          ))}
        </DropsScroll>
      </RightSide>
    </AdminContainer>
  );
};
