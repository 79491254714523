import Lottie from "lottie-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import zebra from "../../assets/images/catalog/zebra.png";
import loader from "../../assets/lottie/l_g.json";
import {
  LottieContainer,
  WithTitleContainer,
} from "../../common/elements/Blocks";
import { CatalogImage, CatalogVideo } from "../../common/elements/Images";
import { Large, Small } from "../../common/elements/Text";
import { getDropsThunk } from "../../features/drop/dropSlice";
import { useBuy } from "../../hooks/useBuy";
import { useIsAdaptive } from "../../hooks/useIsAdaptive";
import {
  ActionButton,
  ActionTypeFormButton,
  ButtonAbsolute,
  CatalogCard,
  CatalogCards,
  LoadingContainer,
  MediumStyled,
} from "./CatalogStyle";

export const Catalog = () => {
  const adpt = useIsAdaptive();
  const drops = useSelector((state) => state.drop.drops);
  const isLoading = useSelector((state) => state.drop.isLoading);
  const dispatch = useDispatch();
  const { buyFunc } = useBuy();

  const isImage = [".gif", ".jpg", ".jpeg", ".png"];
  const isVideo = [".mpg", ".mp2", ".mpeg", ".mpe", ".mpv", ".mp4"];

  const statusOrderWeb = [100, 5000, 0];
  const statusOrderMobile = [5000, 100, 0];

  useEffect(() => {
    dispatch(getDropsThunk());
  }, []);

  return (
    <WithTitleContainer>
      <Large>Catalog</Large>
      <CatalogCards>
        {isLoading ? (
          <LoadingContainer>
            <LottieContainer animationData={loader} loop>
              <Lottie animationData={loader} loop />
            </LottieContainer>
          </LoadingContainer>
        ) : (
          drops.length !== 0 &&
          drops
            .slice(0)
            .sort((a, b) => {
              const statusOrder =
                adpt === "mob" ? statusOrderMobile : statusOrderWeb;
              return (
                statusOrder.indexOf(a.amount) - statusOrder.indexOf(b.amount)
              );
            })
            .map((c) => (
              <CatalogCard
                status={c.status}
                zebra={
                  (c.status === "ENDED" ||
                    (!c.available && c.status !== "ENDED")) &&
                  zebra
                }
                key={c.id}
                onClick={() => {
                  if (c.status === "STARTED") {
                    buyFunc();
                  }
                  if (c.status === "STARTED") {
                    document
                      .querySelector(".catalog-wl-button")
                      .addEventListener("click", function () {
                        document.body.style.overflow = "auto";
                      });
                  }
                }}
              >
                <ActionTypeFormButton
                  id="vKpGXHzK"
                  className="catalog-wl-button"
                />
                {isImage?.includes(c.photo.slice(-4)) && (
                  <CatalogImage src={c.photo} status={c.status} />
                )}
                {isVideo?.includes(c.photo.slice(-4)) && (
                  <CatalogVideo muted autoPlay loop playsInline src={c.photo} />
                )}
                {c.name && (
                  <MediumStyled
                    t={
                      (adpt === "pc" && "1.302vw") ||
                      (adpt === "tablet" && "1.172vw") ||
                      (adpt === "mob" && "2.564vw")
                    }
                    l={
                      (adpt === "pc" && "1.563vw") ||
                      (adpt === "tablet" && "1.563vw") ||
                      (adpt === "mob" && "3.846vw")
                    }
                    c={c.status === "ENDED" ? "rgba(0, 0, 0, 0.35)" : null}
                  >
                    {c.name}
                  </MediumStyled>
                )}
                {c.dateStart && (
                  <MediumStyled
                    b={
                      (adpt === "pc" && "0.990vw") ||
                      (adpt === "tablet" && "1.302vw") ||
                      (adpt === "mob" && "2.564vw")
                    }
                    l={
                      (adpt === "pc" && "1.563vw") ||
                      (adpt === "tablet" && "1.563vw") ||
                      (adpt === "mob" && "3.846vw")
                    }
                    c="rgba(0, 0, 0, 0.35)"
                  >
                    Date of drop:
                    <br />
                    {c.dateStart}
                  </MediumStyled>
                )}
                {c.status !== "PLANNED" || c.available ? (
                  <MediumStyled
                    t={
                      (adpt === "pc" && "1.302vw") ||
                      (adpt === "tablet" && "1.172vw") ||
                      (adpt === "mob" && "2.564vw")
                    }
                    r={
                      (adpt === "pc" && "1.563vw") ||
                      (adpt === "tablet" && "1.563vw") ||
                      (adpt === "mob" && "3.846vw")
                    }
                    c="rgba(0, 0, 0, 0.35)"
                  >
                    {/* {`In stock: ${c.available} / ${c.amount}`} */}
                  </MediumStyled>
                ) : null}
                <MediumStyled
                  b={
                    (adpt === "pc" && "0.990vw") ||
                    (adpt === "tablet" && "1.302vw") ||
                    (adpt === "mob" && "2.564vw")
                  }
                  r={
                    (adpt === "pc" && "1.563vw") ||
                    (adpt === "tablet" && "1.563vw") ||
                    (adpt === "mob" && "3.846vw")
                  }
                  c="rgba(0, 0, 0, 0.35)"
                  style={{ textAlign: "right" }}
                >
                  Est. delivery:
                  <br />
                  {c.approximateDeliveryDate}
                </MediumStyled>
                <ButtonAbsolute>
                  <ActionButton
                    zebra={
                      (c.status === "ENDED" ||
                        (!c.available && c.status !== "ENDED")) &&
                      zebra
                    }
                  >
                    {c.status === "ENDED" && (
                      <Small c="#9D9D9D">Out of stock</Small>
                    )}
                    {c.status === "STARTED" && <Small>Buy now</Small>}
                    {c.status === "PLANNED" && <Small c="#9D9D9D">Soon</Small>}
                  </ActionButton>
                </ButtonAbsolute>
              </CatalogCard>
            ))
        )}
      </CatalogCards>
    </WithTitleContainer>
  );
};
