/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { profileApi } from "../../api/profileApi";
import { catchNotif } from "../notification/notificationSlice";

export const getChatDevicesThunk = createAsyncThunk(
  "/profile/devices",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await profileApi.getChatDevices();
      const sorted = response.data.sort((a, b) => {
        const aName = a.name.split(" #");
        const bName = b.name.split(" #");
        return aName[0].localeCompare(bName[0]) || +aName[1] - +bName[1];
      });
      const deviceId = response.data.length ? sorted[0].id : "";
      if (deviceId) {
        dispatch(getChatMessagesThunk({ deviceId, page: 0, size: 1000 }));
      }
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getCellzThunk = createAsyncThunk(
  "/profile/cellz",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await profileApi.getCellz();
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getChatMessagesThunk = createAsyncThunk(
  "/profile/device/messages",
  async ({ deviceId, page, size }, { dispatch, rejectWithValue }) => {
    try {
      const response = await profileApi.getChatMessages(deviceId, page, size);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const setActiveRoom = createAsyncThunk(
  "/profile/device/setActiveRoom",
  async ({ deviceId, page, size }, { dispatch, rejectWithValue }) => {
    try {
      const response = await profileApi.getChatMessages(deviceId, page, size);
      return { data: response.data, deviceId };
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const otpChangeEmailThunk = createAsyncThunk(
  "/auth/email/otpChangeEmail",
  async ({ email }, { dispatch, rejectWithValue }) => {
    try {
      const response = await profileApi.otpSend(email);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    chatDevices: [],
    chatMessages: [],
    activeRoom: "",
    wallet: "",
    page: 0,
    size: 1000,
    isLoading: false,
    isLoadingDevices: false,
    cellz: [],
    isLoadingCellz: false,
  },
  reducers: {
    addNewMessage(state, action) {
      state.chatMessages = [...state.chatMessages, action.payload];
    },
    setWallet(state, action) {
      state.wallet = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload + 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChatDevicesThunk.fulfilled, (state, action) => {
      const sorted = action.payload.sort((a, b) => {
        const aName = a.name.split(" #");
        const bName = b.name.split(" #");
        return aName[0].localeCompare(bName[0]) || +aName[1] - +bName[1];
      });
      state.chatDevices = sorted;
      state.activeRoom = action.payload.length ? sorted[0].id : "";
      state.isLoadingDevices = false;
    });
    builder.addCase(getChatDevicesThunk.pending, (state) => {
      state.isLoadingDevices = true;
    });
    builder.addCase(getChatDevicesThunk.rejected, (state) => {
      state.isLoadingDevices = false;
    });
    builder.addCase(getCellzThunk.fulfilled, (state, action) => {
      state.cellz = action.payload;
      state.isLoadingCellz = false;
    });
    builder.addCase(getCellzThunk.pending, (state) => {
      state.isLoadingCellz = true;
    });
    builder.addCase(getCellzThunk.rejected, (state) => {
      state.isLoadingCellz = false;
    });
    builder.addCase(setActiveRoom.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setActiveRoom.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(setActiveRoom.fulfilled, (state, action) => {
      state.activeRoom = action.payload.deviceId;
      state.chatMessages = action.payload.data;
      state.page = 0;
      state.size = 1000;
      state.isLoading = false;
    });
    builder.addCase(getChatMessagesThunk.fulfilled, (state, action) => {
      state.chatMessages = state.chatMessages.concat(action.payload);
    });
  },
});

export const { addNewMessage, setWallet, setPage } = profileSlice.actions;

export default profileSlice.reducer;
