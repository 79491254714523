import { useState } from "react";
import { useDispatch } from "react-redux";
import { setActiveOrder } from "../features/support/supportSlice";
import {
  AdminNavigation,
  NavButton,
  NavigateAdminContainer,
} from "./NavigateAdminPage";
import { SupportOrders } from "./SupportOrders";

const statuses = [
  { id: 0, name: "IN_PROCESSING" },
  { id: 1, name: "DEVICE_ASSEMBLY" },
  { id: 2, name: "PREPARED_FOR_DELIVERY" },
  { id: 3, name: "IN_DELIVERY" },
  { id: 4, name: "DELIVERED" },
];

export const NavigateSupportPage = () => {
  const [status, setStatus] = useState("IN_PROCESSING");
  const dispatch = useDispatch();

  const changeStatusFunc = (newStatus) => {
    dispatch(setActiveOrder());
    setStatus(newStatus);
  };

  return (
    <NavigateAdminContainer>
      <AdminNavigation>
        {statuses.map((s) => (
          <NavButton
            key={s.id}
            className={status === s.name ? "active" : null}
            onClick={() => changeStatusFunc(s.name)}
          >
            {s.name}
          </NavButton>
        ))}
      </AdminNavigation>
      <SupportOrders status={status} />
    </NavigateAdminContainer>
  );
};
