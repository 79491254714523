import styled from "styled-components";
import { Medium } from "../../elements/Text";

export const TouchableOpacity = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${(props) => props.visible && "1.042vw"};
  border-bottom: ${(props) => props.visible && "0.052vw solid #000000"};

  @media (max-width: 768px) {
    padding-bottom: ${(props) => props.visible && "2.627vw"};
    border-bottom: ${(props) => props.visible && "0.256vw solid #000000"};
  }
`;

export const Text = styled(Medium)`
  font-size: 1.563vw;
  @media (max-width: 768px) {
    font-size: 4.569vw;
    line-height: 4.34vw;
  }
`;

export const Plus = styled.img`
  width: 1.615vw;
  height: 1.615vw;

  @media (max-width: 768px) {
    width: 4.734vw;
    height: 4.734vw;
  }
`;

export const Minus = styled.img`
  width: 1.615vw;
  height: 0.417vw;

  @media (max-width: 768px) {
    width: 4.734vw;
    height: 1.175vw;
  }
`;

export const ArrowDown = styled.img`
  @media (max-width: 768px) {
    width: 5.076vw;
    height: 3.299vw;
  }
`;

export const ArrowUp = styled.img`
  @media (max-width: 768px) {
    width: 5.076vw;
    height: 3.299vw;
  }
`;

export const Block = styled.div`
  width: 55.573vw;
  padding-top: 1.719vw;
  @media (max-width: 768px) {
    width: 100%;
    padding: 5.076vw 0;
  }
`;

export const Container = styled.div`
  padding-bottom: ${(props) => !props.visible && "0.885vw"};
  border-bottom: ${(props) => !props.visible && "0.052vw solid #000000"};

  @media (max-width: 768px) {
    padding-bottom: 0;
    border-bottom: ${(props) => !props.visible && "0.256vw solid #000000"};
  }
`;

// Profile styles
export const ContainerProfile = styled.div`
  padding-bottom: ${(props) => !props.visible && "0.885vw"};
  border-bottom: ${(props) => !props.visible && "0.052vw solid #000000"};

  @media (max-width: 768px) {
    padding-bottom: 0;
    border-bottom: ${(props) => !props.visible && "0.256vw solid #000000"};
  }
`;

export const TouchableOpacityProfile = styled.div`
  /* cursor: pointer; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${(props) => props.visible && "1.042vw"};
  border-bottom: ${(props) => props.visible && "0.052vw solid #000000"};

  @media (max-width: 768px) {
    padding-bottom: ${(props) => props.visible && "2.627vw"};
    border-bottom: ${(props) => props.visible && "0.256vw solid #000000"};
  }
`;

export const TextProfile = styled(Medium)`
  @media (max-width: 768px) {
    font-weight: 450;
    font-size: 10.152vw;
    line-height: 9.645vw;
  }
`;

export const BlockProfile = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    padding: 7.614vw 0 8.883vw 0;
  }
`;
