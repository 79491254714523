/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { supportApi } from "../../api/supportApi";
import { catchNotif } from "../notification/notificationSlice";

export const getSupportDevicesThunk = createAsyncThunk(
  "/support/getDevices",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await supportApi.getDevices();
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const getSupportMessagesThunk = createAsyncThunk(
  "/support/device/messages",
  async ({ deviceId, page, size }, { dispatch, rejectWithValue }) => {
    try {
      const response = await supportApi.getChatMessages(deviceId, page, size);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

export const changeOrderThunk = createAsyncThunk(
  "/support/changeOrder",
  async ({ orderId, obj }, { dispatch, rejectWithValue }) => {
    try {
      const response = await supportApi.changeOrder(orderId, obj);
      return response.data;
    } catch (e) {
      dispatch(catchNotif(e));
      return rejectWithValue(e.response?.data?.message);
    }
  },
);

const supportSlice = createSlice({
  name: "support",
  initialState: {
    orders: [],
    activeOrder: null,
    chatDevices: [],
    activeRoom: "",
    chatMessages: [],
    page: 0,
    size: 1000,
  },
  reducers: {
    setActiveOrder(state, action) {
      state.activeOrder = action.payload || null;
      state.chatDevices = action.payload?.deviceList || [];
      state.chatMessages = [];
      state.activeRoom = "";
    },
    setActiveRoom(state, action) {
      state.activeRoom = action.payload;
    },
    addNewMessage(state, action) {
      state.chatMessages = [...state.chatMessages, action.payload];
    },
    filterOrdersIsDevice: (state) => {
      state.orders = state.orders.filter(
        (item) => item.deviceList.length !== 0,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSupportDevicesThunk.fulfilled, (state, action) => {
      state.orders = action.payload;
    });
    builder.addCase(getSupportMessagesThunk.pending, (state) => {
      state.chatMessages = [];
    });
    builder.addCase(getSupportMessagesThunk.fulfilled, (state, action) => {
      state.chatMessages = action.payload;
    });
    builder.addCase(changeOrderThunk.fulfilled, (state, action) => {
      state.orders = state.orders.map((od) =>
        action.payload.id === od.order.id
          ? { ...od, order: action.payload }
          : od,
      );
    });
  },
});

export const {
  setActiveRoom,
  setActiveOrder,
  addNewMessage,
  filterOrdersIsDevice,
} = supportSlice.actions;

export default supportSlice.reducer;
