import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "../features/admin/adminSlice";
import authSlice from "../features/auth/authSlice";
import breedingSlice from "../features/breeding/breedingSlice";
import claimSlice from "../features/claim/claimSlice";
import deliverySlice from "../features/delivery/deliverySlice";
import dropSlice from "../features/drop/dropSlice";
import notificationSlice from "../features/notification/notificationSlice";
import profileSlice from "../features/profile/profileSlice";
import supportSlice from "../features/support/supportSlice";
import xsollaSlice from "../features/xsolla/xsollaSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    breeding: breedingSlice,
    delivery: deliverySlice,
    admin: adminSlice,
    drop: dropSlice,
    profile: profileSlice,
    support: supportSlice,
    xsolla: xsollaSlice,
    claim: claimSlice,
    notification: notificationSlice,
  },
});
