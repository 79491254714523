import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import { Large, Small } from "../../../common/elements/Text";
// import { Medium, Small } from "../../../common/elements/Text";

export const ProductContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 48.958vw;
  background: #000000;
  padding: 2.083vw 3.438vw;
  @media (max-width: 768px) {
    height: auto;
    padding: 25.381vw 4.569vw 34.206vw 4.569vw;
  }
`;

export const LargeStyledJoinWl = styled(Large)`
  @media (max-width: 768px) {
    font-size: 11.228vw;
    line-height: 14.467vw;
    text-align: center;
  }
`;

export const TitleBuyContainer = styled.div`
  margin: 10.297vw 0 10.188vw 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.573vw;
  @media (max-width: 768px) {
    order: 1;
    margin: 0 0 25.381vw 0;
    padding: 0vw;
  }
`;
export const TitleBuy = styled(Large)`
  background-clip: text;
  -webkit-background-clip: text;
  font-family: "regular";
  font-size: 4.688vw;
  line-height: 4.453vw;
  font-weight: 400;
  letter-spacing: -0.02em;
  cursor: pointer;

  color: #f2530f;
  transition: all 300ms ease-out;
  &:hover {
    transition: all 300ms ease-out;
    color: #ff6f32;
  }
  @media (max-width: 768px) {
    font-size: 11.228vw;
    line-height: 14.467vw;
    text-align: center;
  }
`;

export const ListItem = styled(HashLink)``;

export const BlockLinks = styled.div`
  display: flex;

  @media (max-width: 768px) {
    order: 2;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 19.543vw;
  }
`;

export const Nav = styled.div`
  display: flex;
  gap: 2.083vw;
  @media (max-width: 1280px) {
    gap: 2.344vw;
  }
  @media (max-width: 768px) {
    flex-direction: column;

    width: 100%;
  }
`;

export const NavElement = styled(HashLink)`
  display: flex;
  align-items: center;
  gap: 0.521vw;
  cursor: pointer;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    border-top: 0.256vw solid rgba(255, 255, 255, 0.3);
    width: 100%;
    padding: 5.076vw 0;
    :last-child {
      border-bottom: 0.256vw solid rgba(255, 255, 255, 0.3);
    }
  }
`;

export const TextSmall = styled(Small)`
  @media (max-width: 768px) {
    font-weight: 400;
    font-size: 8.883vw;
    line-height: 8.439vw;
    letter-spacing: -0.02em;
    font-family: "regular";
  }
`;

export const Dot = styled.div`
  width: 0.59vw;
  height: 0.59vw;
  border: 0.052vw solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActiveInside = styled.div`
  height: 0.313vw;
  width: 0.313vw;
  border-radius: 50%;
  &.active {
    background-color: #000000;
  }
  &.active_white {
    background-color: #ffffff;
  }
`;

export const ViewPillz = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.542vw;
  align-items: center;

  @media (max-width: 768px) {
    order: 4;
    margin-top: 25.381vw;
    gap: 7.614vw;
  }
`;

export const BlockText = styled.div`
  text-align: center;
`;

export const Logo = styled.img`
  width: 8.77vw;
  height: 2.781vw;
  @media (max-width: 768px) {
    width: 28.835vw;
    height: 9.142vw;
    /* position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%); */
  }
`;

export const SocialsBlock = styled.div`
  display: flex;

  margin-top: 2.604vw;

  @media (max-width: 768px) {
    order: 3;
    margin-top: 0;
  }
`;

export const Block = styled.div``;

export const LinksSocial = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.057vw;
  width: 15.521vw;
  svg path {
    transition: all 300ms ease-in;
  }
  :hover {
    svg path {
      transition: all 300ms ease-out;
      fill: #fff;
      fill-opacity: 1;
    }
  }

  @media (max-width: 768px) {
    flex-basis: calc(50% - 15.391vw);
    margin-top: 8.376vw;
    gap: 5.076vw;
  }
`;

export const BorderTop = styled.div`
  height: 0.781vw;
  width: 100%;
  border-width: 0.052vw 0 0 0.052vw;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.3);

  &.border {
    border-width: 0.052vw 0.052vw 0 0.052vw;
  }
  @media (max-width: 768px) {
    border-width: 0.256vw 0 0 0.256vw;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.3);
    height: 2.538vw;
    &.border {
      border-width: 0.256vw 0.256vw 0 0.256vw;
    }
  }
`;
