import { useDispatch, useSelector } from "react-redux";
import { Small } from "../../common/elements/Text";
import { setActiveRoom } from "../../features/support/supportSlice";
import { DeviceBlock, LeftPanel, Name } from "./allStyles";

export const LeftPanelRoomsSup = () => {
  const chatDevices = useSelector((state) => state.support.chatDevices);
  const activeRoom = useSelector((state) => state.support.activeRoom);
  const dispatch = useDispatch();

  const isActiveRoom = (id) => id === activeRoom;

  return (
    <LeftPanel>
      {chatDevices.map((room) => (
        <DeviceBlock
          key={room.id}
          className={`${isActiveRoom(room.id) && "active"}`}
          onClick={() => dispatch(setActiveRoom(room.id))}
        >
          <Name c="#000000">{room.name}</Name>
          <Small c="grey">serial: {room.serial}</Small>
          <Small c="grey">activatedAt: {room.activatedAt}</Small>
          <Small c="grey">lastConnected: {room.lastConnected}</Small>
          <Small c="grey">activeNft: {room.activeNft}</Small>
        </DeviceBlock>
      ))}
    </LeftPanel>
  );
};
