import { DropDownProfile } from "../../common/components/dropDownBlock/DropDownBlock";
import { Medium } from "../../common/elements/Text";
import { useIsAdaptive } from "../../hooks/useIsAdaptive";
import {
  CartTitles,
  CartZone,
  DeliveryAndLinkedContainers,
} from "./ProfileStyle";
import { Credentials } from "./subcomponents/Credentials";

export const Profile = () => {
  const adpt = useIsAdaptive();
  return (
    <>
      {adpt !== "mob" && (
        <CartTitles>
          <CartZone>
            <Medium>Profile info</Medium>
          </CartZone>
        </CartTitles>
      )}
      <DeliveryAndLinkedContainers>
        <CartZone>
          {adpt === "mob" ? (
            <DropDownProfile label="Credentials">
              <Credentials />
            </DropDownProfile>
          ) : (
            <Credentials />
          )}
        </CartZone>
      </DeliveryAndLinkedContainers>
    </>
  );
};
