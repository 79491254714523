/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import blueArrow from "../../../assets/images/cart/blue_arrow.png";
import greyArrow from "../../../assets/images/cart/grey_arrow.png";
import lightArrow from "../../../assets/images/cart/light_arrow.png";
import { useComponentVisible } from "../../../hooks/useComponentVisible";
import { BlueContainer, FieldContainer } from "../../elements/Blocks";
import { BlueTyping, Input, NoInput } from "../../elements/Inputs";
import { FieldLabel } from "../../elements/Labels";
import { Small } from "../../elements/Text";
import {
  ArrowBlueContainer,
  ArrowContainer,
  BlueList,
  BlueListElement,
  List,
  ListElement,
  SelectArrow,
  SelectPopup,
  StyledSmallLabel,
  StyledSpan,
} from "./SelectStyle";

export const Select = ({
  label,
  w,
  values,
  value,
  setValue,
  bc,
  brc,
  required,
}) => {
  const [filterVal, setFilterVal] = useState("");
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const validValue = () => {
    if (value !== "" && !values.find((v) => v.value === value)) {
      setValue("");
      setFilterVal("");
      setIsComponentVisible(!isComponentVisible);
    }
  };

  const handleDrop = () => {
    if (!isComponentVisible) {
      setIsComponentVisible(!isComponentVisible);
    }
  };

  function handleChange(e) {
    setValue(e.target.value);
    setFilterVal(e.target.value);
  }

  const handleClick = (value) => {
    setValue(value);
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <FieldContainer w={w}>
      <Input
        w={w}
        bc={bc}
        brc={brc}
        type="text"
        value={value}
        onChange={handleChange}
        onClick={handleDrop}
        onBlur={() => validValue()}
      />
      <FieldLabel className={value && "filled"} htmlFor={name} pointer>
        {label}
        {required && <StyledSpan>*</StyledSpan>}
      </FieldLabel>
      <SelectArrow
        src={greyArrow}
        className={isComponentVisible ? "abs_active" : "abs"}
        alt="grey_arrow"
        isOpen={isComponentVisible}
      />
      {isComponentVisible && (
        <List bc={bc} brc={brc} ref={ref}>
          {values.filter((opt) =>
            opt.value.toLowerCase().includes(filterVal.toLowerCase()),
          ).length !== 0 ? (
            values
              .filter((opt) =>
                opt.value.toLowerCase().includes(filterVal.toLowerCase()),
              )
              .map((c) => (
                <ListElement
                  key={label === "Country" ? c.value : c.id}
                  onClick={() => handleClick(c.value)}
                  className={value === c.value ? "active" : null}
                >
                  <Small c={value === c.value ? "#fff" : "#000"}>
                    {c.value} {c.name && `| ${c.name}`}
                  </Small>
                </ListElement>
              ))
          ) : (
            <ListElement>
              <Small c="#000">No matches...</Small>
            </ListElement>
          )}
        </List>
      )}
    </FieldContainer>
  );
};

export const StringSelect = ({
  label,
  w,
  values,
  value,
  setValue,
  bc,
  fc,
  c,
  brc,
  required,
  notyping,
  disabled,
  black,
}) => {
  const [filterVal, setFilterVal] = useState("");
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const nav = useNavigate();
  const isAuth = useSelector((state) => state.auth.isAuth);

  const validValue = () => {
    if (
      !isComponentVisible &&
      value !== "" &&
      !values.find(
        (v) => v === value || value === "Why is my country not here?",
      )
    ) {
      setValue("");
      setFilterVal("");
    }
  };

  useEffect(() => {
    validValue();
  }, [isComponentVisible]);

  const handleDrop = (a) => {
    if (!isComponentVisible || a === "arrow") {
      setIsComponentVisible(!isComponentVisible);
    }
  };

  function handleChange(e) {
    setValue(e.target.value);
    setFilterVal(e.target.value);
  }

  const handleClick = (value) => {
    setValue(value);
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <FieldContainer w={w} ref={ref}>
      {!notyping ? (
        <Input
          w={w}
          bc={bc}
          c={c}
          brc={brc}
          type="text"
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onClick={handleDrop}
        />
      ) : (
        <NoInput w={w} bc={bc} c={c} brc={brc} onClick={handleDrop}>
          <Small c={c}>
            {value && `${value.slice(0, 6)}...${value.slice(-6)}`}
          </Small>
        </NoInput>
      )}
      <FieldLabel
        fc={fc}
        c={c}
        className={value && "filled"}
        htmlFor={name}
        pointer
      >
        {label}
        {required && <StyledSpan>*</StyledSpan>}
      </FieldLabel>
      <ArrowContainer onClick={() => handleDrop("arrow")}>
        <SelectArrow
          src={!black ? greyArrow : lightArrow}
          className={isComponentVisible ? "abs_active" : "abs"}
          alt="grey_arrow"
          isOpen={isComponentVisible}
          black={black}
        />
      </ArrowContainer>
      {isComponentVisible && (
        <List bc={bc} brc={brc} black={black}>
          {label === "Wallet" && (
            <ListElement
              black={black}
              key="Do you need to add a wallet?"
              onClick={() =>
                !disabled && (isAuth ? nav("/profile/info") : nav("/login"))
              }
            >
              <Small c="#F2530F">Do you need to add a wallet?</Small>
            </ListElement>
          )}
          {values.filter((opt) =>
            opt.toLowerCase().includes(filterVal.toLowerCase()),
          ).length !== 0 ? (
            <>
              {/* {label === "Region" && (
                <ListElement
                  black={black}
                  key="Why is my country not here?"
                  onClick={() =>
                    !disabled && handleClick("Why is my country not here?")
                  }
                  className={
                    value === "Why is my country not here?" ? "active" : null
                  }
                >
                  <Small
                    c={
                      value === "Why is my country not here?"
                        ? "#fff"
                        : "#F2530F"
                    }
                  >
                    Why is my country not here?
                  </Small>
                </ListElement>
              )} */}
              {values
                .filter((opt) =>
                  opt.toLowerCase().includes(filterVal.toLowerCase()),
                )
                .map((c) => (
                  <ListElement
                    key={c}
                    black={black}
                    onClick={() => !disabled && handleClick(c)}
                    className={value === c ? "active" : null}
                  >
                    <Small c={value === c || black ? "#fff" : "#000"}>
                      {!notyping ? c : `${c.slice(0, 6)}...${c.slice(-6)}`}
                    </Small>
                  </ListElement>
                ))}
            </>
          ) : (
            <ListElement>
              <Small c={c}>No matches...</Small>
            </ListElement>
          )}
        </List>
      )}
    </FieldContainer>
  );
};

export const BlueSelect = ({ values, value, setValue, black, bc }) => {
  const [filterVal, setFilterVal] = useState("");
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const validValue = () => {
    if (
      !isComponentVisible &&
      value !== "" &&
      !values.find((v) => v === value)
    ) {
      setValue("");
      setFilterVal("");
    }
  };

  useEffect(() => {
    validValue();
  }, [isComponentVisible]);

  const handleDrop = (a) => {
    if (!isComponentVisible || a === "arrow") {
      setIsComponentVisible(!isComponentVisible);
    }
  };

  function handleChange(e) {
    setValue(e.target.value);
    setFilterVal(e.target.value);
  }

  const handleClick = (value) => {
    setValue(value);
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <SelectPopup ref={ref}>
      <BlueContainer onClick={handleDrop}>
        {!isComponentVisible ? (
          <StyledSmallLabel c="#F2530F" style={{ maxWidth: "88%" }}>
            {value || "Select country"}
          </StyledSmallLabel>
        ) : (
          <BlueTyping
            className="select"
            autoFocus
            placeholder="Select country"
            value={value}
            onChange={handleChange}
          />
        )}
      </BlueContainer>
      <ArrowBlueContainer onClick={() => handleDrop("arrow")}>
        <SelectArrow
          src={blueArrow}
          alt="blue_arrow"
          className={isComponentVisible ? "abs_active" : "abs"}
          isOpen={isComponentVisible}
        />
      </ArrowBlueContainer>
      {isComponentVisible && (
        <BlueList bc={bc}>
          {values.filter((opt) =>
            opt.toLowerCase().includes(filterVal.toLowerCase()),
          ).length !== 0 ? (
            <>
              {values
                .filter((opt) =>
                  opt.toLowerCase().includes(filterVal.toLowerCase()),
                )
                .map((c) => (
                  <BlueListElement
                    key={c}
                    onClick={() => handleClick(c)}
                    className={value === c ? "active" : null}
                    black={black}
                  >
                    <Small c={value === c ? "#fff" : "#F2530F"}>{c}</Small>
                  </BlueListElement>
                ))}
            </>
          ) : (
            <BlueListElement>
              <Small c="#F2530F">No matches...</Small>
            </BlueListElement>
          )}
        </BlueList>
      )}
    </SelectPopup>
  );
};
